$react-notifications-font-base-path: './fonts' !default;

$react-notifications-transition-time: 0.4s !default;

$react-notifications-container-bottom: 0 !default;
$react-notifications-container-right: 0 !default;
$react-notifications-container-z-index: 999999 !default;
$react-notifications-container-width: 320px !default;
$react-notifications-container-top-padding: 0px !default;
$react-notifications-container-max-height: calc(100% - 30px) !default;
$react-notifications-container-right-padding: 15px !default;

$react-notifications-border-radius: 4px !default;
$react-notifications-padding-top: 12px !default;
$react-notifications-padding-right: 15px !default;
$react-notifications-padding-bottom: 12px !default;
$react-notifications-padding-left: 15px !default;
$react-notifications-color: #eff5fa !default;
$react-notifications-background-color: #ccc !default;
$react-notifications-box-shadow: none !default;
$react-notifications-cursor: pointer !default;
$react-notifications-font-size: 15px !default;
$react-notifications-line-height: 20px !default;
$react-notifications-opacity: 0.9 !default;
$react-notifications-margin-top: 15px !default;

$react-notifications-icon-top: 50% !default;
$react-notifications-icon-left: 15px !default;
$react-notifications-icon-margin-top: -14px !default;
$react-notifications-icon-width: 28px !default;
$react-notifications-icon-height: 28px !default;
$react-notifications-icon-font-size: 28px !default;
$react-notifications-icon-line-height: 28px !default;

$react-notifications-hover-opacity: 1 !default;

$react-notifications-title-font-size: 1em !default;
$react-notifications-title-line-height: 1.2em !default;
$react-notifications-title-font-weight: bold !default;
$react-notifications-title-margin: 0 0 5px 0 !default;

$react-notifications-info-background-color: #354551 !default;
$react-notifications-info-content: "\f05a" !default;
$react-notifications-success-background-color: #51a351 !default;
$react-notifications-success-content: "\f058" !default;
$react-notifications-warning-background-color: #f89406 !default;
$react-notifications-warning-content: "\f06a" !default;
$react-notifications-error-background-color: #D95252 !default;
$react-notifications-error-content: "\f057" !default;

.notification-container {
    box-sizing: border-box;
    position: fixed;
    // bottom: $react-notifications-container-bottom;
    // right: $react-notifications-container-right;
    bottom: 15px;
    left: 0px;
    z-index: $react-notifications-container-z-index;
    padding: $react-notifications-container-top-padding $react-notifications-container-right-padding;
    max-height: $react-notifications-container-max-height;
}

.notification {
    box-sizing: border-box;
    padding: $react-notifications-padding-top $react-notifications-padding-right $react-notifications-padding-bottom $react-notifications-padding-left;
    border-radius: $react-notifications-border-radius;
    color: $react-notifications-color;
    font-weight: 700;
    background-color: $react-notifications-background-color;
    box-shadow: $react-notifications-box-shadow;
    cursor: $react-notifications-cursor;
    font-size: $react-notifications-font-size;
    line-height: $react-notifications-line-height;
    position: relative;
    opacity: $react-notifications-opacity;
    margin-top: $react-notifications-margin-top;
    .title {
        font-size: $react-notifications-title-font-size;
        line-height: $react-notifications-title-line-height;
        font-weight: $react-notifications-title-font-weight;
        margin: $react-notifications-title-margin;
    }
    &:hover, &:focus {
        opacity: $react-notifications-hover-opacity;
    }
}

.notification-enter {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
}

.notification-enter.notification-enter-active {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: all $react-notifications-transition-time;
}

.notification-exit {
    visibility: visible;
    transform: translate3d(0, 0, 0);
}

.notification-exit.notification-exit-active {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
    transition: all $react-notifications-transition-time;
}

.notification-info {
    background-color: $react-notifications-info-background-color;
}

.notification-success {
    background-color: $react-notifications-success-background-color;
}

.notification-warning {
    background-color: $react-notifications-warning-background-color;
}

.notification-error {
    background-color: $react-notifications-error-background-color;
}

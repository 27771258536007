/* Colors */
/* Fonts */
/* Dimensions */
.watchlists__heading {
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 20px;
}
.watchlists__button {
  margin-top: 12px;
}
.watchlists__button .btn {
  width: 100%;
  line-height: 28px;
  padding: 9px 22px;
  height: auto;
  min-height: 46px;
}
.watchlists .watchlist__item {
  display: flex;
  background: #3A4C5A;
  margin: 4px 0 0;
  color: #2DC46E;
  padding: 8px 16px;
  align-items: center;
}
.watchlists .watchlist__item:hover {
  color: #FFF;
}
.watchlists .watchlist__avatar {
  white-space: nowrap;
  width: 32px;
  height: 32px;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
  background: #24323D;
  color: #CCCCCC;
  border-radius: 16px;
  flex-shrink: 0;
}
.watchlists .watchlist__name {
  flex-grow: 1;
  margin-left: 16px;
  font-size: 16px;
  line-height: 22px;
}
.watchlists .watchlist__remove {
  margin-left: 8px;
  padding: 4px;
  flex-shrink: 0;
}
.watchlists .watchlist__remove svg {
  width: 8px;
  height: 8px;
  color: #FFF;
}
.watchlists .watchlist__remove:hover svg {
  color: #2DC46E;
}
/* Colors */
/* Fonts */
/* Dimensions */
.my-account__form .submit-row:after {
  content: "";
  display: table;
  clear: both;
}

.my-account__form {
  padding: 0px 0 70px;
}
.my-account__form .settings-h4 {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 35px;
  margin-bottom: 5px;
  color: white;
  opacity: 0.7;
}
.my-account__form label {
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 0px;
  text-transform: uppercase;
  display: block;
  color: white;
  opacity: 0.7;
}
.my-account__form .form-row {
  display: flex;
  margin-bottom: 40px;
}
.my-account__form h4 + .form-row {
  margin-top: 15px;
}
.my-account__form .input-container {
  margin-left: 30px;
  width: 250px;
  flex-shrink: 0;
  margin-bottom: 0;
}
.my-account__form .input-container .input {
  margin-bottom: 0;
}
.my-account__form .input-container .input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.my-account__form .input-container .input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.my-account__form .input-container .input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.my-account__form .input-container .input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.my-account__form .input-container .select {
  color: #1E2A31;
  margin-top: -4px;
}
.my-account__form .input-container .select input {
  margin-bottom: 0;
}
.my-account__form .input-container:nth-child(1) {
  margin-left: 0;
}
.my-account__form .account-form .btn {
  display: block;
}
.my-account__form .submit-row {
  justify-content: flex-start;
  width: 550px;
}
.my-account__form .error-message {
  font-size: 14px;
  line-height: 20px;
  margin: 25px 0;
  color: red;
}
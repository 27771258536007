/* Colors */
/* Fonts */
/* Dimensions */
.home-search .player-view {
  display: flex;
  color: #FFF;
}
.home-search .player-view .custom-player-indicator {
  display: inline-block;
  padding: 2px 5px;
  font-size: 9px;
  line-height: 11px;
  vertical-align: super;
  text-transform: uppercase;
  background: #188538;
  color: #FFF;
  border-radius: 2px;
  margin-left: 4px;
}
.home-search .player-view__img {
  width: 25px;
  height: 25px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
}
.home-search .player-view__name {
  font-weight: bold;
}
.home-search .player-view__team-img {
  width: 16px;
  height: 16px;
  display: inline-block;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 4px;
  vertical-align: middle;
}
.home-search .player-view__team-name {
  font-size: 12px;
  opacity: 0.7;
  display: block;
}
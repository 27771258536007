/* Colors */
/* Fonts */
/* Dimensions */
.earn__invite {
  margin-top: 24px;
}
.earn__invite-tabs {
  font-size: 13px;
  line-height: 40px;
  color: #CCC;
  border-bottom: 2px solid #2D3B46;
}
.earn__invite-tabs li {
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: -2px;
  padding: 0 12px;
  cursor: pointer;
}
.earn__invite-tabs li.active {
  cursor: default;
  border-bottom: 2px solid #FFFFFF;
  font-weight: bold;
  color: #FFF;
}
.earn__invite-form {
  display: flex;
  padding: 16px 0 0;
}
.earn__invite-form input {
  color: #2DC46E;
}
.earn__invite-form .btn {
  font-size: 13px;
  white-space: nowrap;
  margin-left: 24px;
}
.earn__invite-link input {
  cursor: pointer;
}
/* Colors */
/* Fonts */
/* Dimensions */
.coupons p.label {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 25px 0 10px !important;
}
.coupons .coupon-form {
  display: flex;
}
.coupons .coupon-form .btn {
  display: block;
  margin-left: 10px;
  font-size: 12px;
  line-height: 40px;
  white-space: nowrap;
  padding: 0px 20px;
  height: 40px;
  display: block;
  box-sizing: border-box;
}
.coupons .coupon-form input {
  max-width: 200px;
}
.coupons .coupon-error {
  color: #d33a61;
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  display: block;
  text-transform: uppercase;
}
.coupons .coupon-success {
  color: #188538;
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  display: block;
  text-transform: uppercase;
}
/* Colors */
/* Fonts */
/* Dimensions */
.note-tag {
  display: inline-block;
  padding: 3px 10px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.15);
  margin: 3px 3px 0 0;
  color: #FFF;
  font-size: 13px;
  line-height: 18px;
  white-space: nowrap;
}
@keyframes animatedBackground {
    from { background-position: 0 0; }
    to { background-position: 160px 0; }
}

.loader {
    @include position(fixed, 0);
    background: rgba($color_Bg, .99);
    will-change: opacity;
    z-index: 102;

    &.matches-loader{
        top:80px;
        position: relative;
        background: none;
        opacity: 1;
    }

    .loader-image {
        width: 120px;
        height: 120px;
        @include position(absolute, 50% none none 50%);
        margin: -60px 0 0 -60px;
        @media (max-width: $screen-sm-max) {
            width: 120px;
            height: 120px;
            margin: -60px 0 0 -60px;
        }
    }

    .loader-message{
        margin-top: 24px;
        width: 360px;
        margin-left: -120px;
        text-align: center;
        color: #CCC;
    }

    &.modal-enter {
        opacity: 0;
    }

    &.modal-enter-active {
        opacity: 1;
        @include transition(opacity 500ms ease-out);
    }

    &.modal-leave {
        opacity: 1;
    }

    &.modal-leave-active {
        opacity: 0;
        @include transition(opacity 500ms ease-out);
    }
}

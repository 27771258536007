/* Colors */
/* Fonts */
/* Dimensions */
.confetti {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -300px;
  margin-top: -300px;
  pointer-events: none;
}

.confirm-modal.congratulations-confirm {
  width: 582px;
  background: #2D3B46;
  border-radius: 6px;
}
.confirm-modal.congratulations-confirm .buttons {
  text-align: left;
}
.confirm-modal.congratulations-confirm .buttons .btn {
  margin-left: 0;
}

.congratulations__heading {
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 32px;
}
.congratulations__heading svg {
  width: 13px;
  height: 13px;
  vertical-align: baseline;
  display: inline-block;
  margin-right: 4px;
}
.congratulations__body .text h4 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 16px;
}
.congratulations__body .text h4 strong {
  color: #2DC46E;
}
.congratulations__body .text p {
  margin-top: 16px;
}
.congratulations__row {
  display: flex;
}
.congratulations__row .icon {
  margin-right: 36px;
  position: relative;
}
.congratulations__row .icon svg {
  width: 45px;
  height: 45px;
}
.congratulations__row .icon svg.upgrade-icon-big {
  width: 81px;
  height: 81px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.area {
  margin-top: 20px;
  font-size: 0;
  display: inline-block;
  position: relative;
}
@media (max-width: 959px) {
  .area {
    margin-top: 10px;
  }
}
.area .area__svg {
  display: inline-block;
}
.area .area__svg, .area .area__svg rect {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.area .area__svg .pixel-perfect {
  shape-rendering: crispEdges;
}
/* Colors */
/* Fonts */
/* Dimensions */
.data-item-changeable {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  /*@media (max-width: $screen-sm-max) {
    &__value{
      font-size: 13px;
      line-height: 17px;
    }

    &__input{
      font-size: 12px;
      line-height: 16px;
      input{
        font-size: 12px;
        line-height: 16px;
      }

      .date_input{
        width: 60px;
      }

      .select_input{
        min-width: 60px;
        & > div {
          min-height: 23px;
        }
      }

      .number_input{
        width: 30px;
      }
    }

    &__label{
      font-size: 11px;
      line-height: 15px;
    }
  }*/
}
.data-item-changeable__value {
  color: #FFF;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  display: inline-block;
}
.data-item-changeable__value .data-item-changeable__edit {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-position: center center;
  background-size: contain;
  vertical-align: middle;
  position: absolute;
  left: 100%;
  margin-left: 4px;
  top: 4px;
  background-repeat: no-repeat;
  color: #2DC46E;
}
.data-item-changeable__input input {
  color: white;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  padding: 1px 0 2px;
}
.data-item-changeable__input .date_input {
  width: 80px;
}
.data-item-changeable__input .select_input {
  min-width: 80px;
}
.data-item-changeable__input .select_input > div {
  min-height: 23px;
}
.data-item-changeable__input .number_input {
  width: 45px;
}
.data-item-changeable__label {
  font-size: 13px;
  text-transform: uppercase;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.5px;
  display: block;
}
/* Colors */
/* Fonts */
/* Dimensions */
.homepage__watchlists {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  overflow: hidden;
  height: 432px;
  position: relative;
  margin-top: -8px;
}
.homepage__watchlists .demo-message {
  position: absolute;
  left: 50%;
  top: 270px;
}
.homepage__watchlists .demo-message.no-flex {
  position: relative;
  left: 25px;
  top: 45px;
}
@media (max-width: 1379px) {
  .homepage__watchlists {
    height: 360px;
  }
  .homepage__watchlists .demo-message {
    top: 230px;
  }
  .homepage__watchlists .demo-message.no-flex {
    position: relative;
    width: 176px;
    left: 25px;
    top: 45px;
  }
}
@media (max-width: 1199px) {
  .homepage__watchlists {
    height: auto;
  }
  .homepage__watchlists .demo-message {
    position: relative;
    display: block;
    margin-top: 45px;
    top: 0;
    left: 30%;
  }
}
@media (max-width: 959px) {
  .homepage__watchlists {
    height: auto;
    justify-content: center;
  }
  .homepage__watchlists .demo-message {
    left: 10%;
    flex-basis: 70%;
  }
}
.homepage__watchlists .homepage__interactive-card {
  width: calc(33% - 8px);
  height: 208px;
  display: flex;
  flex-direction: column;
  margin: 8px 8px 0 0;
  padding: 0 16px 16px;
  justify-content: space-between;
}
@media (max-width: 1379px) {
  .homepage__watchlists .homepage__interactive-card {
    width: 163px;
    height: 172px;
  }
}
.homepage__watchlists .upgrade-plan, .homepage__watchlists .add-watchlist {
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: bold;
  color: #FFF;
  letter-spacing: 0.14em;
  text-align: center;
}
.homepage__watchlists .upgrade-plan .icon-container, .homepage__watchlists .add-watchlist .icon-container {
  width: 80px;
  height: 80px;
}
.homepage__watchlists .upgrade-plan:hover, .homepage__watchlists .add-watchlist:hover {
  color: #35B374;
}
.homepage__watchlists .upgrade-plan .add-watchlist__text, .homepage__watchlists .add-watchlist .add-watchlist__text {
  display: block;
  max-width: 100px;
  margin: 0 auto;
}
.homepage__watchlists .upgrade-plan svg, .homepage__watchlists .add-watchlist svg {
  display: block;
  width: 27px;
  height: 27px;
  margin: 0 auto;
}
.homepage__watchlists .upgrade-plan svg {
  margin-top: 0;
  width: 81px;
  height: 80px;
}
.homepage__watchlists .bg-img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center center;
}
.homepage__watchlists h3 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 700;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 2;
  text-align: center;
  top: 50%;
  margin-top: -14px;
}
.homepage__watchlists h3.new {
  position: relative;
  left: auto;
  top: auto;
  margin-top: 16px;
}
.homepage__watchlist {
  text-align: left;
  color: #FFF;
  position: relative;
}
.homepage__watchlist:hover {
  color: #35B374;
}
.homepage__watchlist:hover .homepage__watchlist-meta {
  color: #FFF;
}
.homepage .watchlist-icon {
  margin: 0 auto;
  display: block;
  position: relative;
}
.homepage__watchlist-icon-container {
  width: 100%;
  height: 100%;
  max-height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}
.homepage__watchlist-name {
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
  /*white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;*/
}
.homepage__watchlist-meta {
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: 400;
  color: #CCCCCC;
}
/* Colors */
/* Fonts */
/* Dimensions */
.pdf-rates .rate-indicator-icon {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
}
.pdf-rates .note-event {
  position: absolute;
  left: -40px;
  width: 38px;
  top: 1px;
  text-align: center;
  white-space: nowrap;
}
.pdf-rates .tag {
  color: #2DC46E;
  font-size: 13px;
  display: inline-block;
  margin-right: 6px;
  line-height: 20px;
}
.pdf-rates .note-icon {
  width: 14px;
  height: 14px;
  display: inline-block;
}
.pdf-rates .note-icon + .note-icon {
  margin-left: -4px;
}
.pdf-rates .note-icon.goal-icon {
  stroke: #000;
  fill: #FFF;
  margin-right: 6px;
}
.pdf-rates .note-icon.red-card-icon {
  fill: #ef3955;
  stroke: #ef3955;
}
.pdf-rates .note-icon.yellow-red-card-icon {
  fill: #ef3955;
  stroke: #ef3955;
}
.pdf-rates .note-icon.yellow-card-icon {
  fill: #fccf07;
  stroke: #fccf07;
}
.pdf-rates .pdf-rates-team {
  padding-left: 70px;
  position: relative;
  width: 40%;
  font-weight: 700;
  font-size: 26px;
  line-height: 24px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .pdf-rates-team {
    padding-left: 46px;
    font-size: 18px;
    line-height: 16px;
  }
}
.pdf-rates .pdf-rates-team .team-logo {
  width: 54px;
  height: 68px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -34px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
@media screen and (max-width: 959px) {
  .pdf-rates .pdf-rates-team .team-logo {
    width: 36px;
    height: 46px;
    margin-top: -23px;
  }
}
.pdf-rates .pdf-rates-list {
  margin: 40px 0 0;
}
@media screen and (max-width: 959px) {
  .pdf-rates .pdf-rates-list {
    margin: 20px 0 0;
  }
}
.pdf-rates .pdf-rates-list .title {
  text-transform: uppercase;
  border-bottom: 1px solid #D7D9D7;
  font-size: 14px;
  line-height: 18px;
  color: #393939;
  padding-bottom: 18px;
  font-weight: 700;
}
@media screen and (max-width: 959px) {
  .pdf-rates .pdf-rates-list .title {
    font-size: 11px;
    line-height: 12px;
    padding-bottom: 12px;
  }
}
.pdf-rates .rated-player {
  border-bottom: 1px solid #D7D9D7;
  padding: 40px 0 40px 120px;
  page-break-inside: avoid;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rated-player {
    padding: 20px 0 20px 80px;
  }
}
.pdf-rates .rated-player-name {
  white-space: nowrap;
  position: relative;
  padding: 25px 10px 29px 0;
  float: left;
  width: 190px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rated-player-name {
    padding: 10px 6px 20px 0;
    width: 126px;
  }
}
.pdf-rates .rated-player-name .player-photo {
  position: absolute;
  top: 50%;
  left: -120px;
  margin-top: -53px;
  width: 106px;
  height: 106px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rated-player-name .player-photo {
    left: -80px;
    margin-top: -35px;
    width: 70px;
    height: 70px;
  }
}
.pdf-rates .rated-player-name .add-to-favorites {
  position: absolute;
  bottom: -15px;
  left: -47px;
}
.pdf-rates .rated-player-name .rated-player-favorite {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid #E5E5E5;
  display: inline-block;
  background: #fff;
  color: #fff;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  position: relative;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rated-player-name .rated-player-favorite {
    width: 26px;
    height: 26px;
    line-height: 22px;
    bottom: -4px;
    left: -32px;
  }
}
.pdf-rates .rated-player-name .rated-player-favorite svg {
  vertical-align: middle;
  stroke: #2DC46E;
  width: 22px;
  height: 22px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rated-player-name .rated-player-favorite svg {
    width: 14px;
    height: 14px;
  }
}
.pdf-rates .rated-player-name .rated-player-favorite.active {
  background: #000;
  color: #fff;
}
.pdf-rates .rated-player-name .rated-player-favorite.active svg {
  stroke: #000;
}
.pdf-rates .rated-player-name .first_name {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rated-player-name .first_name {
    font-size: 13px;
    line-height: 13px;
  }
}
.pdf-rates .rated-player-name .last_name {
  font-weight: 600;
  font-size: 28px;
  line-height: 31px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rated-player-name .last_name {
    font-size: 18px;
    line-height: 18px;
  }
}
.pdf-rates .rated-player-info {
  float: left;
  padding: 0 0 0 22px;
  width: 209px;
  border-left: 1px solid #E9EBE9;
  border-right: 1px solid #E9EBE9;
  min-height: 106px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rated-player-info {
    padding: 0 0 0 11px;
    width: 140px;
    min-height: 53px;
  }
}
.pdf-rates .rated-player-info .info {
  vertical-align: top;
  display: inline-block;
  margin-right: 22px;
  white-space: nowrap;
  padding: 5px 0 10px;
  min-width: 30px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rated-player-info .info {
    margin-right: 14px;
    padding: 4px 0 7px;
    min-width: 20px;
  }
}
.pdf-rates .rated-player-info .info .info-value {
  font-weight: 400;
  font-size: 16px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rated-player-info .info .info-value {
    font-size: 11px;
  }
}
.pdf-rates .rated-player-info .info .info-name {
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  color: #565656;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rated-player-info .info .info-name {
    font-size: 10px;
  }
}
.pdf-rates .rates-group {
  float: right;
}
.pdf-rates .rates-table {
  padding: 5px 5px 5px 30px;
  border-left: 1px solid #E9EBE9;
  margin-left: -1px;
  min-height: 106px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rates-table {
    padding: 3px 8px 3px 15px;
    min-height: 53px;
  }
}
@media print {
  .pdf-rates .rates-table {
    min-height: 0;
  }
}
.pdf-rates .rates-table table {
  width: 270px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rates-table table {
    width: 181px;
  }
}
.pdf-rates .rates-table table thead td {
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  padding: 0 0 3px;
  border: none;
  border-bottom: 2px solid #CACCCA;
  vertical-align: bottom;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rates-table table thead td {
    font-size: 11px;
    padding: 0 0 2px;
  }
}
.pdf-rates .rates-table table thead td .offset {
  display: inline-block;
  width: 20px;
  text-align: center;
  position: relative;
  right: -10px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rates-table table thead td .offset {
    width: 14px;
    right: -7px;
  }
}
.pdf-rates .rates-table table thead .rates-table-title {
  float: left;
  font-size: 14px;
  color: #565656;
  font-weight: 400;
  position: relative;
  top: -3px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rates-table table thead .rates-table-title {
    font-size: 11px;
    top: -2px;
  }
}
.pdf-rates .rates-table table tbody tr td {
  font-weight: 400;
  font-size: 12px;
  line-height: 32px;
  padding: 0;
  border: 1px solid #CACCCA;
  border-bottom: 1px solid #E9EBE9;
  white-space: nowrap;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rates-table table tbody tr td {
    font-size: 10px;
    line-height: 20px;
  }
}
.pdf-rates .rates-table table tbody tr td:first-child {
  border-left: none;
}
.pdf-rates .rates-table table tbody tr td.fixed-width {
  width: 32px;
  font-size: 0;
  line-height: 0;
  vertical-align: middle;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rates-table table tbody tr td.fixed-width {
    width: 20px;
  }
}
.pdf-rates .rates-table table .rate-value {
  position: relative;
  width: 0;
  height: 0;
  float: left;
  display: inline-block;
}
.pdf-rates .rates-table table .rate-value .circle {
  position: absolute;
  top: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin: -7px 0 0 -7px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rates-table table .rate-value .circle {
    width: 10px;
    height: 10px;
    margin: -5px 0 0 -5px;
  }
}
.pdf-rates .final-rating {
  padding: 5px 15px 5px 30px;
  border-left: 1px solid #E9EBE9;
  margin-left: -1px;
  width: 306px;
  margin-top: 10px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .final-rating {
    padding: 3px 10px 3px 15px;
    width: 205px;
    margin-top: 5px;
  }
}
@media print {
  .pdf-rates .final-rating {
    margin-top: 0;
    padding-top: 15px;
  }
}
.pdf-rates .final-rating .final-rating-title {
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .final-rating .final-rating-title {
    font-size: 11px;
  }
}
.pdf-rates .final-rating .final-rating-list {
  text-align: right;
  margin: 0 -2px 20px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .final-rating .final-rating-list {
    margin-bottom: 13px;
  }
}
.pdf-rates .final-rating .final-rating-list .final-rating-item {
  display: inline-block;
  width: 36px;
  height: 36px;
  position: relative;
  margin-top: 10px;
  cursor: pointer;
}
@media screen and (max-width: 959px) {
  .pdf-rates .final-rating .final-rating-list .final-rating-item {
    width: 24px;
    height: 24px;
    margin-top: 7px;
    margin-left: 2px;
  }
}
.pdf-rates .final-rating .final-rating-list .final-rating-item:first-child {
  float: left;
}
.pdf-rates .final-rating .final-rating-list .final-rating-item .inner,
.pdf-rates .final-rating .final-rating-list .final-rating-item .outer {
  display: inline-block;
  content: "";
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  -webkit-transition: width 0.2s, height 0.2s, opacity 0.2s;
  -moz-transition: width 0.2s, height 0.2s, opacity 0.2s;
  transition: width 0.2s, height 0.2s, opacity 0.2s;
}
@media screen and (max-width: 959px) {
  .pdf-rates .final-rating .final-rating-list .final-rating-item .inner,
  .pdf-rates .final-rating .final-rating-list .final-rating-item .outer {
    width: 18px;
    height: 18px;
  }
}
.pdf-rates .final-rating .final-rating-list .final-rating-item .inner {
  opacity: 0;
}
.pdf-rates .final-rating .final-rating-list .final-rating-item .outer {
  border-style: solid;
  border-width: 2px;
}
.pdf-rates .final-rating .final-rating-list .final-rating-item .name {
  position: absolute;
  top: 100%;
  right: -30px;
  left: -30px;
  color: #2DC46E;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-top: 4px;
  -webkit-transition: font-weight 0.2s, color 0.2s, padding-top 0.2s;
  -moz-transition: font-weight 0.2s, color 0.2s, padding-top 0.2s;
  transition: font-weight 0.2s, color 0.2s, padding-top 0.2s;
}
@media screen and (max-width: 959px) {
  .pdf-rates .final-rating .final-rating-list .final-rating-item .name {
    right: -20px;
    left: -20px;
    font-size: 9px;
    margin-top: 3px;
  }
}
.pdf-rates .final-rating .final-rating-list .final-rating-item .name:before {
  content: "";
  width: 1px;
  height: 4px;
  background: #979797;
  position: absolute;
  top: -4px;
  left: 50%;
}
@media screen and (max-width: 959px) {
  .pdf-rates .final-rating .final-rating-list .final-rating-item .name:before {
    height: 3px;
  }
}
.pdf-rates .final-rating .final-rating-list .final-rating-item.active .inner {
  opacity: 1;
}
.pdf-rates .final-rating .final-rating-list .final-rating-item.active .outer {
  width: 36px;
  height: 36px;
  border-width: 1px;
  border-color: #000 !important;
}
@media screen and (max-width: 959px) {
  .pdf-rates .final-rating .final-rating-list .final-rating-item.active .outer {
    width: 24px;
    height: 24px;
  }
}
.pdf-rates .final-rating .final-rating-list .final-rating-item.active .name {
  color: #2DC46E;
  font-weight: 700;
}
@media screen and (max-width: 959px) {
  .pdf-rates .final-rating .final-rating-list .final-rating-item.active .name {
    padding-top: 2px;
  }
}
.pdf-rates .rated-notes {
  width: 378px;
  float: left;
  line-height: 22px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rated-notes {
    width: 252px;
  }
}
.pdf-rates .rated-notes .player-note-group {
  position: relative;
  margin-top: 20px;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rated-notes .player-note-group {
    margin-top: 12px;
  }
}
.pdf-rates .rated-notes .player-note-group .note-group {
  margin-left: -50px;
  font-weight: 700;
  font-size: 16px;
  width: 106px;
  white-space: nowrap;
}
.pdf-rates .rated-notes .player-note-item {
  position: relative;
  margin-top: 5px;
}
.pdf-rates .rated-notes .player-note-item .note-min {
  position: absolute;
  top: 0;
  left: -60px;
  font-weight: 700;
  font-size: 16px;
  min-width: 42px;
  white-space: nowrap;
  font-size: 12px;
}
.pdf-rates .rated-notes .player-note-item .note-min .period {
  max-width: 64px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rated-notes .player-note-item .note-min {
    left: -40px;
    font-size: 11px;
    line-height: 14px;
  }
}
.pdf-rates .rated-notes .player-note-item .note {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
  width: 100%;
  border: none;
  resize: none;
  padding: 0;
  font-weight: normal;
  display: block;
}
@media screen and (max-width: 959px) {
  .pdf-rates .rated-notes .player-note-item .note {
    font-size: 10px;
    line-height: 14px;
  }
}
.pdf-rates .pdf-team-notes {
  margin: 45px 0 0;
}
@media screen and (max-width: 959px) {
  .pdf-rates .pdf-team-notes {
    margin: 30px 0 0;
  }
}
.pdf-rates .pdf-team-notes .title {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  color: #393939;
  font-weight: 700;
}
@media screen and (max-width: 959px) {
  .pdf-rates .pdf-team-notes .title {
    font-size: 10px;
    line-height: 12px;
  }
}
.pdf-rates .pdf-team-notes .note {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 15px;
  line-height: 22px;
  width: 100%;
  border: none;
  resize: none;
  margin: 20px 0 0;
  padding: 0;
  font-weight: normal;
}
@media screen and (max-width: 959px) {
  .pdf-rates .pdf-team-notes .note {
    font-size: 10px;
    line-height: 12px;
    margin: 13px 0 0;
  }
}
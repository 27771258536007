/* Colors */
/* Fonts */
/* Dimensions */
.help {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  height: 100%;
}
.help .lang-selector {
  margin: 25px 0 0 0;
}
.help .lang-selector .container {
  padding-top: 0;
  padding-bottom: 0;
}
.help .lang-selector .js-link {
  border-bottom: 1px dotted;
  display: inline-block;
  margin-left: 15px;
}
.help .lang-selector .js-link:nth-child(1) {
  margin-left: 0;
}
.help .lang-selector .js-link.active {
  color: #FFF;
  border-bottom: none;
  font-weight: 700;
}
.help .Collapsible {
  margin: 30px 0 0;
}
.help .Collapsible__trigger {
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: 700;
  border-bottom: 1px dashed;
  padding-bottom: 2px;
  cursor: pointer;
}
.help .Collapsible__trigger.is-closed {
  color: #2DC46E;
}
.help .Collapsible__trigger.is-open {
  color: #FFF;
}
@media (max-width: 959px) {
  .help .Collapsible__trigger {
    font-size: 14px;
  }
}
.help .help-indicator {
  display: inline-block;
  width: 32.5%;
  vertical-align: top;
  padding: 15px;
  margin-top: 30px;
  overflow: hidden;
}
.help .help-indicator:nth-child(1), .help .help-indicator:nth-child(2), .help .help-indicator:nth-child(3), .help .help-indicator:nth-child(4) {
  margin-top: 0;
}
@media (max-width: 959px) {
  .help .help-indicator {
    margin-top: 15px;
    width: 49.5%;
  }
}
.help .help-indicator svg {
  display: block;
  width: 35px;
  height: 35px;
  float: left;
  margin-right: 15px;
  color: #2DC46E;
}
.help .help-indicator .help-indicator-body {
  display: block;
  overflow: hidden;
}
.help .help-indicator .help-indicator-body strong {
  text-transform: uppercase;
  display: block;
}
.help .help-indicator-header {
  display: block;
  text-align: center;
}
.help .help-indicator-header svg {
  width: 50px;
  height: 50px;
  color: #2DC46E;
}
.help .sub-nav__content h2 {
  margin: 60px 0 0;
  font-size: 25px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 959px) {
  .help .sub-nav__content h2 {
    font-size: 16px;
  }
}
.help .sub-nav__content h3 {
  font-weight: 700;
  margin: 60px 0 0;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
}
.help .sub-nav__content h4 {
  font-size: 22px;
  margin-bottom: 25px;
  font-weight: 500;
}
.help .sub-nav__content p.hero {
  font-size: 17px;
  line-height: 170%;
  margin-bottom: 50px;
  display: block;
}
.help .sub-nav__content p {
  margin-top: 7px;
  font-size: 16px;
  line-height: 135%;
  max-width: 650px;
  opacity: 0.9;
}
.help .sub-nav__content p a {
  color: #2DC46E;
}
.help .sub-nav__content p a:hover {
  color: #188538;
}
.help .sub-nav__content .p__btn {
  margin-top: 35px;
}
.help .sub-nav__content .p__btn .btn {
  color: #FFF;
}
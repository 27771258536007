/* Colors */
/* Fonts */
/* Dimensions */
.dw-analytics__info .content-row:after, .dw-analytics .content-block:after {
  content: "";
  display: table;
  clear: both;
}

.dw-analytics__content {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1279px) {
  .dw-analytics__content {
    flex-direction: column;
  }
}
.dw-analytics .content-block {
  background: #293B47;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 2px 16px 1px rgba(0, 0, 0, 0.1);
  padding: 24px 0;
  margin-top: 25px;
}
.dw-analytics .content-block hr {
  background: rgba(53, 76, 92, 0.5);
  margin: 25px;
}
.dw-analytics .content-block:nth-child(1) {
  margin-top: 0;
}
.dw-analytics__help {
  display: flex;
  flex-wrap: wrap;
  margin-top: 36px;
}
.dw-analytics__help-block {
  width: 180px;
  margin-right: 35px;
  margin-bottom: 24px;
}
.dw-analytics__help-block h4 {
  font-weight: bold;
  margin-bottom: 8px;
}
.dw-analytics__info {
  width: calc(33.3% - 10px);
}
@media (max-width: 1279px) {
  .dw-analytics__info {
    margin-top: 25px;
    width: auto;
  }
  .dw-analytics__info h4 {
    font-size: 16px;
    line-height: 22px;
  }
}
.dw-analytics__info .content-pad {
  padding: 0 24px;
}
.dw-analytics__info .content-row {
  display: flex;
  margin-top: 20px;
}
.dw-analytics__info .content-row .content-col-50 {
  width: calc(50% - 18px);
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 35px;
}
.dw-analytics__info .content-row .content-col-50:nth-child(1) {
  margin-left: 0;
}
.dw-analytics__info .content-row .content-col-66 {
  width: calc(66.7% - 5px);
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 35px;
}
.dw-analytics__info .content-row .content-col-66:nth-child(1) {
  margin-left: 0;
}
.dw-analytics__info .content-row .content-col-33 {
  width: calc(33.3% - 18px);
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 35px;
}
.dw-analytics__info .content-row .content-col-33:nth-child(1) {
  margin-left: 0;
}
.dw-analytics__info .content-row.small-margin {
  margin-top: 5px;
}
.dw-analytics__info .content-row:nth-child(1) {
  margin-top: 0;
}
.dw-analytics__info .content-row .data-item, .dw-analytics__info .content-row .data-item-icon, .dw-analytics__info .content-row .data-item-changeable {
  margin-left: 35px;
}
.dw-analytics__info .content-row .data-item:nth-child(1), .dw-analytics__info .content-row .data-item-icon:nth-child(1), .dw-analytics__info .content-row .data-item-changeable:nth-child(1) {
  margin-left: 0;
}
.dw-analytics__info .player-data {
  display: flex;
}
.dw-analytics__info .player-data__image {
  width: 72px;
  height: 72px;
  border-radius: 36px;
  margin-right: 24px;
  border: 2px solid #35B374;
  background-color: rgba(53, 179, 116, 0.5);
}
.dw-analytics__info .player-data__image .player-image {
  width: 68px;
  height: 68px;
  background-size: cover;
  background-position: center center;
  border-radius: 34px;
}
.dw-analytics__info .player-data__content {
  flex-grow: 1;
}
.dw-analytics__info .player-data__content .data-item, .dw-analytics__info .player-data__content .data-item-icon, .dw-analytics__info .player-data__content .data-item-changeable {
  flex-grow: 1;
  max-width: 50%;
}
.dw-analytics__info .player-data__content .data-item .select_input, .dw-analytics__info .player-data__content .data-item-icon .select_input, .dw-analytics__info .player-data__content .data-item-changeable .select_input {
  color: #FFF;
}
.dw-analytics__info .player-data__firstName {
  font-size: 18px;
  line-height: 23px;
}
.dw-analytics__info .player-data__lastName {
  font-size: 36px;
  line-height: 40px;
  font-weight: bold;
}
.dw-analytics__info .player-data .data-row {
  display: flex;
  margin-top: 25px;
}
.dw-analytics__info .player-data .team-row {
  margin-top: 45px;
}
.dw-analytics__chart {
  padding: 75px;
  margin-left: 50px;
  width: calc(60% - 50px);
  max-height: 480px;
}
@media (max-width: 1279px) {
  .dw-analytics__chart {
    width: auto;
    margin-left: 0;
    margin-top: 25px;
  }
}
.dw-analytics__chart svg {
  width: 100%;
  height: auto;
  max-height: 480px;
}

.indicator-tip {
  width: 190px !important;
}

.matchmetrics-spiderchart__chart__label.matchmetrics-spiderchart__chart__label--name {
  font-family: "Source Sans Pro", Helvetica, sans-serif !important;
  cursor: default;
}
.matchmetrics-spiderchart__chart__label.matchmetrics-spiderchart__chart__label--name + text {
  font-family: "Source Sans Pro", Helvetica, sans-serif !important;
  font-weight: normal !important;
}
.matchmetrics-spiderchart__chart__label.matchmetrics-spiderchart__chart__label--name + text + text {
  font-family: "Source Sans Pro", Helvetica, sans-serif !important;
  font-weight: normal !important;
}
/* Colors */
/* Fonts */
/* Dimensions */
.add-new {
  min-height: 30%;
  position: relative;
  margin-bottom: 40px;
}
@media (max-width: 959px) {
  .add-new {
    margin-bottom: 20px;
    min-height: 0;
  }
}
.add-new .Select-placeholder, .add-new .Select-value-label {
  font-size: 16px;
  font-weight: 400 !important;
}
@media (max-width: 959px) {
  .add-new .Select-placeholder, .add-new .Select-value-label {
    font-size: 14px;
  }
}
.add-new .add-player-form {
  position: relative;
}
.add-new .add-player-form .add-close {
  position: absolute;
  top: 15px;
  right: 12px;
  cursor: pointer;
  display: block;
  padding: 3px;
}
@media (max-width: 959px) {
  .add-new .add-player-form .add-close {
    right: 6px;
    top: 8px;
    padding: 2px;
  }
}
.add-new .add-player-form .row {
  margin-top: 20px;
}
@media (max-width: 959px) {
  .add-new .add-player-form .row {
    margin-top: 10px;
  }
}
.add-new .add-player-title {
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: -10px;
}
@media (max-width: 959px) {
  .add-new .add-player-title {
    font-size: 11px;
    margin-bottom: -5px;
  }
}
.add-new .input-placeholder-example {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.75);
}
.add-new .buttons-flex {
  display: flex;
}
.add-new .buttons-flex .btn-red {
  margin-left: 16px;
}
.add-new .team {
  position: relative;
  z-index: 3;
}
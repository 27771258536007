.match-card__tag {
  position: relative;
  left: -6px;
  padding: 0 6px;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.6px;
  font-weight: 700;
  border-radius: 10px;
  color: #FFF;
  white-space: nowrap;
  display: inline-block;
  text-transform: uppercase;
}
.match-card__tag.in_progress {
  background-color: rgba(244, 171, 59, 0.5);
}
.match-card__tag.demo {
  background-color: rgba(159, 81, 191, 0.5);
}
.match-card__tag.completed {
  background-color: rgba(103, 203, 139, 0.5);
}
.match-card__tag.not_started {
  background-color: rgba(212, 60, 102, 0.5);
}
/* Colors */
/* Fonts */
/* Dimensions */
.survey-form {
  padding: 24px 32px;
  background: #FFF;
  max-width: 660px;
  min-width: 320px;
  margin: 0 auto;
  color: #1C2734;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  border-radius: 12px;
}
.survey-form h1, .survey-form h3 {
  margin: 0;
  font-size: 24px;
  line-height: 32px;
}
.survey-form h1 {
  font-weight: bold;
}
.survey-form h3 {
  font-weight: 400;
}
.survey-form .reason {
  position: relative;
  background: #F1F4F6;
  padding: 12px 16px 16px 56px;
  border-radius: 4px;
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;
  min-height: 80px;
  cursor: pointer;
  user-select: none;
}
.survey-form .reason .c-checkbox {
  position: absolute;
  left: 16px;
  top: 16px;
  width: 24px;
  height: 24px;
  pointer-events: none;
}
.survey-form .reason .c-checkbox svg {
  width: 14px;
  height: 14px;
  margin-left: -7px;
  margin-top: -7px;
}
.survey-form .reason .reason-name {
  font-weight: bold;
  vertical-align: middle;
}
.survey-form .reason .reason-name svg {
  width: 24px;
  height: 24px;
  fill: none;
  stroke: currentColor;
  display: inline-block;
  position: relative;
  margin-right: 6px;
  top: 4px;
}
.survey-form .reason .other-input {
  display: block;
  margin-top: 8px;
  color: #1C2734;
  background: #FFF;
  border: none;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  border-bottom: 2px transparent;
}
.survey-form .reason .other-input::placeholder {
  color: #808080;
}
.survey-form .reason .other-input.error {
  border-bottom: 2px solid #d33a61;
}
.survey-form .reason .other-input.error::placeholder {
  color: #d33a61;
}
.survey-form .submit-row {
  text-align: right;
  margin-top: 16px;
}
.survey-form .submit-row .btn {
  border: none;
  background: #35B374;
  color: #FFF;
}
.survey-form .submit-row .btn:hover {
  background: #1C2734;
  color: #FFF;
}
.survey-form .submit-row .btn.disabled {
  background: #5F7582;
  color: #CCC;
  cursor: default;
  pointer-events: none;
}
.survey-form .submit-row .btn.disabled:hover {
  background: #5F7582;
  color: #FFF;
}
/* Colors */
/* Fonts */
/* Dimensions */
.registration-form {
  background-image: url(../../../images/auth_bg3.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #021c29;
  font-family: "IBM Plex Sans", sans-serif;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  color: #1C2734;
}
.registration-form .currency {
  color: #1C2734;
  font-size: 13px;
  margin-top: 12px;
}
.registration-form .error-message {
  background: rgba(255, 235, 235, 0.85) !important;
  font-size: 15px;
  color: red;
  line-height: 20px;
  margin: 0 0 24px;
  padding: 12px;
  border-radius: 3px;
}
.registration-form .error-message a {
  color: red;
  text-decoration: underline;
}
.registration-form .error-message a:hover {
  color: red;
}
.registration-form .wrapper {
  width: 100%;
  height: calc(100vh - 121px);
  min-height: 700px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.registration-form .wrapper .main {
  position: relative;
}
.registration-form .message {
  max-width: 333px;
  min-width: 200px;
  margin: 0 auto;
  background: #FFF;
  border-radius: 12px;
  padding: 24px 32px;
  text-align: left;
}
.registration-form .message h2 {
  font-size: 24px;
  line-height: 24px;
  margin: 0;
  font-weight: bold;
  font-family: "IBM Plex Sans", sans-serif;
}
.registration-form .message .img {
  display: block;
  margin: 24px auto;
}
.registration-form .message p {
  font-size: 16px;
  line-height: 24px;
}
.registration-form form {
  width: 692px;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}
@media (max-width: 679px) {
  .registration-form form {
    width: auto;
    max-width: 340px;
  }
}
.registration-form form .row {
  display: flex;
  background: none;
}
@media (max-width: 679px) {
  .registration-form form .row {
    padding: 0;
    background: transparent;
  }
}
.registration-form form .row .col {
  width: 50%;
  padding: 24px 32px;
  background: #FFF;
  border-radius: 12px;
  position: relative;
}
.registration-form form .row .col h2.heading {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  margin: 0;
}
.registration-form form .row .col .preferred-currency {
  margin-top: 24px;
}
.registration-form form .row .col .currency-changer {
  display: inline-block;
  vertical-align: middle;
}
.registration-form form .row .col .currency-changer span {
  display: inline-block;
  margin-right: 12px;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
}
.registration-form form .row .col .currency-changer span.active {
  font-weight: bold;
  color: #35B374;
  cursor: default;
}
.registration-form form .row .col:nth-child(1) {
  width: 50%;
  margin-right: 25px;
}
@media (max-width: 679px) {
  .registration-form form .row .col:nth-child(1) {
    width: auto;
    margin-right: 0;
  }
}
@media (max-width: 679px) {
  .registration-form form .row .col:nth-child(2) {
    display: none;
    width: auto;
  }
}
.registration-form form .features {
  font-size: 16px;
  line-height: 24px;
}
.registration-form form .features li {
  margin-top: 24px;
  position: relative;
  padding-left: 32px;
  font-size: 16px;
  line-height: 24px;
}
.registration-form form .features li svg {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
}
.registration-form form .features li.text {
  padding-left: 0;
  margin: 24px 0;
}
.registration-form form .features li.heading {
  padding-left: 0;
  margin-top: 0;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 18px;
  font-weight: bold;
}
.registration-form form .input {
  margin-top: 16px;
}
.registration-form form .input label {
  display: block;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;
}
.registration-form form input {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 24px;
  border: none;
  font-family: "IBM Plex Sans", sans-serif;
  color: #1C2734;
  background: #F1F4F6;
  border-radius: 4px;
}
.registration-form form input.error {
  color: red;
  background: rgba(255, 235, 235, 0.85) !important;
}
.registration-form form input::placeholder {
  color: #808080;
  font-size: 16px;
  opacity: 1;
}
.registration-form form .btn {
  color: #FFF;
  background: #35B374;
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
  padding: 12px;
  border: none;
  border-radius: 12px !important;
  text-transform: uppercase;
  cursor: pointer;
}
.registration-form form .btn:hover {
  background-color: #1C2734;
}
.registration-form form .terms {
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: #1C2734;
  margin: 24px 0;
}
.registration-form form .terms a {
  text-decoration: underline;
  color: #1C2734;
}
.registration-form form .terms a:hover {
  color: #35B374;
}
.registration-form .signin {
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
}
.registration-form .signin a {
  color: #1C2734;
  text-decoration: underline;
}
.registration-form .signin a:hover {
  color: #35B374;
}
.registration-form #Loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;
}
.registration-form #Loader .loader-image {
  width: 120px;
  height: 120px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -60px 0 0 -60px;
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 160px 0;
  }
}
/* Colors */
/* Fonts */
/* Dimensions */
.add-to-favorites {
  z-index: 1;
}
.add-to-favorites .trigger {
  display: inline-block;
}
.add-to-favorites .trigger .tri {
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(8px, 2px);
  width: 0;
  height: 0;
  border-color: #2DC46E;
  color: #2DC46E;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid;
  position: absolute;
}
.add-to-favorites .trigger svg {
  fill: none;
  stroke: #2DC46E;
}
.add-to-favorites .trigger.active.cat_0 svg {
  fill: #5F7582;
  stroke: #5F7582;
}
.add-to-favorites .trigger.active.cat_0 .tri {
  color: #5F7582;
}
.add-to-favorites .trigger.active.cat_1 svg {
  fill: #EFDD30;
  stroke: #EFDD30;
}
.add-to-favorites .trigger.active.cat_1 .tri {
  color: #EFDD30;
}
.add-to-favorites .trigger.active.cat_2 svg {
  fill: #F98736;
  stroke: #F98736;
}
.add-to-favorites .trigger.active.cat_2 .tri {
  color: #F98736;
}
.add-to-favorites .trigger.active.cat_3 svg {
  fill: #DD4043;
  stroke: #DD4043;
}
.add-to-favorites .trigger.active.cat_3 .tri {
  color: #DD4043;
}
.add-to-favorites .fav_icon {
  stroke: #2DC46E;
  fill: none;
  width: 20px;
  height: 20px;
}
.add-to-favorites .fav_icon.cat_0 {
  fill: #5F7582;
  stroke: #5F7582;
}
.add-to-favorites .fav_icon.cat_1 {
  fill: #EFDD30;
  stroke: #EFDD30;
}
.add-to-favorites .fav_icon.cat_2 {
  fill: #F98736;
  stroke: #F98736;
}
.add-to-favorites .fav_icon.cat_3 {
  fill: #DD4043;
  stroke: #DD4043;
}
.add-to-favorites .list {
  margin-top: 5px;
  position: absolute;
  top: 100%;
  right: 0;
  font-size: 15px;
  color: #000000;
  line-height: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  width: 160px;
  background: #FFF;
  z-index: 1;
}
.add-to-favorites .list li {
  padding: 10px 10px;
  font-size: 15px;
  line-height: 21px;
  border-top: 1px solid #E1E1E1;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
}
.add-to-favorites .list li:hover {
  background: #e6e6e6;
}
.add-to-favorites .list li .fav_icon {
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -10px;
}
.add-to-favorites .list li:nth-child(1) {
  border-radius: 4px 4px 0 0;
  border-top: none;
}
.add-to-favorites .list li:nth-last-child(1) {
  border-radius: 0 0 4px 4px;
}
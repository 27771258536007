/* Colors */
/* Fonts */
/* Dimensions */
.shadow-team__add-player {
  position: relative;
  background: #2A333A;
  margin-left: -30px;
  margin-right: -30px;
  border-radius: 2px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
}
.shadow-team__add-player .empty-message {
  padding: 4px 6px !important;
  margin: 0 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  opacity: 0.8;
  width: 100% !important;
}
.shadow-team__add-player ul {
  max-height: 240px;
}
.shadow-team__add-player ul li {
  height: 35px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  user-select: none;
}
.shadow-team__add-player__player {
  border-top: 1px solid #294254;
  font-size: 14px;
  line-height: 26px;
}
.shadow-team__add-player__player > span {
  padding: 4px 6px;
  display: block;
}
.shadow-team__add-player__player:hover {
  color: #2DC46E;
}
.shadow-team__add-player__player.active {
  background: #1f262b;
}
.shadow-team__add-player__player .team-image {
  width: 17px;
  height: 22px;
  position: relative;
  top: -2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  background-size: contain;
}
.shadow-team__add-player__player:nth-child(1) {
  border-top: none;
}
.shadow-team__add-player__position {
  border-top: 1px solid #294254;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 400;
  height: 25px !important;
  letter-spacing: 1px;
  background: #323D45;
  opacity: 0.8;
  padding: 5px 6px 3px 10px;
  cursor: default;
}
.shadow-team__add-player-filter input {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #101a20;
}
.shadow-team__add-player-heading {
  background: #323D45;
  color: rgba(255, 255, 255, 0.6);
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 14px;
  padding: 7px 10px;
  border-radius: 2px;
  text-transform: uppercase;
}
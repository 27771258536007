/* Colors */
/* Fonts */
/* Dimensions */
.match-card {
  margin: 0 18px 8px 0;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  background-color: #3A4C5A;
  border: 1px solid #3A4C5A;
  border-radius: 6px;
  height: 130px;
  width: 320px;
  justify-content: stretch;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
  color: #FFF;
  /*Attributes*/
  /*Controls*/
  /* Content */
}
.match-card__add {
  margin-top: 8px;
}
.match-card__add svg {
  width: 16px;
  height: 16px;
  fill: currentColor;
}
.match-card__container {
  display: inline-block;
  height: 138px;
  width: 338px;
}
.match-card__shadow {
  background: #24323D;
  border-color: #24323D;
  box-shadow: none;
  pointer-events: none;
}
.match-card__shadow:hover {
  background: #24323D;
  border-color: #24323D;
  box-shadow: none;
}
.match-card__loading {
  pointer-events: none;
  opacity: 0.5;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: opacitySpin 1s ease-in-out infinite;
}
.match-card__loading.no-animation {
  animation: none;
}
.match-card__attrs, .match-card__info, .match-card__controls {
  box-sizing: border-box;
}
.match-card__attrs {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 6px 13px 12px 16px;
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.match-card__info {
  flex-grow: 1;
  padding: 16px 16px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 176px);
}
.match-card__controls {
  flex-grow: 0;
  flex-shrink: 0;
  width: 46px;
  padding: 16px 0;
  border-left: 1px solid #2D3B46;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.match-card__date {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #CCCCCC;
  text-transform: uppercase;
}
.match-card__league {
  font-size: 12px;
  line-height: 16px;
  color: #637581;
  margin-top: 7px;
  text-transform: uppercase;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}
.match-card__by {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #637581;
  margin-top: 0;
}
.match-card__button {
  box-sizing: border-box;
  display: block;
  width: 40px;
  height: 40px;
  color: #FFF;
}
.match-card__button:hover {
  color: #35B374;
}
.match-card__button svg {
  width: 40px;
  height: 40px;
  stroke: currentColor;
  fill: none;
}
.match-card__button svg.pdf-icon {
  fill: currentColor;
  stroke: none;
}
.match-card__name {
  display: block;
  width: 100%;
  max-height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.match-card__divider {
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  color: #637581;
  text-align: center;
  position: relative;
  margin-left: 32px;
}
.match-card__divider:before, .match-card__divider:after {
  content: "";
  height: 1px;
  background: #637581;
  position: absolute;
  width: calc(50% - 15px);
}
.match-card__divider:before {
  left: 0;
  top: 8px;
}
.match-card__divider:after {
  right: 0;
  top: 8px;
}
.match-card:hover {
  background-color: #24323D;
  cursor: pointer;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgwIiBoZWlnaHQ9IjEzMCIgdmlld0JveD0iMCAwIDI4MCAxMzAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMDguMjY3IC05NS4zNDM4TDMwMC4zIC0zLjMxMTIyTDcxLjg2NTMgMjI1LjEyM0wtMjAuMTY3MiAxMzMuMDkxTDIwOC4yNjcgLTk1LjM0MzhaIiBmaWxsPSIjMkQzQjQ2Ii8+Cjwvc3ZnPgo=");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #3A4C5A;
  color: #35B374;
}

@keyframes opacitySpin {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
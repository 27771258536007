.inline-loader-container {
  display: inline-block;
  height: 5em;
  width: 3em;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
}

.inline-loader,
.inline-loader:before,
.inline-loader:after {
  background: #2dc46e;
  -webkit-animation: inline-loader-animation 1s infinite ease-in-out;
  animation: inline-loader-animation 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
  display: block;
  will-change: height, box-shadow;
}

.inline-loader {
  color: #2dc46e;
  text-indent: -9999em;
  margin: 22px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.inline-loader:before,
.inline-loader:after {
  position: absolute;
  top: 0;
  content: "";
}

.inline-loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.inline-loader:after {
  left: 1.5em;
}

@-webkit-keyframes inline-loader-animation {
  0%, 80%, 100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes inline-loader-animation {
  0%, 80%, 100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
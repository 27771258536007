.final-rating-overtime__heading {
  font-size: 18px;
  line-height: 26px;
}
.final-rating-overtime__heading label {
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.7);
}
.final-rating-overtime__body {
  margin-top: 25px;
}
.final-rating-overtime__body label {
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.7);
}
.final-rating-overtime__body table {
  margin-top: 5px;
}
.final-rating-overtime__body table tr, .final-rating-overtime__body table td {
  border: none;
  padding: 0;
}
.final-rating-overtime__body table td {
  padding-top: 5px;
  vertical-align: middle;
}
.final-rating-overtime__body table .season-col {
  width: 30px;
  font-size: 13px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.9);
  padding-right: 10px;
}
.final-rating-overtime__body table .reports-col {
  width: 80px;
  font-size: 13px;
  line-height: 18px;
  padding-left: 10px;
}
.final-rating-overtime__body table .bar {
  display: flex;
  height: 8px;
  border-radius: 4px;
}
.final-rating-overtime__body table .bar .bar-section {
  height: 8px;
  flex-grow: 1;
  flex-shrink: 1;
}
.final-rating-overtime__body table .bar .bar-section:nth-child(1) {
  border-radius: 4px 0 0 4px;
}
.final-rating-overtime__body table .bar .bar-section:nth-last-child(1) {
  border-radius: 0 4px 4px 0;
}
.final-rating-overtime__body table .bar .bar-section.bordered {
  border-left: 1px solid #7FAF7E;
}
.final-rating-overtime__body table .bar .bar-section.single {
  border-radius: 4px;
}
.final-rating-overtime__average {
  margin-top: 25px;
  font-size: 18px;
  line-height: 26px;
}
.final-rating-overtime__average label {
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.7);
}
.final-rating-overtime__average .color-round {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 6px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.final-rating-overtime__average .description {
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: #A4A6A4;
}
.final-rating-overtime__average-icon {
  margin: 8px 0;
}
/* Colors */
/* Fonts */
/* Dimensions */
.side-controls {
  position: absolute;
  top: 0;
  right: -285px;
  bottom: 0;
  width: 285px;
  background-color: #1c2830;
}
@media (max-width: 959px) {
  .side-controls {
    width: 170px;
    right: -170px;
    z-index: 10;
  }
}
.side-controls .side-controls__close {
  position: absolute;
  top: 20px;
  right: 19px;
  cursor: pointer;
  padding: 10px;
  z-index: 5;
}
@media (max-width: 959px) {
  .side-controls .side-controls__close {
    top: 3px;
    right: 3px;
  }
}
.side-controls .side-controls__close svg {
  width: 24px;
  height: 24px;
}
@media (max-width: 959px) {
  .side-controls .side-controls__close svg {
    width: 14px;
    height: 14px;
  }
}
/* Colors */
/* Fonts */
/* Dimensions */
.history {
  position: absolute;
  right: 0;
  left: 0;
  background: #324859;
}
.history .history-group {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.7);
  padding: 24px 0 8px 15px;
}
.history .history-group:nth-child(odd) {
  background: #303F4E;
}
.history .story {
  padding: 12px 15px 12px 75px;
  position: relative;
  font-size: 16px;
  line-height: 19px;
}
@media (max-width: 959px) {
  .history .story {
    padding: 6px 8px 6px 45px;
    font-size: 12px;
    line-height: 14px;
  }
}
.history .story .story-name {
  margin: 0 0 5px;
}
.history .story .story-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 4px;
}
@media (max-width: 959px) {
  .history .story .story-icon {
    width: 13px;
    height: 13px;
  }
}
.history .story .story-icon__event {
  top: 4px;
  fill: none;
  stroke: #FFF;
}
.history .story:nth-child(odd) {
  background: #303F4E;
}
.history .story .story-time {
  position: absolute;
  top: 12px;
  left: 15px;
}
.history .story .story-time__single {
  top: 19px;
}
@media (max-width: 959px) {
  .history .story .story-time {
    top: 6px;
    left: 7px;
  }
  .history .story .story-time__single {
    top: 12px;
  }
}
.history .story .story-image {
  position: absolute;
  top: 37px;
  left: 23px;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (max-width: 959px) {
  .history .story .story-image {
    top: 33px;
    left: 13px;
    width: 14px;
    height: 14px;
  }
}
.history .story .story-value {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
  height: 32px;
  line-height: 32px;
  border-radius: 16px;
  max-width: 100%;
  position: relative;
}
@media (max-width: 959px) {
  .history .story .story-value {
    height: 26px;
    line-height: 26px;
  }
}
.history .story .story-value.story-value-2 {
  background: #7CB4D3;
}
.history .story .story-value.story-value-1 {
  background: #7CB4D3;
}
.history .story .story-value.story-value0 {
  background: #A4A6A4;
}
.history .story .story-value.story-value1 {
  background: #EB8C49;
}
.history .story .story-value.story-value2 {
  background: #CA5425;
}
.history .story .story-delete {
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -11px;
  color: #d33a61;
  z-index: 3;
}
@media (max-width: 959px) {
  .history .story .story-delete {
    margin-top: -10px;
    right: 10px;
  }
}
.history .story .story-delete svg {
  width: 22px;
  height: 22px;
}
@media (max-width: 959px) {
  .history .story .story-delete svg {
    width: 16px;
    height: 16px;
  }
}
.history.edit .story {
  padding-right: 43px;
}
.history .history-header {
  height: 46px;
  background: #324859;
  text-align: justify;
  line-height: 50px;
  text-transform: uppercase;
  font-size: 14px;
}
@media (max-width: 959px) {
  .history .history-header {
    font-size: 11px;
    height: 32px;
    line-height: 32px;
  }
}
.history .history-header .title, .history .history-header .edit-btn {
  display: inline-block;
  padding: 0 15px;
  letter-spacing: 1px;
}
@media (max-width: 959px) {
  .history .history-header .title, .history .history-header .edit-btn {
    padding: 0 5px;
    letter-spacing: 0.2px;
  }
}
.history .history-header .edit-btn {
  letter-spacing: 0.2px;
  color: #2DC46E;
  font-size: 11px;
}
.history .history-header:after {
  display: inline-block;
  width: 99%;
  overflow: hidden;
  height: 0;
  content: " ";
}
.history .history-list {
  overflow: auto;
}
.history .substitution-icon {
  border-style: solid;
  height: 0;
  width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}
.history .substitution-icon.substitution_in {
  border-color: transparent transparent #35B374;
  border-width: 0 8px 10px;
}
.history .substitution-icon.substitution_out {
  border-color: #d33a61 transparent transparent;
  border-width: 10px 8px 0;
}
/* Colors */
/* Fonts */
/* Dimensions */
.users-list {
  line-height: 20px;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  margin: 0;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
}
@media (max-width: 959px) {
  .users-list {
    line-height: 13px;
    font-size: 11px;
  }
}
.users-list input, .users-list .has-value.Select--single > .Select-control .Select-value .Select-value-label {
  font-weight: 300 !important;
  font-size: 15px;
}
@media (max-width: 959px) {
  .users-list input, .users-list .has-value.Select--single > .Select-control .Select-value .Select-value-label {
    font-size: 11px;
  }
}
.users-list th, .users-list td {
  text-align: left;
  padding: 15px 10px;
  font-size: 15px;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 959px) {
  .users-list th, .users-list td {
    padding: 8px 5px;
    font-size: 11px;
  }
}
.users-list th:first-child, .users-list td:first-child {
  padding-left: 20px;
}
@media (max-width: 959px) {
  .users-list th:first-child, .users-list td:first-child {
    padding-left: 10px;
  }
}
.users-list th:last-child, .users-list td:last-child {
  padding-right: 20px;
}
@media (max-width: 959px) {
  .users-list th:last-child, .users-list td:last-child {
    padding-right: 10px;
  }
}
.users-list th.controls-col, .users-list td.controls-col {
  text-align: right;
  width: 150px;
}
@media (max-width: 959px) {
  .users-list th.controls-col, .users-list td.controls-col {
    width: 75px;
  }
}
.users-list th {
  font-weight: 700;
  padding-top: 0;
}
.users-list .align {
  display: block;
}

.create-container div.users-list {
  text-align: right;
  padding: 15px 20px;
}
@media (max-width: 959px) {
  .create-container div.users-list {
    padding: 8px 10px;
  }
}
.create-container td {
  border-bottom: none;
}

.limits-message {
  padding: 0px 0px 25px 0px;
  font-size: 18px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.7);
}

.users-empty-message {
  text-align: left;
  font-size: 18px;
  padding: 25px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.7);
}
/* Colors */
/* Fonts */
/* Dimensions */
.dd-group-heading {
  display: flex;
  cursor: pointer;
}
.dd-group-heading * {
  cursor: pointer;
}
.dd-group-heading .dd-indicator {
  stroke: #000;
}
.dd-group-heading:hover {
  color: #188538;
}
.dd-group-heading:hover .dd-indicator {
  stroke: #188538;
}

.dd-heading {
  flex-grow: 2;
}

.dd-indicator-container {
  position: relative;
  top: -2px;
  width: 44px;
  padding: 0 16px 0 16px;
}

.dd-indicator {
  width: 12px;
  height: 6px;
  fill: none;
  transform: rotateY(0);
  transition: transform 0.35s ease-out;
}
.dd-indicator.dd-indicator-open {
  transform: rotateX(-180deg);
}
@import "../../styles/exports";

* { outline: none !important; }

body{
    font-family: $font_Primary;
    font-style: normal;
    font-size: $font_Size;
    line-height: $line_Height;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    color: #fff;
}

/* Links */
a {
	text-decoration: none;
	color: $color_GreenAction;

    &:hover{
        color: #fff;
    }
}


/*Заголовки*/
h1{
    font-size: 38px;
	line-height: 150%;
	font-weight: 100;

    @media (max-width: $screen-sm-max) {
        font-size: 19px;
    }
}
h2{
    font-size: 34px;

    @media (max-width: $screen-sm-max) {
        font-size: 17px;
    }
}
h3{
    font-size: 24px;

    @media (max-width: $screen-sm-max) {
        font-size: 12px;
    }
}
h4, h5, h6 {
    font-size: $font_Size;

    @media (max-width: $screen-sm-max) {
        font-size: $font_Size_Sm;
    }
}


/*Списки*/
ul, ol{

    li {

    }

    nav & {
        margin: 0;
        padding: 0;
        list-style: none;

        li { padding-left: 0; }
    }
}


/*Таблицы*/
table{
	width: 100%;
	margin: 20px 0;

    @media (max-width: $screen-sm-max) {
        margin: 10px 0;
    }

	caption {
		text-align: left;
		color: #888888;
		padding: 0 0 5px 0;
	}

	th, td {
		padding: 10px;
		border: 1px solid #d9d9d9;

        @media (max-width: $screen-sm-max) {
            padding: 5px;
        }
	}

    td {

    }

    th {
		text-align: left;
    }
}



/*Элементы*/
p { margin: 0; }
p + p { /*TODO: */ }

blockquote{
    text-align: center;
    display: block;
    margin: 20px 0;

    @media (max-width: $screen-sm-max) {
        margin: 10px 0;
    }
}

hr {
    display: block;
    height: 1px;
    border: none;
    margin: 10px 0 20px;
    padding: 0;
    background: #d9d9d9;

    @media (max-width: $screen-sm-max) {
        margin: 5px 0 10px;
    }
}


/*Картинки*/
img {
    vertical-align: middle;
}


h1, h2, h3, h4, h5, h6, ul, ol, table, p, blockquote {
    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }
}

h1, h2, h3, h4, h5, h6 {
    & + p,
    & + ul { /*TODO: */ }
}

p {
    & + ul,
    & + ol {
        margin-top: 10px;

        @media (max-width: $screen-sm-max) {
            margin-top: 5px;
        }
    }
}

svg:not(:root) {
	overflow: hidden;
}

svg {
	fill: currentColor;
}


.overlay-h1 {
    font-weight: 700;
    font-size: 27px;
    line-height: 36px;
    letter-spacing: .3px;
    margin: 0 0 25px;

    a {
        font-size: 18px; display: block; line-height: 110%; font-weight: normal;
        &:hover {cursor: pointer;}
    }

    @media (max-width: $screen-sm-max) {
        font-size: 16px;
        margin: 0 0 10px;
    }
}

/* Colors */
/* Fonts */
/* Dimensions */
@-webkit-keyframes preview-end {
  from {
    -webkit-transform: scale(1.1);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes preview-end {
  from {
    -moz-transform: scale(1.1);
  }
  to {
    -moz-transform: scale(1);
  }
}
@keyframes preview-end {
  from {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  to {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.area-player {
  width: 42px;
  height: 42px;
  margin: -21px 0 0 -21px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  -webkit-transition-property: left, top, -webkit-transform;
  -moz-transition-property: left, top, -moz-transform;
  transition-property: left, top, transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
@media (max-width: 959px) {
  .area-player {
    width: 18px;
    height: 18px;
    margin: -9px 0 0 -9px;
  }
}
.area-player.active {
  z-index: 2;
}
.area-player:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation: preview-end 0.2s linear;
  -moz-animation: preview-end 0.2s linear;
  animation: preview-end 0.2s linear;
}
.area-player .player__number {
  position: relative;
  color: #fff;
  text-align: center;
  display: block;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
  will-change: background-color;
}
@media (max-width: 959px) {
  .area-player .player__number {
    font-size: 12px;
    line-height: 18px;
    width: 18px;
    height: 18px;
  }
}
.area-player .player__number-text {
  font-size: 21px;
  line-height: 21px;
  font-weight: 700;
  margin-top: 3px;
}
@media (max-width: 959px) {
  .area-player .player__number-text {
    margin-top: 2px;
    font-size: 9px;
    line-height: 9px;
  }
}
.area-player .player__birthdate {
  margin-top: -4px;
  line-height: 21px;
  font-size: 10px;
  color: #CCC;
}
@media (max-width: 959px) {
  .area-player .player__birthdate {
    margin-top: -2px;
    font-size: 7px;
    line-height: 9px;
  }
}
.area-player.substituted .player__number:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: 0;
  width: 0;
  border-bottom: 10px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
@media (max-width: 959px) {
  .area-player.substituted .player__number:before {
    height: 0;
    width: 0;
    border-bottom: 6px solid #fff;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
}
.area-player.substituted .player__number:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  border-bottom: 8px solid #188538;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}
@media (max-width: 959px) {
  .area-player.substituted .player__number:after {
    height: 0;
    width: 0;
    border-bottom: 4px solid #188538;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
  }
}
.area-player.substituted.active .player__number:before,
.area-player.substituted.active .player__number:after {
  display: none;
}
.area-player.home:before {
  background: #202020;
}
.area-player.away:before {
  background: #fff;
}
.area-player.away .player__number {
  color: #000;
}
.area-player.away .player__birthdate {
  color: #000;
}
.area-player .player__name {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  margin: 0 -20px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-top: 1px;
}
@media (max-width: 959px) {
  .area-player .player__name {
    margin: 0 -10px;
    font-size: 10px;
    line-height: 10px;
    margin-top: 0;
    pointer-events: none;
    width: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.area-player .swap {
  position: absolute;
  top: -14px;
  right: -14px;
  width: 18px;
  height: 18px;
}
@media (max-width: 959px) {
  .area-player .swap {
    top: -7px;
    right: -7px;
    width: 9px;
    height: 9px;
  }
}
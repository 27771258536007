/* Colors */
/* Fonts */
/* Dimensions */
.notes-desktop-container .notes-aside {
  width: 30%;
  max-width: 321px;
  min-width: 260px;
  flex-grow: 1;
  flex-shrink: 1;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 15px;
}
@media (max-width: 959px) {
  .notes-desktop-container .notes-aside {
    min-width: 160px;
    padding-top: 0;
    border-left: none;
  }
}
.notes-desktop-container .notes-aside__wrapper {
  overflow: hidden;
  padding-bottom: 35px;
}
.notes-desktop-container .notes-aside .notes-add {
  display: flex;
  padding: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
@media (max-width: 959px) {
  .notes-desktop-container .notes-aside .notes-add {
    padding: 10px;
  }
}
.notes-desktop-container .notes-aside .notes-add .search-field-container {
  position: relative;
  flex-grow: 1;
  color: #CCC;
}
.notes-desktop-container .notes-aside .notes-add .search-field-container svg {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 6px;
  top: 6px;
  transition: color 250ms ease-out;
  fill: currentColor;
}
@media (max-width: 959px) {
  .notes-desktop-container .notes-aside .notes-add .search-field-container svg {
    width: 14px;
    height: 14px;
  }
}
.notes-desktop-container .notes-aside .notes-add .search-field {
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: #FFF;
  font-size: 14px;
  line-height: 36px;
  height: 36px;
  border-radius: 4px;
  padding: 0 15px 0 36px;
  transition: border-color 250ms ease-out;
  width: 100%;
  display: block;
}
@media (max-width: 959px) {
  .notes-desktop-container .notes-aside .notes-add .search-field {
    line-height: 24px;
    height: 24px;
    padding: 0 10px 0 24px;
  }
}
.notes-desktop-container .notes-aside .notes-add .search-field::placeholder {
  color: #CCC;
  transform: translateX(0) translateZ(0);
  transition: color 250ms ease-out, transform 0.2s ease-out;
}
.notes-desktop-container .notes-aside .notes-add .search-field:focus {
  border: 1px solid green;
}
.notes-desktop-container .notes-aside .notes-add .search-field:focus::placeholder {
  transform: translateX(50px) translateZ(0);
  color: transparent;
}
.notes-desktop-container .notes-aside .notes-add .note-add-button {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid;
  text-align: center;
  font-size: 14px;
  line-height: 36px;
  color: #2DC46E;
  background: transparent;
  transition: color 250ms ease-out;
  margin-left: 12px;
  position: relative;
  padding: 0;
}
@media (max-width: 959px) {
  .notes-desktop-container .notes-aside .notes-add .note-add-button {
    width: 24px;
    height: 24px;
  }
}
.notes-desktop-container .notes-aside .notes-add .note-add-button:before, .notes-desktop-container .notes-aside .notes-add .note-add-button:after {
  content: "";
  left: 50%;
  top: 50%;
  position: absolute;
  background: #2DC46E;
  transition: background 250ms ease-out;
}
.notes-desktop-container .notes-aside .notes-add .note-add-button:before {
  width: 12px;
  height: 2px;
  margin-top: -1px;
  margin-left: -6px;
}
.notes-desktop-container .notes-aside .notes-add .note-add-button:after {
  width: 2px;
  height: 12px;
  margin-left: -1px;
  margin-top: -6px;
}
.notes-desktop-container .notes-aside .notes-add .note-add-button:hover {
  color: green;
}
.notes-desktop-container .notes-aside .notes-add .note-add-button:hover:before, .notes-desktop-container .notes-aside .notes-add .note-add-button:hover:after {
  background: green;
}
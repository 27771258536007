/* Colors */
/* Fonts */
/* Dimensions */
.filter-date {
  color: #2DC46E;
  padding: 7px 0;
  border-bottom: 1px solid #354C5C;
  cursor: pointer;
  position: relative;
  width: 210px;
  display: flex;
  z-index: 10;
}
.filter-date label {
  display: inline-flex;
  width: 50%;
  border-bottom: none !important;
}
.filter-date label > svg {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  position: relative;
  top: 1px;
}
.filter-date .react-datepicker__input-container input {
  padding: 0;
  border-bottom: none;
  color: #2DC46E;
  text-align: center;
}
.filter-date .react-datepicker__input-container input:placeholder {
  color: rgba(255, 255, 255, 0.4);
  font-size: 12px;
}
.filter-date .react-datepicker {
  width: 240px;
}
.filter-date .react-datepicker .react-datepicker__day, .filter-date .react-datepicker .react-datepicker__day--in-range {
  border-radius: 0;
}
.filter-date .react-datepicker .react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  background-color: #777;
}
.filter-date__quick-buttons {
  display: flex;
  background-color: #f6f6f6;
  border-top: 1px solid #d9d9d9;
  flex-wrap: nowrap;
  width: 100%;
}
.filter-date__quick-button {
  display: block;
  border-left: 1px solid #d9d9d9;
  padding: 10px;
  text-align: center;
  flex-grow: 1;
}
.filter-date__quick-button:hover {
  color: #2DC46E;
}
.filter-date__quick-button:nth-child(1) {
  border-left: none;
}
.filter-date.white .react-datepicker__input-container input {
  color: #FFF;
}
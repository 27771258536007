/* Colors */
/* Fonts */
/* Dimensions */
@-webkit-keyframes preview-start {
  from {
    -webkit-transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.1);
  }
}
@-moz-keyframes preview-start {
  from {
    -moz-transform: scale(1);
  }
  to {
    -moz-transform: scale(1.1);
  }
}
@keyframes preview-start {
  from {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.preview {
  width: 42px;
  height: 42px;
  margin: -21px 0 0 -21px;
  position: fixed;
  left: 0;
  top: 0;
  border-radius: 50%;
  cursor: pointer;
  cursor: grabbing;
  pointer-events: none;
  z-index: 2;
}
@media (max-width: 959px) {
  .preview {
    width: 22px;
    height: 22px;
    margin: -11px 0 0 -11px;
  }
}
.preview:before {
  content: "";
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-animation: preview-start 0.2s linear;
  -moz-animation: preview-start 0.2s linear;
  animation: preview-start 0.2s linear;
}
.preview .circle {
  position: relative;
  color: #fff;
  font-weight: 700;
  text-align: center;
  font-size: 21px;
  line-height: 42px;
  display: block;
  width: 42px;
  height: 42px;
}
@media (max-width: 959px) {
  .preview .circle {
    font-size: 11px;
    line-height: 22px;
    width: 22px;
    height: 22px;
  }
}
.preview.home:before {
  background: #202020;
}
.preview.away:before {
  background: #fff;
}
.preview.away .circle {
  color: #000;
}
/* Colors */
/* Fonts */
/* Dimensions */
.customer-view__type {
  display: block;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.65);
}
.customer-view__email {
  display: block;
  color: rgba(0, 0, 0, 0.65);
}
.customer-view__name {
  font-weight: bold;
}
.customer-view__customer {
  color: rgba(255, 255, 255, 0.65);
}
.customer-view.option .customer-view__customer {
  color: rgba(0, 0, 0, 0.65);
}
/* Colors */
/* Fonts */
/* Dimensions */
.favorite-stats__nav {
  display: flex;
  background: #293B47;
  padding: 0 24px;
}
.favorite-stats__nav-tab {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  cursor: pointer;
  padding: 16px 20px;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 2px solid #3A4C5A;
}
.favorite-stats__nav-tab.active {
  background: #293B47;
  border-radius: 5px 5px 0 0;
  border-bottom: 2px solid #FFF;
}
.favorite-stats__body {
  background: #293B47;
  padding: 24px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 16px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
}
.favorite-stats__body.first-active {
  border-radius: 0 5px 5px 5px;
}
.favorite-stats__body.last-active {
  border-radius: 5px 0 5px 5px;
}
.favorite-stats__body .empty-message {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.4);
}
.favorite-stats__chart-section {
  margin-top: 25px;
  table-layout: fixed;
}
.favorite-stats__chart-section:nth-child(1) {
  margin-top: 0;
}
.favorite-stats__chart-section th, .favorite-stats__chart-section td {
  padding: 0;
}
.favorite-stats__chart-section th, .favorite-stats__chart-section td {
  border: none;
}
.favorite-stats__chart-section th {
  border-bottom: 1px solid #263540;
  padding-bottom: 5px;
}
.favorite-stats__chart-section td {
  padding: 5px 0;
  position: relative;
}
.favorite-stats__chart-section .label {
  font-size: 14px;
  text-align: center;
}
.favorite-stats__chart-section .group-name {
  font-size: 16px;
  line-height: 22px;
  padding-left: 0;
  width: 130px;
}
.favorite-stats__chart-section .indicator-name {
  font-size: 14px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.7);
  width: 130px;
  /*white-space:nowrap;*/
}
.favorite-stats__chart-section .indicator-name svg {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  position: relative;
  top: 2px;
}
.favorite-stats__chart-section .line {
  width: 1px;
  background: #22313b;
  display: block;
  position: absolute;
  left: 10%;
  top: 0;
  bottom: 0;
}
.favorite-stats__chart-section .line:nth-child(2) {
  left: 30%;
}
.favorite-stats__chart-section .line:nth-child(3) {
  left: 50%;
}
.favorite-stats__chart-section .line:nth-child(4) {
  left: 70%;
}
.favorite-stats__chart-section .line:nth-child(5) {
  left: 90%;
}
.favorite-stats__chart-section .val-indicator {
  width: 15px;
  height: 15px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -7px;
  left: 30%;
  background: #6CB5D7;
  margin-left: -7px;
  border-radius: 50%;
}
/* Colors */
/* Fonts */
/* Dimensions */
.pdf-single-rates {
  padding: 0 25px;
}
.pdf-single-rates .rated-player {
  padding-left: 60px;
}
.pdf-single-rates .rates-group {
  margin-right: -35px;
}
.pdf-single-rates .rates-table {
  padding: 5px 5px 5px 5px;
  border-left: none;
}
.pdf-single-rates .rates-table .rate-indicator-icon {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
}
.pdf-single-rates .rates-table table {
  width: 270px;
}
@media screen and (max-width: 959px) {
  .pdf-single-rates .rates-table table {
    width: 220px;
  }
}
.pdf-single-rates .rates-table table thead tr td {
  border-bottom: 2px solid #24323D;
}
.pdf-single-rates .rates-table table thead .rates-table-title {
  font-size: 16px;
  line-height: 20px;
  color: #181C20;
}
.pdf-single-rates .rates-table table tbody tr td {
  border: none;
  border-right: 1px solid #24323D;
  font-size: 12px;
  line-height: 32px;
}
.pdf-single-rates .final-rating {
  padding: 0;
  border-left: none;
  margin-top: 36px;
}
.pdf-single-rates .final-rating .final-rating-list {
  border: 1px solid #000;
  display: flex;
  width: 215px;
}
@media screen and (max-width: 959px) {
  .pdf-single-rates .final-rating .final-rating-list {
    width: 150px;
    margin-left: 30px;
  }
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item {
  margin-top: 0;
  display: inline-block;
  width: 43px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  color: #35B374;
  border-left: 1px solid #000;
  text-align: center;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item:nth-child(1) {
  border-left: none;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item .name {
  display: block;
  width: 100%;
  height: 40px;
  line-height: 40px;
  top: auto;
  left: auto;
  right: auto;
  text-align: center;
  margin-top: 0;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item .name:before {
  display: none;
}
@media screen and (max-width: 959px) {
  .pdf-single-rates .final-rating .final-rating-list .final-rating-item {
    width: 30px;
    height: 24px;
    line-height: 24px;
    font-size: 13px;
  }
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item:hover {
  color: #FFF;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item:hover .name {
  color: #FFF;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item.active {
  color: #FFF;
  font-weight: bold;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item.active .name {
  color: #FFF;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item.final-rating-item1:hover {
  background: #30619A;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item.final-rating-item1.active {
  background: #30619A;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item.final-rating-item2:hover {
  background: #7CB4D3;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item.final-rating-item2.active {
  background: #7CB4D3;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item.final-rating-item3:hover {
  background: #A4A6A4;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item.final-rating-item3.active {
  background: #A4A6A4;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item.final-rating-item4:hover {
  background: #EB8C49;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item.final-rating-item4.active {
  background: #EB8C49;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item.final-rating-item5:hover {
  background: #CA5425;
}
.pdf-single-rates .final-rating .final-rating-list .final-rating-item.final-rating-item5.active {
  background: #CA5425;
}
/* Colors */
/* Fonts */
/* Dimensions */
.notes-desktop-container .notes-filter {
  width: 26%;
  min-width: 150px;
  max-width: 292px;
  flex-grow: 1;
  flex-shrink: 1;
}
@media (max-width: 959px) {
  .notes-desktop-container .notes-filter {
    display: none;
  }
}
.notes-desktop-container .notes-filter__section {
  margin: 20px 15px 30px;
}
.notes-desktop-container .notes-filter__section-header {
  font-size: 16px;
  font-weight: 500;
  padding-left: 12px;
  color: #FFF;
  margin: 35px 15px 0px;
}
.notes-desktop-container .notes-filter__section-title {
  font-size: 13px;
  font-weight: 500;
  padding-left: 12px;
  margin-bottom: 4px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #CCC;
  opacity: 0.6;
}
.notes-desktop-container .notes-filter__search {
  font-size: 12px;
  line-height: 18px;
  /*border-bottom: 1px solid $color_light_border;*/
  padding: 3px 12px;
  width: 100%;
  display: block;
  margin: 5px 0;
  color: #CCC;
}
.notes-desktop-container .notes-filter__search::placeholder {
  color: #ccc;
}
.notes-desktop-container .notes-filter__item {
  display: flex;
  position: relative;
  margin-top: 1px;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: #2DC46E;
  padding: 3px 12px 3px 25px;
  cursor: pointer;
  border-radius: 2px;
  background: transparent;
  transition: background 250ms ease-out;
}
.notes-desktop-container .notes-filter__item.player {
  padding-left: 12px;
}
.notes-desktop-container .notes-filter__item.all-items {
  padding-left: 12px;
}
.notes-desktop-container .notes-filter__item.category:before {
  content: "#";
  position: absolute;
  left: 12px;
}
.notes-desktop-container .notes-filter__item.author {
  padding-left: 12px;
}
.notes-desktop-container .notes-filter__item.match {
  padding-left: 12px;
}
.notes-desktop-container .notes-filter__item:hover {
  background: rgba(255, 255, 255, 0.05);
}
.notes-desktop-container .notes-filter__item .text {
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.notes-desktop-container .notes-filter__item.active {
  background: rgba(255, 255, 255, 0.15);
}
.notes-desktop-container .notes-filter__item.active .text {
  font-weight: 500;
}
.notes-desktop-container .notes-filter__item .label {
  flex-grow: 0;
  opacity: 0.5;
}
/* Colors */
/* Fonts */
/* Dimensions */
.app-container {
  display: flex;
}
.app-container .app-content {
  width: calc(100% - 64px);
  background: #22323c;
}
@media (max-width: 959px) {
  .app-container .app-content {
    width: calc(100% - 40px);
  }
}

.privacy-confirm p {
  margin-top: 20px;
  line-height: 145%;
}
.privacy-confirm .h2 {
  font-weight: 700;
  font-size: 19px;
  margin: 0 0 1px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.95);
}
@media (max-width: 959px) {
  .privacy-confirm .h2 {
    font-size: 14px;
  }
}
.privacy-confirm .with-checkbox {
  position: relative;
  padding-left: 35px;
}

.checkbox {
  position: absolute;
  left: 0;
  top: 6px;
  width: 24px;
  height: 24px;
  border: 1px solid #FFF;
  cursor: pointer;
}
.checkbox svg {
  width: 20px;
  height: 20px;
  display: none;
}
.checkbox.checked svg {
  display: block;
}

.reports-limit-message {
  color: #FFF;
  background: #1E2A31;
  padding: 25px;
}

.restart-subscription {
  background: #47226E;
  border-radius: 6px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  margin: 60px 40px 0px 25px;
  padding: 20px 25px 25px;
  font-size: 18px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.75);
  text-align: left;
}
.restart-subscription.main-message {
  position: absolute;
  top: 0;
  left: 65px;
  right: 0;
  z-index: 999;
  margin: 0;
  box-sizing: border-box;
  padding-right: 64px;
}
.restart-subscription.main-message.minimized {
  position: relative;
  left: 0;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}
.restart-subscription.main-message.minimized a {
  color: #FFF;
  text-decoration: underline;
}
.restart-subscription.main-message .close {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  width: 24px;
  height: 24px;
  right: 24px;
  top: 24px;
  display: block;
}
.restart-subscription.main-message .close svg {
  width: 24px;
  height: 24px;
}
@media (max-width: 959px) {
  .restart-subscription.main-message {
    left: 40px;
  }
}
.restart-subscription.watchlist {
  margin-top: 45px;
  font-size: 18px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.75);
  text-align: left;
  opacity: 0.8;
  font-weight: 400;
  letter-spacing: 0.2px;
  margin-left: 0;
}
.restart-subscription h2 {
  font-weight: 700;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: 0.1px;
}
.restart-subscription h5 {
  opacity: 0.7;
  font-size: 15px;
}
.restart-subscription h5 + h5 {
  margin-top: 25px;
}
.restart-subscription .p__btn {
  margin: 15px 0px 0px;
}
@media (max-width: 959px) {
  .restart-subscription {
    margin: 20px 30px 0px 5px;
  }
}
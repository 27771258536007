@import "../exports";

#DatePickerPortal{
    z-index: 10000000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
}
.react-datepicker__tether-element-attached-top .react-datepicker__triangle, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__tether-element-attached-top .react-datepicker__triangle::before, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
}
.react-datepicker__tether-element-attached-top .react-datepicker__triangle::before, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: #f6f6f6;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
}
.react-datepicker__tether-element-attached-top .react-datepicker__triangle, .react-datepicker__tether-element-attached-top .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #f6f6f6;
}
.react-datepicker__tether-element-attached-top .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #d9d9d9;
}

.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
}
.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff;
}
.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #d9d9d9;
}

.react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #d9d9d9;
    display: inline-block;
    position: relative;
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker__tether-element-attached-bottom.react-datepicker__tether-element {
    margin-top: -20px;
}

.react-datepicker__header {
    text-align: center;
    background-color: #f6f6f6;
    border-bottom: 1px solid #d9d9d9;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
}

.react-datepicker__header__dropdown--select {
    margin-top: -16px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select {
    display: inline-block;
    margin: 0 2px;
}

.react-datepicker__current-month {
    margin-top: 0;
    color: #000;
    font-weight: 400;
    font-family: 'Signika Negative', sans-serif;
    font-size: 0.944rem;
}
.react-datepicker__current-month--hasYearDropdown {
    margin-bottom: 16px;
}

.react-datepicker__navigation {
    width: 42px;
    height: 42px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    z-index: 1;
    overflow: hidden;
    background: transparent;
    border: none;
    font-size: 0;
    line-height: 0;
    text-indent: -9999px;

    &:before {
        content: '';
        position: absolute;
        top: 15px;
        border-style: solid;
        border-color: transparent;
        border-width: 5px;
    }
}
.react-datepicker__navigation--previous {
    left: 0;

    &:before {
        border-right-color: #999;
        left: 15px;
    }
}
.react-datepicker__navigation--previous:hover:before {
    border-right-color: #666;
}
.react-datepicker__navigation--next {
    right: 0;

    &:before {
        border-left-color: #999;
        right: 15px;
    }
}
.react-datepicker__navigation--next:hover:before {
    border-left-color: #666;
}
.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #d9d9d9;
}
.react-datepicker__navigation--years-previous:hover {
    border-top-color: #d9d9d9;
}
.react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #d9d9d9;
}
.react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: #d9d9d9;
}

.react-datepicker__month-container {
    display: inline;
    float: left;
}

.react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
}

.react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__day-name,
.react-datepicker__day {
    color: #000;
    display: inline-block;
    width: 2rem;
    line-height: 2rem;
    text-align: center;

    &.react-datepicker__day--outside-month {
        color: #999;
    }
}

.react-datepicker__day {
    cursor: pointer;
}
.react-datepicker__day:hover {
    background-color: #f0f0f0;
}
.react-datepicker__day--today {
    font-weight: 400;
}
.react-datepicker__day--highlighted {
    background-color: #3dcc4a;
    color: #fff;
}
.react-datepicker__day--highlighted:hover {
    background-color: #148535;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    background-color: #148535;
    color: #fff !important;
    border-radius: 2px;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
    background-color: #148535;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
    background-color: #f0f0f0;
    color: #000;
}
.react-datepicker__day--disabled {
    cursor: default;
    color: #ccc;
}
.react-datepicker__day--disabled:hover {
    background-color: transparent;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;

    input{
        &:disabled{
            color: $color_Disabled;
            border-bottom-color: $color_Disabled;

            &::placeholder{
                color: $color_Disabled;
            }
        }
    }
}

.react-datepicker__year-read-view {
    width: 50%;
    left: 25%;
    position: absolute;
    bottom: 25px;
    border: 1px solid transparent;
}
.react-datepicker__year-read-view:hover {
    cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow {
    border-top-color: #d9d9d9;
}
.react-datepicker__year-read-view--down-arrow {
    border-top-color: #ccc;
    margin-bottom: 3px;
    left: 5px;
    top: 9px;
    position: relative;
    border-width: 0.45rem;
}
.react-datepicker__year-read-view--selected-year {
    right: 0.45rem;
    position: relative;
}

.react-datepicker__year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    text-align: center;
    border: 1px solid #d9d9d9;
}
.react-datepicker__year-dropdown:hover {
    cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
}

.react-datepicker__year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.react-datepicker__year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover {
    background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #d9d9d9;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous {
    border-top-color: #d9d9d9;
}
.react-datepicker__year-option--selected {
    position: absolute;
    left: 30px;
}

.react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 0;
    outline: 0;
    padding: 0;
    vertical-align: middle;
}
.react-datepicker__close-icon::after {
    background-color: #216ba5;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: "\00d7";
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 50%;
}

.react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #d9d9d9;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
    padding: 5px 0;
}

.react-datepicker__tether-element {
    z-index: 2147483647;
}


@media(max-width: $screen-xs-max){
    .popper{
        position:fixed;
        bottom:0;
        left:0;
        right:0;
        transform: none !important;
    }
}

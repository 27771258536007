/* Colors */
/* Fonts */
/* Dimensions */
.player-note {
  position: absolute;
  right: 0px;
  left: 0px;
  margin: 10px 0;
  height: 60%;
}
.player-note__single {
  height: calc(100% - 68px);
  padding: 14px 0 14px;
}
.player-note h3 {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  margin: 0 0 40px;
  padding: 0 10px;
}
.player-note .events {
  padding: 0 10px 10px;
}
.player-note .events label {
  display: block;
  opacity: 0.8;
  font-size: 15px;
}
.player-note .events button {
  background: #293741;
  border: none;
  margin: 5px;
  display: inline-block;
  font-size: 16px;
  line-height: 32px;
  padding: 0 12px;
  position: relative;
  color: #FFF;
  border-radius: 16px;
  transition: background 0.35s ease-out;
}
.player-note .events button.active {
  background: #35B374;
}
.player-note .events button svg {
  width: 14px;
  height: 14px;
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}
.player-note .events button svg.goal {
  top: 0;
}
.player-note .events button svg.assists {
  top: -2px;
}
.player-note .events button:hover {
  background: #3A4C5A;
}
@media (max-width: 959px) {
  .player-note {
    right: 8px;
    left: 8px;
    margin: 8px 0;
  }
}
.player-note textarea {
  position: relative;
  height: calc(100% - 230px);
  width: 100%;
  resize: none;
  background: #2c404f;
  border: none;
  padding: 13px 16px;
  color: #fff;
  font-size: 15px;
  line-height: 20px;
  background: #2c404f;
  border-radius: 4px;
}
@media (max-width: 959px) {
  .player-note textarea {
    padding: 4px;
    font-size: 13px;
    line-height: 15px;
  }
}
.player-note textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.player-note .events-tags {
  margin: 10px 10px 0;
  color: #2DC46E;
}
.player-note .events-tags .tag {
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
}
.player-note .events-tags .tag:nth-child(1) {
  margin-left: 0;
}
/* Colors */
/* Fonts */
/* Dimensions */
.homepage {
  display: flex;
}
.homepage__search {
  display: flex;
  position: relative;
  z-index: 5;
  width: 100%;
}
.homepage__search .home-search {
  flex-grow: 2;
}
.homepage__interactive-card {
  background-color: #24323D;
  border: 1px solid #24323D;
  border-radius: 6px;
  cursor: pointer;
  background-image: none;
}
.homepage__interactive-card:hover {
  border: 1px solid #3A4C5A;
}
@media (max-width: 1199px) {
  .homepage {
    display: block;
  }
}
.homepage__sidebar {
  width: 215px;
  padding: 72px 24px 24px;
}
@media (max-width: 1199px) {
  .homepage__sidebar {
    width: auto;
    padding: 36px 24px 0;
    margin-bottom: 24px;
  }
}
.homepage__greeting {
  font-size: 28px;
  line-height: 35px;
  font-weight: 300;
}
.homepage__greeting strong {
  font-weight: 700;
}
@media (max-width: 959px) {
  .homepage__greeting {
    font-size: 20px;
    line-height: 26px;
  }
  .homepage__greeting br {
    display: none;
  }
}
.homepage__content {
  flex-grow: 1;
  width: calc(100% - 215px);
  padding: 72px 24px 24px;
  max-width: 1300px;
}
@media (max-width: 1199px) {
  .homepage__content {
    padding: 0 24px 24px;
    width: 100%;
  }
}
.homepage__row {
  display: flex;
  margin-top: 24px;
}
.homepage__row:nth-child(1) {
  margin-top: 0;
}
@media (max-width: 959px) {
  .homepage__row {
    display: block;
  }
}
.homepage__block {
  background: #2D3B46;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.24);
  border-radius: 6px;
  flex-grow: 1;
  padding: 24px;
  margin-left: 24px;
}
@media (max-width: 959px) {
  .homepage__block {
    margin-left: 0;
    margin-top: 24px;
  }
}
.homepage__block.watchlists-block {
  max-width: 720px;
  width: 100%;
}
@media (max-width: 1379px) {
  .homepage__block.watchlists-block {
    max-width: 570px;
  }
}
@media (max-width: 1199px) {
  .homepage__block.watchlists-block {
    max-width: 390px;
  }
}
@media (max-width: 959px) {
  .homepage__block.watchlists-block {
    max-width: 100%;
  }
}
.homepage__block.players-block {
  flex-shrink: 2;
}
.homepage__block.matches-block {
  width: 100%;
  padding-right: 0;
}
.homepage__block:nth-child(1) {
  margin-left: 0;
}
.homepage__block-header {
  vertical-align: middle;
  white-space: nowrap;
  margin-bottom: 24px;
}
.homepage__block-header svg {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin: 0 6px;
  vertical-align: middle;
  fill: none;
  position: relative;
  top: -2px;
}
.homepage__block-header svg:nth-child(1) {
  margin-left: 0;
}
.homepage__block-header a {
  color: #FFF;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 16px;
  font-size: 13px;
}
.homepage__block-header a svg {
  fill: none;
  stroke: currentColor;
}
.homepage__block-header a svg.players-icon {
  width: 16px;
  height: 17px;
}
.homepage__block-header a svg.pitch-icon {
  width: 16px;
  height: 16px;
}
.homepage__block-header a svg.watchlists-icon {
  width: 18px;
  height: 16px;
}
.homepage__block-header a svg.pitch-icon, .homepage__block-header a svg.watchlists-icon {
  fill: currentColor;
  stroke: none;
}
.homepage__block-header a:hover {
  color: #35B374;
}
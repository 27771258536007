/* Colors */
/* Fonts */
/* Dimensions */
.match-view {
  color: #FFF;
}
.match-view__date {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  line-height: 18px;
}
.match-view__row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.match-view__match .match-view__name {
  width: calc(50% - 28px);
}
.match-view__match .match-view__name.home {
  text-align: right;
}
.match-view__match .match-view__name.away {
  text-align: left;
}
.match-view__player-img {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 8px;
}
.match-view__team-img {
  display: inline-block;
  width: 20px;
  height: 24px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.match-view__name {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
}
.match-view__teams {
  margin: 0 8px;
}
.match-view__teams .match-view__team-img + .match-view__team-img {
  margin-left: -8px;
}
.match-view__single .match-view__team-img {
  width: 14px;
  height: 18px;
}
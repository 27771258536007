/* Colors */
/* Fonts */
/* Dimensions */
.notes-desktop-container .notes-content {
  flex-grow: 1;
  flex-shrink: 1;
  width: 70%;
  padding: 29px 0px 35px 30px;
  position: relative;
}
.notes-desktop-container .notes-content__wrapper {
  padding-bottom: 35px;
  padding-right: 30px;
}
.notes-desktop-container .notes-content .note-content {
  -ms-user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
  padding-bottom: 35px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 35px;
}
.notes-desktop-container .notes-content .note-content__wrapper {
  overflow: hidden;
}
.notes-desktop-container .notes-content .note-content.player-profile {
  border-bottom: none;
}
.notes-desktop-container .notes-content .note-content__title {
  display: flex;
}
.notes-desktop-container .notes-content .note-content__title h2 {
  flex-grow: 1;
}
.notes-desktop-container .notes-content .note-content__button {
  width: 18px;
  height: 18px;
  display: block;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  transition: color 250ms ease-out;
  margin-left: 20px;
  top: 5px;
  cursor: pointer;
}
.notes-desktop-container .notes-content .note-content__button:hover {
  color: green;
}
.notes-desktop-container .notes-content .note-content__button.edit-note svg {
  fill: none;
  stroke: currentColor;
}
.notes-desktop-container .notes-content .note-content__button svg {
  width: 100%;
  height: 100%;
}
.notes-desktop-container .notes-content .note-content h2 {
  color: #FFF;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  margin: 0;
}
.notes-desktop-container .notes-content .note-content p {
  font-size: 15px;
  line-height: 22px;
  color: #FFF;
  margin-top: 15px;
}
.notes-desktop-container .notes-content .note-content__meta {
  font-size: 12px;
  line-height: 18px;
  margin: 15px 0 0px;
  color: #CCC;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.notes-desktop-container .notes-content .note-content__tags {
  margin-top: 15px;
}
.notes-desktop-container .notes-content .note-content__player {
  margin: 35px 0;
}
.notes-desktop-container .notes-content .note-content__player h4 {
  font-size: 14px;
  line-height: 20px;
  color: #CCC;
  margin: 0 0 10px;
  font-weight: normal;
}
.notes-desktop-container .notes-content .note-content__player .player-card {
  padding: 15px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  color: #CCC;
}
.notes-desktop-container .notes-content .note-content__player .player-card:hover {
  background: blue;
}
.notes-desktop-container .notes-content .note-content__player .player-card .player-text .player-name {
  color: #2DC46E;
}
.notes-desktop-container .notes-content .note-content__more {
  margin: 35px 0;
}
.notes-desktop-container .notes-content .note-content__more h4 {
  font-size: 14px;
  line-height: 20px;
  color: #CCC;
  margin: 0 0 10px;
  font-weight: normal;
}
.notes-desktop-container .notes-content .note-content__more .notes-list__item {
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  padding: 15px;
  transition: background 250ms ease-out;
  margin: 0px 0px 10px 0;
}
.notes-desktop-container .notes-content .note-content__more .notes-list__item:hover {
  background: blue;
}
.notes-desktop-container .notes-content .note-content__more .notes-list__item h4 {
  font-size: 15px;
  line-height: 21px;
  font-weight: 500;
  color: blue;
}
.notes-desktop-container .notes-content .note-content__more .notes-list__item-player-pic {
  display: none;
}
.notes-desktop-container .notes-content .note-content__more .notes-list__item-text {
  color: #FFF;
}
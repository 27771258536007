/* Colors */
/* Fonts */
/* Dimensions */
.homepage__reports {
  display: flex;
  flex-wrap: wrap;
  height: 130px;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 959px) {
  .homepage__reports {
    height: auto;
  }
}
.homepage__reports .demo-message {
  margin: 15px 20px 0 70px;
}
@media (max-width: 959px) {
  .homepage__reports .demo-message {
    margin-left: 50px;
    width: 140px;
  }
}
.homepage__reports .firstmatch {
  display: inline-block;
  font-size: 22px;
  font-weight: 700;
  margin: 8px 0 0 16px;
  width: 240px;
  line-height: 130%;
  opacity: 0.8;
  vertical-align: top;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.homepage__reports-item {
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  list-style: none;
}
@media (max-width: 959px) {
  .homepage__reports-item {
    margin-bottom: 8px;
  }
}
.homepage__reports-item .match-card {
  background-color: #24323D;
  border-color: #24323D;
}
.homepage__reports-item .match-card:hover {
  border-color: #3A4C5A;
}
.homepage__reports-add {
  width: 280px;
  height: 130px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0.14em;
  font-weight: 700;
  flex-shrink: 0;
  flex-grow: 0;
  background: #24323D;
  border-radius: 6px;
  padding: 32px 16px 14px;
  color: #FFF;
  cursor: pointer;
  margin-bottom: 8px;
}
.homepage__reports-add svg {
  width: 27px;
  height: 27px;
  display: block;
  margin: 0 auto 18px;
}
@media (max-width: 959px) {
  .homepage__reports-add {
    width: 130px;
    height: 130px;
    padding-top: 24px;
  }
}
.homepage__reports-add:hover {
  opacity: 1;
  color: #35B374;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgwIiBoZWlnaHQ9IjEzMCIgdmlld0JveD0iMCAwIDI4MCAxMzAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMDguMjY3IC05NS4zNDM4TDMwMC4zIC0zLjMxMTIyTDcxLjg2NTMgMjI1LjEyM0wtMjAuMTY3MiAxMzMuMDkxTDIwOC4yNjcgLTk1LjM0MzhaIiBmaWxsPSIjMkQzQjQ2Ii8+Cjwvc3ZnPgo=");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.homepage__reports-add:hover .day, .homepage__reports-add:hover .time, .homepage__reports-add:hover .league {
  color: #FFF;
}
/* Colors */
/* Fonts */
/* Dimensions */
.shadow-team__player-controls {
  position: absolute;
  background: #2A333A;
  top: 0;
  width: 200px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.35);
}
.shadow-team__player-controls.left {
  right: 100%;
  left: auto;
  margin-right: 3px;
}
.shadow-team__player-controls.right {
  left: 100%;
  right: auto;
  margin-left: 3px;
}
.shadow-team__player-controls ul li {
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 400;
  border-top: 1px solid #294254;
  color: #FFF;
}
.shadow-team__player-controls ul li.profile {
  padding: 0;
}
.shadow-team__player-controls ul li a {
  padding: 8px 10px;
  display: block;
  color: #FFF;
}
.shadow-team__player-controls ul li a:hover {
  color: #2DC46E;
}
.shadow-team__player-controls ul li:nth-child(1) {
  border-top: none;
}
.shadow-team__player-controls ul li .list-icon.tri-up, .shadow-team__player-controls ul li .list-icon.tri-down {
  display: inline-block;
  margin-right: 5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: relative;
  top: -1px;
}
.shadow-team__player-controls ul li .list-icon.tri-up {
  border-bottom: 6px solid;
}
.shadow-team__player-controls ul li .list-icon.tri-down {
  border-top: 6px solid;
}
.shadow-team__player-controls ul li .list-icon.remove {
  width: 11px;
  height: 11px;
  display: inline-block;
  fill: currentColor;
  margin-right: 4px;
  position: relative;
  top: 1px;
  left: auto;
}
.shadow-team__player-controls ul li:hover {
  color: #2DC46E;
}
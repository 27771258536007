.match-card__player {
  position: relative;
  padding-left: 48px;
  font-size: 19px;
  line-height: 20px;
  font-weight: 700;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.match-card__player-image {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 0;
  margin-top: -20px;
  border-radius: 20px;
  background-size: cover;
  background-color: #637581;
  background-repeat: no-repeat;
  background-position: center center;
}
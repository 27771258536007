/* Colors */
/* Fonts */
/* Dimensions */
.fade-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 999999;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.65);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.fade-wrapper.with-logo {
  flex-direction: column;
}
.fade-wrapper.with-logo .fade-body {
  width: 100%;
  height: calc(100vh - 121px);
  min-height: 601px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 959px) {
  .fade-wrapper.with-logo .fade-body {
    display: block;
    padding: 24px;
  }
}
/* Colors */
/* Fonts */
/* Dimensions */
.timeline {
  margin: 10px auto 0;
  width: 43px;
  position: relative;
  text-align: center;
}
@media (max-width: 959px) {
  .timeline {
    margin: 20px auto 0;
    width: 30px;
  }
}
.timeline .clock {
  position: absolute;
  top: 0;
  right: 100%;
  margin-right: 50px;
  white-space: nowrap;
  font-size: 30px;
  line-height: 43px;
  height: 43px;
}
@media (max-width: 959px) {
  .timeline .clock {
    margin-right: 20px;
    font-size: 20px;
    line-height: 22px;
    height: 22px;
  }
}
.timeline .clock > *:hover {
  color: white;
}
.timeline .clock .unit {
  display: inline-block;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 700;
  text-transform: uppercase;
  vertical-align: middle;
}
@media (max-width: 959px) {
  .timeline .clock .unit {
    font-size: 11px;
    margin: -2px 8px 0px;
  }
}
.timeline .clock input {
  display: inline-block;
  width: 46px;
  font-size: inherit;
  border: none;
  text-align: center;
  line-height: inherit;
  padding: 0;
  color: #2DC46E;
  vertical-align: top;
  appearance: textfield;
}
@media (max-width: 959px) {
  .timeline .clock input {
    width: 30px;
  }
}
.timeline .clock input::-webkit-inner-spin-button, .timeline .clock input::-webkit-outer-spin-button, .timeline .clock input:hover::-webkit-inner-spin-button, .timeline .clock input:hover::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.timeline .toggle-timer {
  position: relative;
  width: 43px;
  height: 43px;
  display: inline-block;
  border-radius: 50%;
  background: #2DC46E;
  cursor: pointer;
  color: #263742;
  text-transform: uppercase;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-align: left;
}
@media (max-width: 959px) {
  .timeline .toggle-timer {
    width: 28px;
    height: 28px;
    line-height: 10px;
  }
}
.timeline .toggle-timer svg {
  position: absolute;
  top: 50%;
  right: 50%;
  margin: -9px -11px 0 0;
  width: 18px;
  height: 18px;
}
@media (max-width: 959px) {
  .timeline .toggle-timer svg {
    width: 12px;
    height: 12px;
    margin: -5.5px -7px 0 0;
  }
}
.timeline .toggle-timer.pause {
  z-index: 2;
}
.timeline .toggle-timer.pause svg {
  margin-right: -9px;
}
@media (max-width: 959px) {
  .timeline .toggle-timer.pause svg {
    margin-right: -6px;
  }
}
.timeline .toggle-timer .timer-status {
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 50%;
  left: 100%;
  margin-left: 12px;
  margin-top: -16px;
  white-space: nowrap;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (max-width: 959px) {
  .timeline .toggle-timer .timer-status {
    font-size: 10px;
    line-height: 11px;
    margin-left: 10px;
    margin-top: -11px;
  }
}
.timeline .toggle-timer:hover {
  background-color: #fff;
}
.timeline .Select {
  font-size: 18px;
  line-height: 40px;
  font-weight: 700;
  width: 43px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
@media (max-width: 959px) {
  .timeline .Select {
    font-size: 10px;
    line-height: 24px;
    width: 30px;
  }
}
.timeline .Select__control {
  width: 43px;
  height: 43px;
  overflow: hidden;
  opacity: 0;
}
@media (max-width: 959px) {
  .timeline .Select__control {
    width: 30px;
    height: 30px;
  }
}
.timeline .Select__menu {
  background: #263742;
  font-weight: 700;
  text-align: left;
  color: #2DC46E;
  top: auto;
  bottom: 100%;
  left: 50%;
  width: 170px;
  margin-left: -66px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 8px;
}
@media (max-width: 959px) {
  .timeline .Select__menu {
    width: 104px;
    margin-left: -52px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 4px;
    font-weight: 400;
  }
}
.timeline .Select__menu .Select__option {
  border-top: 1px solid #4E5F6D;
  padding: 0 14px;
  cursor: pointer;
}
@media (max-width: 959px) {
  .timeline .Select__menu .Select__option {
    padding: 4px 8px;
    font-size: 14px;
  }
}
.timeline .Select__menu .Select__option:hover, .timeline .Select__menu .Select__option:focus, .timeline .Select__menu .Select__option:active, .timeline .Select__menu .Select__option.Select__option--is-selected, .timeline .Select__menu .Select__option.Select__option--is-focused {
  color: #fff;
  background: none;
}
.timeline .Select__menu .Select__option:first-child {
  border: none;
}
.timeline .Select:hover + .toggle-timer,
.timeline .Select.is-open + .toggle-timer {
  color: #fff;
}
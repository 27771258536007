/* Colors */
/* Fonts */
/* Dimensions */
.indicator-control {
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  padding: 4px 4px 6px;
  width: 80px;
  height: 80px;
  background: #3A4C5A;
  position: relative;
  margin: 0 0 1px 1px;
}
.indicator-control__icon {
  width: 24px;
  height: 24px;
  margin: 16px auto 0;
}
.indicator-control__icon svg {
  width: 24px;
  height: 24px;
}
@media (max-width: 959px) {
  .indicator-control {
    width: 60px;
    height: 60px;
  }
  .indicator-control__icon {
    width: 16px;
    height: 16px;
    margin: 7px auto 0;
  }
  .indicator-control__icon svg {
    width: 16px;
    height: 16px;
  }
}
.indicator-control__name {
  box-sizing: border-box;
  font-size: 12px;
  line-height: 13px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4px 4px 6px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.indicator-control__values {
  width: 240px;
  height: 40px;
  position: fixed;
  left: 0;
  background: #24323D;
  top: 0;
  border: 1px solid #3A4C5A;
  z-index: 5;
  color: #35B374;
  display: flex;
}
@media (max-width: 959px) {
  .indicator-control__values {
    width: 180px;
    height: 30px;
  }
}
.indicator-control__values span {
  width: 20%;
  display: block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-left: 1px solid #3A4C5A;
  cursor: pointer;
}
@media (max-width: 959px) {
  .indicator-control__values span {
    height: 30px;
    line-height: 28px;
  }
}
.indicator-control__values span svg {
  width: 20px;
  height: 40px;
}
@media (max-width: 959px) {
  .indicator-control__values span svg {
    height: 30px;
  }
}
.indicator-control__values span:hover {
  color: #FFF;
}
.indicator-control__values span:active {
  opacity: 0.85;
}
.indicator-control__values span.selected {
  background: #35B374;
  color: #FFF;
}
.indicator-control__value0:hover {
  background: #30619A;
}
.indicator-control__value1:hover {
  background: #7CB4D3;
}
.indicator-control__value2:hover {
  background: #A4A6A4;
}
.indicator-control__value3:hover {
  background: #EB8C49;
}
.indicator-control__value4:hover {
  background: #CA5425;
}
.indicator-control:hover, .indicator-control.active {
  color: #35B374;
}
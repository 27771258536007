.analytics-cta-chart {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 100%;
  background-image: url("./bg_analytics.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.analytics-cta-chart .analytics-cta {
  position: absolute;
  width: 190px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #24323D;
  padding: 16px;
}
.analytics-cta-chart .analytics-cta h4 {
  font-family: "Source Sans Pro", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  margin: 0;
}
.analytics-cta-chart .analytics-cta p {
  margin: 16px 0;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  color: #A4A6A4;
}
.analytics-cta-chart .analytics-cta svg {
  width: 80px;
  height: 80px;
  margin: 0 auto 16px;
  display: block;
}
.analytics-cta-chart .analytics-cta .btn {
  width: 100%;
  background: #35B37A;
  border: none;
  color: #FFF;
}
.analytics-cta-chart .analytics-cta .btn:hover, .analytics-cta-chart .analytics-cta .btn:active {
  background: #FFF;
  color: #24323D;
}
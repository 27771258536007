/* Colors */
/* Fonts */
/* Dimensions */
.match-edit-form-modal {
  max-width: 748px;
  width: 100%;
  min-width: 320px;
}

.match-edit-form label {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  color: #A4A6A4;
}
.match-edit-form .input:disabled {
  color: #637581;
  border-bottom-color: #637581;
}
.match-edit-form__row {
  margin-top: 24px;
}
.match-edit-form__buttons {
  padding-top: 20px;
  text-align: right;
}
.match-edit-form__buttons .btn {
  margin-left: 24px;
}
.match-edit-form__buttons .btn:nth-child(1) {
  margin-left: 0;
}
.match-edit-form .type-icon__single {
  stroke: currentColor;
  fill: none;
}
.match-edit-form .type-icon__match {
  stroke: none;
  fill: currentColor;
}
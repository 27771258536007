/* Colors */
/* Fonts */
/* Dimensions */
.radio-switcher {
  width: 100%;
  margin-right: 24px;
  background: #3A4C5A;
  height: 48px;
  border-radius: 6px;
  padding: 4px;
  display: flex;
}
.radio-switcher button {
  display: block;
  width: 50%;
  height: 40px;
  background: transparent;
  border-radius: 6px;
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
}
.radio-switcher button svg {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 4px;
  position: relative;
  top: -2px;
}
.radio-switcher button:disabled {
  color: #637581;
}
.radio-switcher button.active {
  background: #35B374;
}
.radio-switcher button.active:disabled {
  background: #637581;
  color: #FFF;
}
/* Colors */
/* Fonts */
/* Dimensions */
.note-player-select {
  max-width: 350px;
}
.note-player-select.Select--single > .Select-control, .note-player-select.is-focused.Select--single > .Select-control, .note-player-select.is-pseudo-focused.Select--single > .Select-control, .note-player-select.is-open.Select--single > .Select-control {
  height: 43px;
  border: none;
}
.note-player-select.Select--single > .Select-control .Select-placeholder, .note-player-select.is-focused.Select--single > .Select-control .Select-placeholder, .note-player-select.is-pseudo-focused.Select--single > .Select-control .Select-placeholder, .note-player-select.is-open.Select--single > .Select-control .Select-placeholder {
  height: 43px;
  font-size: 14px;
  line-height: 43px;
  font-weight: 500;
  color: #ccc;
}
.note-player-select.Select--single > .Select-control .Select-value, .note-player-select.is-focused.Select--single > .Select-control .Select-value, .note-player-select.is-pseudo-focused.Select--single > .Select-control .Select-value, .note-player-select.is-open.Select--single > .Select-control .Select-value {
  left: 0;
  line-height: 43px;
  height: 43px;
}
.note-player-select.Select--single > .Select-control .Select-value .Select-value-label, .note-player-select.is-focused.Select--single > .Select-control .Select-value .Select-value-label, .note-player-select.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label, .note-player-select.is-open.Select--single > .Select-control .Select-value .Select-value-label {
  display: block;
}
.note-player-select .Select-menu-outer .player-text {
  line-height: 20px;
  padding-top: 6px;
}
.note-player-select.filter-select {
  margin-top: 5px;
}
.note-player-select.filter-select .player-card {
  display: flex;
  font-size: 14px;
  line-height: 32px;
  position: relative;
  background: transparent;
  transition: background 250ms ease-out;
}
.note-player-select.filter-select .player-card .count {
  position: absolute;
  right: 0;
  top: 0;
}
.note-player-select.filter-select .player-card div, .note-player-select.filter-select .player-card span {
  display: block;
}
.note-player-select.filter-select .player-card .player-img {
  width: 16px;
  height: 26px;
  margin-right: 8px;
  margin-top: 5px;
}
.note-player-select.filter-select .player-card .player-img .player-photo .pic {
  max-width: 17px;
  height: 20px;
  position: relative;
}
.note-player-select.filter-select .player-card .player-img .player-photo .pic > div {
  clip-path: none;
}
.note-player-select.filter-select .player-card .player-text {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.note-player-select.filter-select .player-card .player-text .player-name {
  font-weight: 500;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: green;
}
.note-player-select.filter-select .player-card.empty {
  opacity: 0.5;
}
.note-player-select.filter-select.Select--single > .Select-control, .note-player-select.filter-select.is-focused.Select--single > .Select-control, .note-player-select.filter-select.is-pseudo-focused.Select--single > .Select-control, .note-player-select.filter-select.is-open.Select--single > .Select-control {
  height: 32px;
  border: none;
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.note-player-select.filter-select.Select--single > .Select-control .Select-placeholder, .note-player-select.filter-select.is-focused.Select--single > .Select-control .Select-placeholder, .note-player-select.filter-select.is-pseudo-focused.Select--single > .Select-control .Select-placeholder, .note-player-select.filter-select.is-open.Select--single > .Select-control .Select-placeholder {
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  font-weight: 500;
  color: #ccc;
}
.note-player-select.filter-select.Select--single > .Select-control .Select-input, .note-player-select.filter-select.is-focused.Select--single > .Select-control .Select-input, .note-player-select.filter-select.is-pseudo-focused.Select--single > .Select-control .Select-input, .note-player-select.filter-select.is-open.Select--single > .Select-control .Select-input {
  height: 32px;
}
.note-player-select.filter-select.Select--single > .Select-control .Select-input input, .note-player-select.filter-select.is-focused.Select--single > .Select-control .Select-input input, .note-player-select.filter-select.is-pseudo-focused.Select--single > .Select-control .Select-input input, .note-player-select.filter-select.is-open.Select--single > .Select-control .Select-input input {
  padding: 0;
  font-size: 14px;
  line-height: 32px;
}
.note-player-select.filter-select.Select--single > .Select-control .Select-value, .note-player-select.filter-select.is-focused.Select--single > .Select-control .Select-value, .note-player-select.filter-select.is-pseudo-focused.Select--single > .Select-control .Select-value, .note-player-select.filter-select.is-open.Select--single > .Select-control .Select-value {
  left: 0;
  line-height: 32px;
  height: 32px;
}
.note-player-select.filter-select.Select--single > .Select-control .Select-value .Select-value-label, .note-player-select.filter-select.is-focused.Select--single > .Select-control .Select-value .Select-value-label, .note-player-select.filter-select.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label, .note-player-select.filter-select.is-open.Select--single > .Select-control .Select-value .Select-value-label {
  display: block;
}
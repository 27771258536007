/* Colors */
/* Fonts */
/* Dimensions */
@media (max-width: 1023px) {
  .shortcuts .col-xs-12 {
    width: 100%;
    margin-bottom: 36px;
  }
}
@media (max-width: 1023px) {
  .shortcuts .xs-hidden {
    display: none;
  }
}
.shortcuts .positions-selector {
  margin: 0 0 25px;
}
.shortcuts .positions-selector .position {
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid #188538;
  border-left: none;
  font-weight: bold;
  cursor: pointer;
}
.shortcuts .positions-selector .position:nth-child(1) {
  border-left: 1px solid #188538;
}
.shortcuts .positions-selector .position.active {
  background: #188538;
  cursor: default;
}
.shortcuts .controls-list {
  color: rgba(255, 255, 255, 0.75);
  font-weight: 500;
  font-size: 16px;
}
@media (max-width: 959px) {
  .shortcuts .controls-list {
    font-size: 12px;
  }
}
.shortcuts .controls-list .control-name {
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-content: center;
}
.shortcuts .controls-list .control-name a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 30px);
}
.shortcuts .controls-list .control-name .control-btn {
  display: block;
  margin-left: 5px;
  width: 10px;
  height: 10px;
}
.shortcuts .controls-list .control-name .control-btn svg {
  width: 10px;
  height: 10px;
}
.shortcuts .controls-list .control-name .control-btn:hover svg {
  fill: #2DC46E;
}
.shortcuts .controls-list .control-name .control-btn:hover.control-btn-delete svg {
  fill: #d33a61;
}
.shortcuts .controls-list .control-name .disabled {
  color: rgba(255, 255, 255, 0.25);
}
.shortcuts .controls-list .control-name .disabled:hover {
  color: rgba(255, 255, 255, 0.5);
}
.shortcuts .controls-list .control-name + .control-name {
  margin-top: 15px;
}
@media (max-width: 959px) {
  .shortcuts .controls-list .control-name + .control-name {
    margin-top: 8px;
  }
}
.shortcuts .shortcuts-group {
  margin: 0 0 35px;
}
.shortcuts .shortcuts-group h2 {
  font-weight: 700;
  font-size: 14px;
  margin: 0 0 10px;
  opacity: 0.8;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.95);
  text-transform: uppercase;
}
.shortcuts .shortcuts-group .controls-list {
  padding-bottom: 0;
}
.shortcuts .controls-select {
  max-width: 230px;
}
.shortcuts .controls-select > * {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
}
@media (max-width: 959px) {
  .shortcuts .controls-select {
    max-width: 115px;
  }
}
.shortcuts .controls-select + .controls-select {
  margin-top: 30px;
}
@media (max-width: 959px) {
  .shortcuts .controls-select + .controls-select {
    margin-top: 15px;
  }
}
.shortcuts .controls-select .is-focused:not(.is-open) > .Select-control {
  border-color: #616365;
}
.shortcuts .controls-select .VirtualizedSelectOption.VirtualizedSelectSelectedOption {
  background-color: #616365 !important;
  color: #fff !important;
}
.shortcuts .controls-select .VirtualizedSelectOption.VirtualizedSelectFocusedOption {
  background-color: rgba(97, 99, 101, 0.5);
}
.shortcuts .controls-select .Select--multi .Select-value {
  color: #616365;
}
.shortcuts .controls-select .Select--multi a.Select-value-label {
  color: #616365;
}
.shortcuts .controls-select .Select-value-label {
  font-weight: 700 !important;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
}
@media (max-width: 959px) {
  .shortcuts .controls-select .Select-value-label {
    font-size: 14px;
  }
}
.shortcuts .controls-preview {
  position: relative;
  width: 42px;
  margin-left: 75px;
  margin-top: 120px;
}
@media (max-width: 959px) {
  .shortcuts .controls-preview {
    width: 26px;
    margin-left: 62px;
    margin-top: 85px;
  }
}
.shortcuts .controls-preview .player-number {
  position: relative;
  color: #fff;
  font-weight: 700;
  text-align: center;
  font-size: 21px;
  line-height: 42px;
  display: block;
  width: 42px;
  height: 42px;
}
@media (max-width: 959px) {
  .shortcuts .controls-preview .player-number {
    font-size: 14px;
    line-height: 26px;
    width: 26px;
    height: 26px;
  }
}
.shortcuts .controls-preview .player-controls {
  width: 210px;
  height: 210px;
  margin: -105px 0 0 -105px;
}
.shortcuts .controls-preview .player-controls .icon, .shortcuts .controls-preview .player-controls text {
  fill: #fff;
}
.shortcuts .controls-preview .player-controls .section {
  fill: #24323D;
  cursor: default;
}
@media (max-width: 959px) {
  .shortcuts .controls-preview .player-controls {
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
  }
}
/* Colors */
/* Fonts */
/* Dimensions */
.pdf-substitution .substitution-title {
  text-transform: uppercase;
  color: #4B4B4B;
  font-weight: bold;
  font-size: 14px;
  margin: 40px 0 12px;
}
@media screen and (max-width: 959px) {
  .pdf-substitution .substitution-title {
    font-size: 11px;
    margin: 20px 0 6px;
  }
}
.pdf-substitution .pdf-substitution-player {
  position: relative;
  padding-left: 15px;
}
@media screen and (max-width: 959px) {
  .pdf-substitution .pdf-substitution-player {
    padding-left: 11px;
  }
}
.pdf-substitution .pdf-substitution-player:before {
  position: absolute;
  top: 8px;
  left: 0;
  position: absolute;
  content: "";
}
@media screen and (max-width: 959px) {
  .pdf-substitution .pdf-substitution-player:before {
    top: 6px;
  }
}
.pdf-substitution .pdf-substitution-player.out:before {
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 9px solid #C95000;
}
@media screen and (max-width: 959px) {
  .pdf-substitution .pdf-substitution-player.out:before {
    height: 0;
    width: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 5px solid #C95000;
  }
}
.pdf-substitution .pdf-substitution-player.in:before {
  height: 0;
  width: 0;
  border-bottom: 9px solid #00BF00;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
@media screen and (max-width: 959px) {
  .pdf-substitution .pdf-substitution-player.in:before {
    height: 0;
    width: 0;
    border-bottom: 5px solid #00BF00;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
  }
}
.pdf-substitution .pdf-substitutions-list {
  position: relative;
  padding-left: 72px;
}
.pdf-substitution .pdf-substitutions-list .pdf-substitutions-min {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.pdf-substitution .pdf-substitutions-list .pdf-substitutions-min .period {
  width: 90px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}
@media screen and (max-width: 959px) {
  .pdf-substitution .pdf-substitutions-list .pdf-substitutions-min {
    font-size: 10px;
    line-height: 18px;
  }
}
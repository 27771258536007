/* Colors */
/* Fonts */
/* Dimensions */
.control-player-info {
  position: relative;
  padding: 26px 10px 20px 80px;
  font-size: 15px;
  min-height: 190px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
}
.control-player-info .select-container {
  width: 90px;
  display: inline-block;
}
@media (max-width: 959px) {
  .control-player-info {
    padding: 12px 5px 8px 44px;
    font-size: 12px;
  }
}
.control-player-info .player__image {
  position: absolute;
  top: 24px;
  left: 15px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 56px;
  height: 74px;
  border-radius: 4px;
}
@media (max-width: 959px) {
  .control-player-info .player__image {
    top: 12px;
    left: 5px;
    width: 29px;
    height: 38px;
  }
}
.control-player-info b {
  /*text-transform: uppercase;*/
  font-weight: 300;
}
.control-player-info .player__name {
  line-height: 22px;
  margin-bottom: 10px;
  font-size: 18px;
  margin-right: 46px;
  word-wrap: break-word; /* IE 5.5-7 */
  white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
  white-space: pre-wrap; /* current browsers */
}
.control-player-info .player__name input {
  width: calc(100% - 15px);
}
@media (max-width: 959px) {
  .control-player-info .player__name {
    line-height: 17px;
    margin-bottom: 5px;
    font-size: 13px;
    margin-right: 22px;
  }
}
.control-player-info .info-edit-action {
  display: inline-block;
  color: #2DC46E;
  cursor: pointer;
  vertical-align: middle;
  margin: -1px 0 -1px 10px;
}
@media (max-width: 959px) {
  .control-player-info .info-edit-action {
    margin: -3px 0 -3px 5px;
  }
}
.control-player-info .info-edit-action:hover {
  color: #fff;
}
.control-player-info .info-edit-action svg {
  width: 14px;
  height: 14px;
}
.control-player-info .small {
  font-weight: normal;
  /*font-size:14px;*/
  line-height: 18px;
}
.control-player-info input {
  font-size: inherit;
  line-height: inherit;
  height: 22px;
  width: 35px;
  font-weight: inherit;
  padding: 0;
  position: relative;
  top: -1px;
}
@media (max-width: 959px) {
  .control-player-info input {
    height: 11px;
    width: 20px;
  }
}
.control-player-info input.date_input {
  width: 85px;
}
.control-player-info input.invalid {
  border-color: #d33a61;
}
.control-player-info .add-to-favorites {
  right: 24px;
  top: 68px;
  width: 29px;
  height: 29px;
  position: absolute;
}
.control-player-info .add-to-favorites ul.list li {
  font-weight: normal;
}
.control-player-info .player_delete {
  width: 30px;
  height: 30px;
  color: #2DC46E;
  position: absolute;
  right: 24px;
  top: 120px;
  cursor: pointer;
}
.control-player-info .player_delete:hover {
  color: #FFF;
}
.control-player-info .player_delete svg {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: currentColor;
}
.control-player-info .player-favorite {
  border-radius: 50%;
  display: block;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  position: relative;
}
.control-player-info .player-favorite svg {
  vertical-align: middle;
  stroke: #2DC46E;
  width: 29px;
  height: 29px;
}
.control-player-info .player-favorite .tri {
  transform: translate(11px, 4px) !important;
  border-left: 4px solid transparent !important;
  border-right: 4px solid transparent !important;
  border-top: 5px solid !important;
}
.control-player-info .player-favorite:hover svg {
  fill: #2DC46E;
}
.control-player-info .player-favorite.active {
  background: #000;
  color: #fff;
}
.control-player-info .player-favorite.active svg {
  stroke: #000;
}
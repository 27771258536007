/* Colors */
/* Fonts */
/* Dimensions */
.create-note-form {
  max-width: 582px;
  min-width: 320px;
  margin: 0 auto;
  background: #2E3C47;
  padding: 24px;
  box-sizing: border-box;
  flex-grow: 1;
  position: relative;
}
.create-note-form h1 {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 0 20px;
}
.create-note-form h1 .title-icon {
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
  width: 16px;
  height: 16px;
}
.create-note-form .input {
  margin-top: 20px;
}
.create-note-form .input label {
  color: #A4A6A4;
  text-transform: uppercase;
}
.create-note-form .input input {
  color: #2DC46E;
}
.create-note-form .input input:focus {
  color: #FFF;
}
.create-note-form .input textarea {
  width: 100%;
  color: #A4A6A4;
  min-height: 100px;
  background: transparent;
  border: none;
}
.create-note-form .input textarea:focus {
  color: #FFF;
}
.create-note-form .close-icon {
  position: absolute;
  width: 10px;
  height: 10px;
  right: 25px;
  top: 25px;
  color: #FFF;
  cursor: pointer;
}
.create-note-form .close-icon:hover {
  color: #2DC46E;
}
.create-note-form button {
  margin-top: 20px;
}
.create-note-form__wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 999999;
}
.btn {
	line-height: 44px;
	height: 46px;
	padding: 0 22px;
	text-align: center;
	color: #35B374;
	display: inline-block;
	font-size: 15px;
	cursor: pointer;
	background: transparent;
	border: 1px solid #35B374;
	outline: none;
    text-decoration: none;
    text-transform: uppercase;
	letter-spacing: 1px;
	border-radius: 6px;
	font-weight: 400;

    @media (max-width: $screen-sm-max) {
        // line-height: 25px;
        // height: 25px;
        // padding: 0 5px;
        // font-size: 16px;
    }

	// @include linear-gradient(to top,$color_GreenDark, $color_Green);
	@include transition(background 300ms ease-in-out, border-color 300ms ease-in-out);

	&:hover {
		color:#FFF;
		border-color: #FFF;
	}

	&:active{
		color: #FFF;
		background: #35B374;
		border-color: #35B374;
	}

	&.disabled, &:disabled{
		border-color:#3A4C5A;
		color: #3A4C5A;
		background: transparent;
	}

	&.btn-secondary {
		background: inherit;
		color: #CCC;
		border:1px solid #CCC;

		&:hover{
			border-color: #35B374;
			background: #35B374;
			color: #FFF;
		}
	}

	&.btn-block {
		display: block;
		width: 100%;
	}

	&.btn-no-pad {
		padding: 0;
	}

	&.btn-red{
		background: inherit;
		border: 1px solid $color_Red;
		color: $color_Red;

		&:hover{
			background: shade($color_Red, 20%);
			color: #FFF;
		}
	}

    &.btn-transparent {
        background: transparent !important;
    }
}


.btn-small{
	height:auto;
	line-height: 22px;
	font-size:14px;
}

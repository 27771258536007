/* Colors */
/* Fonts */
/* Dimensions */
.create-match-button {
  background: #35B374;
  color: #FFF;
  border: none;
  height: 44px;
  line-height: 44px;
}
.create-match-button__container {
  margin-left: 24px;
  position: relative;
  z-index: 5;
}
.create-match-button__demo {
  position: absolute;
  top: 100%;
  margin-top: 56px;
  right: 0;
  z-index: 5;
}
.create-match-button:hover {
  background: #FFF;
  color: #35B374;
}
/* Colors */
/* Fonts */
/* Dimensions */
.reports__group-ad {
  overflow: hidden;
  width: 320px;
  position: relative;
  height: 406px;
}
.reports__group-ad__bg {
  position: absolute;
  z-index: 1;
  display: block;
  width: 320px;
  height: 130px;
  background: #24323D;
  border-radius: 6px;
  left: 0;
  top: 0;
}
.reports__group-ad__bg:nth-child(2) {
  top: 138px;
}
.reports__group-ad__bg:nth-child(3) {
  top: 276px;
}
.reports__group-ad__pane {
  border-radius: 4px;
  margin: 43px auto 0;
  width: 190px;
  padding: 16px;
  background: #3A4C5A;
  position: relative;
  z-index: 2;
}
.reports__group-ad__pane svg {
  width: 80px;
  height: 80px;
  display: block;
  margin: 0 auto;
}
.reports__group-ad__pane h3 {
  margin: 16px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #FFFFFF;
}
.reports__group-ad__pane p {
  margin: 16px 0;
  font-size: 13px;
  line-height: 16px;
  color: #A4A6A4;
}
.reports__group-ad__pane .btn {
  border: none;
  background: #35B374;
  color: #FFF;
  font-size: 13px;
  text-transform: uppercase;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.reports__group-ad__pane .btn:hover {
  background: #FFF;
  color: #35B374;
}
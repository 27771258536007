/* Colors */
/* Fonts */
/* Dimensions */
.positions-list {
  display: flex;
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid #354C5C;
}
.positions-list label {
  font-size: 16px;
  line-height: 20px;
}
.positions-list__pitch {
  width: 100px;
  height: 133px;
  flex-grow: 0;
  border: 1px solid #FFF;
  position: relative;
  flex-shrink: 0;
  margin-right: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.inverted .positions-list__pitch {
  border-color: #181C20;
}
.positions-list__pitch * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.positions-list__pitch .home, .positions-list__pitch .away {
  position: absolute;
  width: 47px;
  height: 20px;
  border: 1px solid #FFF;
  left: 50%;
  margin-left: -23px;
}
.inverted .positions-list__pitch .home, .inverted .positions-list__pitch .away {
  border-color: #181C20;
}
.positions-list__pitch .home:after, .positions-list__pitch .away:after {
  content: "";
  display: block;
  position: absolute;
  left: 13px;
  width: 20px;
  height: 7px;
  border: 1px solid #FFF;
}
.inverted .positions-list__pitch .home:after, .inverted .positions-list__pitch .away:after {
  border-color: #181C20;
}
.positions-list__pitch .home {
  border-top: none;
  top: 0;
}
.positions-list__pitch .home:after {
  top: 0;
  border-top: none;
}
.positions-list__pitch .away {
  border-bottom: none;
  bottom: 0;
}
.positions-list__pitch .away:after {
  bottom: 0;
  border-bottom: none;
}
.positions-list__pitch .center {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
  background: #FFFFFF;
}
.inverted .positions-list__pitch .center {
  background: #181C20;
}
.positions-list__pitch .center:after {
  content: "";
  display: block;
  position: absolute;
  border: 1px solid #FFF;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
}
.inverted .positions-list__pitch .center:after {
  border-color: #181C20;
}
.positions-list__pitch-position {
  display: block;
  position: absolute;
  width: 11px;
  height: 11px;
  border: 1px solid #2DC46E;
  border-radius: 100%;
  background: rgba(56, 207, 127, 0.3);
  margin-top: -5px;
  margin-left: -5px;
}
.positions-list__pitch-position.clickable {
  cursor: pointer;
}
.positions-list__pitch-position.clickable:hover {
  background: rgba(56, 207, 127, 0.5);
}
.positions-list__pitch-position.clickable.current-position {
  background: #FFF;
}
.positions-list__pitch-position.most-played {
  background: rgba(56, 207, 127, 0.5);
}
.positions-list__pitch-position.current-position {
  background: #FFF;
  border: none;
  opacity: 1;
}
.inverted .positions-list__pitch-position.current-position {
  opacity: 1 !important;
  background: #000;
  box-shadow: none;
  border: none;
}
.positions-list__pitch-position.not-selected-position {
  opacity: 0.5;
}
.inverted .positions-list__pitch-position.not-selected-position {
  opacity: 0.7;
}
.positions-list__positions-list {
  min-width: calc(100% - 125px);
  width: 100%;
}
.positions-list__positions-list .empty-message {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
}
.positions-list__list-item {
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
}
.positions-list__list-item:nth-child(1) {
  margin-top: 0;
}
.positions-list__list-item .progress-legend span {
  opacity: 0.7;
}
.positions-list__show-more-item {
  margin-top: 20px;
}
.positions-list__progress {
  width: 100%;
  height: 3px;
  background: #22313b;
  margin: 6px 0 3px;
}
.positions-list__progress-bar {
  height: 3px;
  background: #35B374;
}
.positions-list__show-more {
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px dotted;
  cursor: pointer;
  color: #2DC46E;
}
/* Colors */
/* Fonts */
/* Dimensions */
.note-form {
  margin-bottom: 35px;
}
.note-form textarea {
  display: block;
  border-left: 2px solid transparent;
  width: 100%;
  resize: none;
  padding-left: 0;
  transition: border 500ms ease-out, padding 500ms ease-out;
  background: none;
  color: #FFF;
  border: none;
}
.note-form textarea::placeholder {
  color: #CCC;
  transform: translateX(0) translateZ(0);
  transition: color 500ms ease-out, transform 500ms ease-out;
}
.note-form textarea:focus {
  border-left: 2px solid #35B374;
  padding-left: 10px;
}
.note-form textarea:focus::placeholder {
  transform: translateX(100px) translateZ(0);
  color: transparent;
}
.note-form__done {
  transition: color 250ms ease-out;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 2px;
  line-height: 36px;
  width: 120px;
  padding: 0;
  background: #53B78F;
  text-align: center;
  float: right;
}
.note-form__done:hover {
  color: #fff;
}
.note-form__player {
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.note-form__player .note-player-select {
  flex-shrink: 0;
  flex-grow: 1;
}
.note-form__title {
  font-size: 24px;
  line-height: 32px;
  color: #FFF;
  font-weight: 500;
  min-height: 32px;
  width: 425px !important;
  display: inline-block !important;
}
@media (max-width: 1023px) {
  .note-form__title {
    width: 360px !important;
  }
}
.note-form__text {
  font-size: 15px;
  line-height: 22px;
  margin-top: 2px;
}
.note-form__tags {
  margin-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 15px;
}
.note-form__tags .Select-value {
  white-space: nowrap;
}
/* Colors */
/* Fonts */
/* Dimensions */
.pdf-events {
  margin: 24px auto 0;
  max-width: 400px;
}
.pdf-events table {
  border: none;
}
.pdf-events table tr, .pdf-events table td, .pdf-events table th {
  border: none;
}
.pdf-events table td {
  border-top: 1px solid #D7D9D7;
  white-space: nowrap;
}
.pdf-events table tr:nth-child(1) td {
  border-top: none;
}
.pdf-events .events-goal {
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
}
.pdf-events .goal-time {
  font-weight: bold;
  margin: 0 4px;
  display: inline-block;
}
.pdf-events .team-logo {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.pdf-events .player-photo {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-left: 4px;
}
.pdf-events .goal-info {
  white-space: nowrap;
}
.pdf-events .own-goal {
  color: #d33a61;
}
.pdf-events .right {
  text-align: right;
}
/* Colors */
/* Fonts */
/* Dimensions */
.favorites-list__wrapper {
  margin-top: 50px;
  position: relative;
}
.favorites-list__wrapper h2 {
  font-size: 19px;
  font-weight: bold;
  margin: 0 0 0px;
}
.favorites-list__wrapper .favorites-list {
  margin: 0;
  min-width: 740px;
}
.favorites-list__wrapper .favorites-list th, .favorites-list__wrapper .favorites-list td {
  text-overflow: ellipsis;
  vertical-align: middle;
}
.favorites-list__wrapper .favorites-list.entries-list th .entry-player-name, .favorites-list__wrapper .favorites-list.entries-list td .entry-player-name {
  padding-left: 20px;
}
.favorites-list__wrapper .favorites-list.entries-list th.col__sort, .favorites-list__wrapper .favorites-list.entries-list td.col__sort {
  width: 45px;
}
.favorites-list__wrapper .favorites-list.entries-list th.col__fav, .favorites-list__wrapper .favorites-list.entries-list td.col__fav {
  width: 40px;
}
.favorites-list__wrapper .favorites-list.entries-list th.col__rating, .favorites-list__wrapper .favorites-list.entries-list td.col__rating {
  width: 145px;
}
.favorites-list__wrapper .favorites-list.entries-list th.col__birth-date, .favorites-list__wrapper .favorites-list.entries-list td.col__birth-date {
  width: 40px;
}
.favorites-list__wrapper .favorites-list.entries-list th.col__notes, .favorites-list__wrapper .favorites-list.entries-list td.col__notes {
  width: 95px;
}
.favorites-list__wrapper .favorites-list.entries-list th.col__evaluations, .favorites-list__wrapper .favorites-list.entries-list td.col__evaluations {
  width: 95px;
}
.favorites-list__wrapper .favorites-list.entries-list th.col__country, .favorites-list__wrapper .favorites-list.entries-list td.col__country {
  width: 90px;
}
.favorites-list__wrapper .favorites-list.entries-list th.col__delete, .favorites-list__wrapper .favorites-list.entries-list td.col__delete {
  width: 40px;
}
.favorites-list__wrapper .favorites-list.entries-list th.col__delete svg, .favorites-list__wrapper .favorites-list.entries-list td.col__delete svg {
  width: 20px;
  height: 20px;
}
.favorites-list__wrapper .favorites-list.entries-list th.col__delete .delete-entry, .favorites-list__wrapper .favorites-list.entries-list td.col__delete .delete-entry {
  cursor: pointer;
}
.favorites-list__wrapper .favorites-list.entries-list th.col__delete .delete-entry svg, .favorites-list__wrapper .favorites-list.entries-list td.col__delete .delete-entry svg {
  stroke: #35B374;
  fill: none;
}
.favorites-list__wrapper .favorites-list.entries-list th.col__delete .delete-entry:hover svg, .favorites-list__wrapper .favorites-list.entries-list td.col__delete .delete-entry:hover svg {
  stroke: #FFF;
  fill: none;
}
.favorites-list__wrapper .favorites-list .next-page .js-link {
  display: block;
  width: 100%;
  height: 52px;
  line-height: 52px;
  color: #2DC46E;
  text-align: center;
  cursor: pointer;
}
.favorites-list__wrapper .favorites-list .next-page .js-link:hover {
  color: #188538;
}
@media screen and (max-width: 1024px) {
  .favorites-list__wrapper .favorites-list th, .favorites-list__wrapper .favorites-list td {
    font-size: 13px;
  }
}
.favorites-list__wrapper .favorites-list .add-to-favorites {
  position: relative;
  top: -1px;
}
.favorites-list__wrapper .favorites-list .favorite-selector {
  cursor: pointer;
  line-height: 25px;
}
.favorites-list__wrapper .favorites-list .favorite-selector svg {
  width: 21px;
  height: 21px;
  display: inline-block;
  position: relative;
  top: -2px;
  vertical-align: middle;
}
.favorites-list__wrapper .favorites-list .favorite-selector .chevron-down {
  vertical-align: middle;
  width: 15px;
  height: 15px;
  position: relative;
  fill: #FFF !important;
  stroke: none !important;
  top: -1px;
}
.favorites-list__wrapper .fav-td {
  width: 44px;
}
.favorites-list__wrapper .fav-td .favorite-change {
  opacity: 0.7;
}
.favorites-wrapper .reports {
  background: #293B47;
  padding: 16px;
}
.favorites-wrapper .reports table {
  border: none;
}
.favorites-wrapper .reports table td, .favorites-wrapper .reports table th {
  border: none;
}
.favorites-wrapper .reports table th {
  border-bottom: 1px solid #24323D;
  padding: 8px 10px;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
}
.favorites-wrapper .reports table td {
  font-size: 16px;
  line-height: 21px;
  padding: 5px 10px;
  vertical-align: middle;
}
.favorites-wrapper .reports table tr:hover td {
  background: #24323D;
  cursor: pointer;
}
.favorites-wrapper .reports table tr.inactive td {
  opacity: 0.5;
}
.favorites-wrapper .reports table tr.inactive td.pdf-cell {
  opacity: 1;
}
.favorites-wrapper .reports table tr.inactive:hover td {
  background: none;
  cursor: default;
}
.favorites-wrapper .reports table .pdf-link {
  width: 22px;
  height: 22px;
  fill: #2DC46E;
}
.favorites-wrapper .reports table .pdf-link:hover {
  cursor: pointer;
}
.favorites-wrapper .reports table .team-image {
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 6px;
  top: -2px;
  position: relative;
}
.favorites-wrapper .reports table .rating-rate .legend {
  width: 8px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 6px;
}
.favorites-wrapper .reports .empty-message {
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  padding: 120px 0;
  color: rgba(255, 255, 255, 0.5);
}
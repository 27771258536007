/* Colors */
/* Fonts */
/* Dimensions */
.inner .control .section {
  fill: #263742;
  stroke: #151C21;
  stroke-width: 1px;
  cursor: pointer;
}
.inner .control .section:hover {
  fill: #3c4b55;
}
.inner .control .icon {
  fill: #2DC46E;
  shape-rendering: geometricPrecision;
  pointer-events: none;
}
.inner .control text {
  text-anchor: middle;
  pointer-events: none;
  font-size: 11px;
  fill: #2DC46E;
}
@media (max-width: 959px) {
  .inner .control text {
    font-size: 8px;
  }
}
.inner .control.active .icon, .inner .control.active text {
  fill: white;
}
/* Colors */
/* Fonts */
/* Dimensions */
.notes-page {
  margin: 0 -25px;
}

.notes-desktop-container {
  display: flex;
  padding-bottom: 0 !important;
}
@media (max-width: 959px) {
  .notes-desktop-container {
    margin-top: 8px;
  }
}
.notes-desktop-container ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.notes-desktop-container ul li {
  padding: 0;
  margin: 0;
}
.notes-desktop-container .notes-desktop-body {
  display: flex;
}
.notes-desktop-container .notes-desktop-body {
  flex-shrink: 1;
  flex-grow: 1;
  width: 78%;
  max-width: 12015px;
}
.notes-desktop-container .notes-desktop-body .notes-list__item-text {
  width: 100%;
}
.notes-desktop-container .notes-desktop-body .notes-list__item {
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding: 10px 12px 7px 10px;
  transition: background 250ms ease-out, border-color 250ms ease-out;
}
.notes-desktop-container .notes-desktop-body .notes-list__item:hover {
  background: rgba(255, 255, 255, 0.5);
}
.notes-desktop-container .notes-desktop-body .notes-list__item.active {
  cursor: default;
  padding-left: 12px;
  background: rgba(255, 255, 255, 0.15);
}
.notes-desktop-container .notes-desktop-body .notes-list__item h4 {
  font-size: 15px;
  line-height: 21px;
  font-weight: 500;
  color: #FFF;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notes-desktop-container .notes-desktop-body .notes-list__item .text-preview {
  font-size: 14px;
  line-height: 18px;
  color: #CCC;
  height: 36px;
  overflow: hidden;
}
.notes-desktop-container .notes-desktop-body .notes-list__item-tag .note-tag {
  background: none;
  font-size: 13px;
  color: #2DC46E;
  padding: 1px 6px 1px 0px;
  font-weight: 500;
}
.notes-desktop-container .notes-desktop-body .notes-list__item-meta {
  margin-top: 2px;
  font-size: 12px;
  line-height: 17px;
  color: #CCC;
  display: flex;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  width: 100%;
}
.notes-desktop-container .notes-desktop-body .notes-list__item-meta .author {
  flex-grow: 1;
}
.notes-desktop-container .notes-desktop-body .notes-list__item-meta .date {
  text-align: right;
  margin-left: 10px;
  white-space: nowrap;
}
.player-card {
  display: flex;
  font-size: 14px;
  line-height: 21px;
  background: transparent;
  transition: background 250ms ease-out;
}
.player-card div, .player-card span {
  display: block;
}
.player-card .player-text .player-name-last, .player-card .player-text .player-name-first {
  text-transform: uppercase;
  color: green;
  letter-spacing: 0.5px;
  line-height: 120%;
}
.player-card .player-text .player-name-first {
  font-size: 13px;
}
.player-card .player-text .player-name-last {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
}
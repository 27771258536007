.add-player-form-modal {
  max-width: 640px;
  width: 100%;
  min-width: 320px;
}
.add-player-form-modal .sp-modal__heading svg {
  fill: none;
  stroke: #FFF;
}
.add-player-form-modal .input-placeholder-example {
  font-size: 12px;
  opacity: 0.5;
  line-height: 16px;
}
.add-player-form-modal .jersey input {
  padding: 8px 5px 7px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.pdf-bench .bench-title {
  text-transform: uppercase;
  color: #4B4B4B;
  font-weight: bold;
  font-size: 14px;
  margin: 40px 0 12px;
}
@media screen and (max-width: 959px) {
  .pdf-bench .bench-title {
    font-size: 11px;
    margin: 20px 0 6px;
  }
}
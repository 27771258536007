/* Colors */
/* Fonts */
/* Dimensions */
.single-report__position-select label {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
}
.single-report__position-select .demo-message {
  right: 0;
  position: absolute;
  top: 210px;
}
@media (max-width: 959px) {
  .single-report__position-select .positions-list__pitch {
    width: 86px;
    height: 103px;
  }
}
.single-report__position-select .positions-list__pitch.inverted {
  border-color: #181C20;
}
.single-report__position-select .positions-list__pitch.inverted .home, .single-report__position-select .positions-list__pitch.inverted .away {
  border-color: #181C20;
}
.single-report__position-select .positions-list__pitch.inverted .home:after, .single-report__position-select .positions-list__pitch.inverted .away:after {
  border-color: #181C20;
}
.single-report__position-select .positions-list__pitch.inverted .center {
  background: #181C20;
}
.single-report__position-select .positions-list__pitch.inverted .center:after {
  border-color: #181C20;
}
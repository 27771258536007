/* Colors */
/* Fonts */
/* Dimensions */
.favorite-summary .content-block:after {
  content: "";
  display: table;
  clear: both;
}

.favorite-summary .content-block {
  background: #293B47;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 2px 16px 1px rgba(0, 0, 0, 0.1);
  padding: 24px 0;
  margin-top: 25px;
}
.favorite-summary .player-information {
  position: relative;
  z-index: 3;
}
.favorite-summary .player-information__scouting-level {
  margin-top: 25px;
}
.favorite-summary .player-information__scouting-level h4 {
  cursor: pointer;
}
.favorite-summary .player-information__scouting-level h4:hover {
  color: #35B374;
}
.favorite-summary .player-information__scouting-level svg {
  fill: none;
  stroke: currentColor;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 6px;
  vertical-align: middle;
  position: relative;
  top: -2px;
}
.favorite-summary .player-information__scouting-level p {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
}
@media screen and (min-width: 1279px) {
  .favorite-summary .player-information {
    display: flex;
  }
}
.favorite-summary .player-information__league-stats {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 20px 25px;
}
@media (max-width: 959px) {
  .favorite-summary .player-information__league-stats {
    padding: 20px;
  }
}
.favorite-summary .player-information__league-stats .leagues-locked-message {
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
}
.favorite-summary .player-information__league-stats .leagues-locked-message svg {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  vertical-align: middle;
}
.favorite-summary .player-information__league-stats .leagues-table {
  border: none;
}
.favorite-summary .player-information__league-stats .leagues-table svg {
  width: 16px;
  height: 16px;
  stroke: rgba(255, 255, 255, 0.7);
}
.favorite-summary .player-information__league-stats .leagues-table svg.no-stroke {
  stroke: none;
}
@media (max-width: 959px) {
  .favorite-summary .player-information__league-stats .leagues-table {
    margin: 0;
  }
}
.favorite-summary .player-information__league-stats .leagues-table th {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  line-height: 19px;
  border: none;
}
@media (max-width: 959px) {
  .favorite-summary .player-information__league-stats .leagues-table th {
    font-size: 12px;
    line-height: 16px;
  }
}
.favorite-summary .player-information__league-stats .leagues-table td {
  border: none;
}
@media (max-width: 959px) {
  .favorite-summary .player-information__league-stats .leagues-table td {
    font-size: 12px;
    line-height: 16px;
  }
  .favorite-summary .player-information__league-stats .leagues-table td:nth-child(1) {
    white-space: nowrap;
  }
}
.favorite-summary .player-information__league-stats .leagues-table td.pagination-link {
  text-align: center;
}
.favorite-summary .player-information__league-stats .leagues-table td.pagination-link span {
  color: #2DC46E;
  cursor: pointer;
}
.favorite-summary .player-information__league-stats .leagues-table .data-item__image {
  position: relative;
  top: -2px;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-position: center center;
  background-size: contain;
  vertical-align: middle;
  margin-right: 4px;
  background-repeat: no-repeat;
}
@media (max-width: 959px) {
  .favorite-summary .player-information__league-stats .leagues-table .data-item__image {
    width: 16px;
    height: 16px;
  }
}
.favorite-summary .player-information__main, .favorite-summary .player-information__team, .favorite-summary .player-information__rating {
  width: 100%;
  display: block;
  padding: 0 24px;
}
@media screen and (min-width: 1279px) {
  .favorite-summary .player-information__main, .favorite-summary .player-information__team, .favorite-summary .player-information__rating {
    flex-grow: 0;
    width: 33.3%;
  }
}
.favorite-summary .player-information__main h4, .favorite-summary .player-information__team h4, .favorite-summary .player-information__rating h4 {
  font-size: 18px;
  line-height: 36px;
  color: #FFF;
}
.favorite-summary .player-information__team, .favorite-summary .player-information__rating {
  margin-top: 45px;
}
@media screen and (min-width: 1279px) {
  .favorite-summary .player-information__team, .favorite-summary .player-information__rating {
    margin-top: 0;
    border-left: 1px solid rgba(53, 76, 92, 0.5);
  }
}
.favorite-summary .player-information__team .team-row {
  position: relative;
  z-index: 7;
}
.favorite-summary .player-information__team .team-row .data-item-changeable {
  width: 90%;
}
.favorite-summary .player-information__rating .final-rating {
  max-width: 300px;
}
.favorite-summary .player-information__rating .final-rating .final-rating-number {
  display: block;
  font-size: 16px;
  line-height: 120%;
  opacity: 0.7;
  font-weight: 400;
  margin-left: 25px;
}
.favorite-summary .player-information__rating .final-rating .final-rating-description .rating-rate {
  font-size: 32px;
  line-height: 110%;
  margin-bottom: 5px;
  display: block;
}
.favorite-summary .player-information__rating .final-rating .final-rating-description .rating-rate .legend {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 3px;
}
@media screen and (min-width: 1279px) {
  .favorite-summary .player-information__rating .final-rating {
    margin-top: 15px;
  }
}
.favorite-summary .player-information__external {
  margin-top: 0;
}
.favorite-summary .player-information__external h3 {
  margin: 0 0 16px;
  font-size: 18px;
  line-height: 22px;
}
.favorite-summary .player-information__external a {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 12px;
}
.favorite-summary .player-information__external svg {
  width: 24px;
  height: 24px;
}
.favorite-summary .player-information__column, .favorite-summary .player-information__indicators, .favorite-summary .player-information__reports {
  width: 100%;
  display: block;
  padding-left: 24px;
  padding-right: 24px;
}
@media screen and (min-width: 1279px) {
  .favorite-summary .player-information__column, .favorite-summary .player-information__indicators, .favorite-summary .player-information__reports {
    width: calc(33.3% - 10px);
    margin-left: 16px;
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .favorite-summary .player-information__column:nth-child(1), .favorite-summary .player-information__indicators:nth-child(1), .favorite-summary .player-information__reports:nth-child(1) {
    margin-left: 0;
  }
}
.favorite-summary .player-information__column {
  padding: 0 !important;
}
.favorite-summary .player-information__stats, .favorite-summary .player-information__external {
  padding-left: 24px;
  padding-right: 24px;
}
@media screen and (min-width: 1279px) {
  .favorite-summary .player-information__stats, .favorite-summary .player-information__external {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.favorite-summary .player-information__indicators {
  padding-left: 0;
  padding-right: 0;
  margin-top: 25px;
}
@media screen and (min-width: 1279px) {
  .favorite-summary .player-information__indicators {
    padding-left: 0;
    padding-right: 0;
    margin-top: 16px;
  }
}
.favorite-summary .player-information__reports {
  padding-left: 0;
  padding-right: 0;
}
.favorite-summary .player-information__reports .content-block {
  padding-left: 24px;
  padding-right: 24px;
}
@media screen and (min-width: 1279px) {
  .favorite-summary .player-information__reports {
    padding-left: 0;
    padding-right: 0;
  }
  .favorite-summary .player-information__reports .content-block {
    padding-left: 24px;
    padding-right: 24px;
  }
  .favorite-summary .player-information__reports .content-block:nth-child(1) {
    margin-top: 0;
  }
}
.favorite-summary .aside .content-block {
  margin-top: 12px;
}
.favorite-summary .aside .content-block:nth-child(1) {
  margin-top: 0;
}
.favorite-summary .add-to-favorites {
  position: relative;
  top: -1px;
}
.favorite-summary .add-to-favorites ul.list {
  right: auto;
  left: 0;
}
.favorite-summary .favorite-selector {
  cursor: pointer;
  font-size: 30px;
  line-height: 30px;
}
.favorite-summary .favorite-selector svg {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: relative;
  top: -2px;
  vertical-align: middle;
  margin-right: 5px;
}
.favorite-summary .favorite-selector .chevron-down {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  position: relative;
  fill: #FFF !important;
  stroke: none !important;
  top: -1px;
  margin-right: 0;
}
.favorite-summary .custom-player-controls .btn {
  margin-left: 25px;
}
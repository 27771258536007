/* Colors */
/* Fonts */
/* Dimensions */
.player {
  position: relative;
  padding: 2px 55px 2px 69px;
  height: 40px;
  font-weight: 500;
  font-size: 15px;
  background: #1C2830;
}
@media (max-width: 959px) {
  .player {
    padding: 0 24px 0 50px;
    height: 33px;
    font-size: 11px;
  }
}
.player:nth-child(even) {
  background-color: #212f38;
}
.player.disabled {
  color: rgba(255, 255, 255, 0.3);
}
.player.disabled .player__image,
.player.disabled .player-grab,
.player.disabled .player__edit {
  opacity: 0.2 !important;
  cursor: default;
}
.player .player__number {
  font-size: 15px;
  text-align: right;
  width: 28px;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 959px) {
  .player .player__number {
    font-size: 12px;
    width: 21px;
    line-height: 33px;
  }
}
.player .player__image {
  position: absolute;
  top: 50%;
  left: 40px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 30px;
  margin-top: -15px;
  border-radius: 3px;
}
@media (max-width: 959px) {
  .player .player__image {
    left: 27px;
    width: 16px;
    height: 22px;
    margin-top: -11px;
  }
}
.player .player__name {
  margin-right: 2%;
  width: 77%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 35px;
  display: inline-block;
  font-size: 15px;
}
@media (max-width: 959px) {
  .player .player__name {
    line-height: 32px;
    font-size: 12px;
  }
}
.player .player__position {
  width: 16%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 300;
  font-size: 14px;
  line-height: 35px;
  display: inline-block;
}
@media (max-width: 959px) {
  .player .player__position {
    font-size: 11px;
    line-height: 33px;
    display: none;
  }
}
.player .player__edit {
  width: 5%;
  display: inline-block;
  line-height: 35px;
  font-size: 14px;
  font-weight: 300;
  vertical-align: top;
  cursor: pointer;
}
.player .player__edit svg {
  width: 12px;
  height: 12px;
  color: #2DC46E;
}
.player .player__edit:hover svg {
  color: #FFF;
}
@media (max-width: 959px) {
  .player .player__edit {
    font-size: 11px;
    line-height: 33px;
  }
}
.player .player-grab {
  position: absolute;
  top: 50%;
  right: 0px;
  margin-top: -22px;
  color: #2DC46E;
  padding: 12px 18px 11px 16px;
  cursor: pointer;
  cursor: grab;
}
.player .player-grab:hover {
  color: white;
}
@media (max-width: 959px) {
  .player .player-grab {
    margin-top: -14px;
    padding: 7px 18px 7px 10px;
  }
}
.player .player-grab svg {
  width: 20px;
  height: 12px;
}
@media (max-width: 959px) {
  .player .player-grab svg {
    height: 10px;
  }
}
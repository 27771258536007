/* Colors */
/* Fonts */
/* Dimensions */
.event-icons {
  left: 75%;
  top: 75%;
  position: absolute;
  white-space: nowrap;
  pointer-events: none;
}
@media (max-width: 959px) {
  .event-icons {
    transform: scale(0.6);
    transform-origin: 0 0;
  }
}
.event-icons .goal-icon {
  font-size: 12px;
  line-height: 14px;
  color: #FFF;
  white-space: nowrap;
  vertical-align: bottom;
}
.event-icons .goal-icon svg {
  vertical-align: middle;
}
.event-icons svg {
  width: 14px;
  height: 14px;
}
.event-icons svg + svg {
  margin-left: -3px;
}
.event-icons svg.red {
  fill: #ef3955;
  stroke: #ef3955;
}
.event-icons svg.yellow {
  fill: #fccf07;
  stroke: #fccf07;
}
.event-icons svg.yellow_red {
  width: 20px;
}
.event-icons svg.goal {
  stroke: #000;
  fill: #FFF;
}
.event-icons svg.goal + .goal {
  margin-left: -5px;
}
.event-icons svg.own-goal {
  stroke: #000;
  fill: #ff8787;
}
.event-icons svg.own-goal + .own-goal {
  margin-left: -5px;
}
.event-icons__small svg {
  width: 12px;
  height: 12px;
}
.event-icons__small svg.yellow_red {
  width: 16px;
}
.event-icons__xs-small svg {
  width: 10px;
  height: 10px;
}
.event-icons__xs-small svg.yellow_red {
  width: 13px;
}
.event-icons__white-small-list {
  left: 12px;
  top: 12px;
  position: absolute;
  white-space: nowrap;
  pointer-events: none;
}
.event-icons__white-small-list .goal-icon {
  color: #000;
  position: relative;
  top: -6px;
}
.event-icons__white-small-list svg {
  width: 10px;
  height: 10px;
}
.event-icons__white-small-list svg.yellow_red {
  width: 14px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.match-title {
  min-height: 70px;
}
@media (max-width: 959px) {
  .match-title {
    min-height: 36px;
  }
}
.match-title .title-teams {
  color: #fff;
  position: relative;
  line-height: 52px;
  margin: 0 40px;
}
@media (max-width: 959px) {
  .match-title .title-teams {
    line-height: 26px;
  }
}
.match-title .title-teams .goals {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: center;
}
.match-title .title-teams .goals textarea:hover::placeholder, .match-title .title-teams .goals textarea:hover::-webkit-input-placeholder {
  color: white;
}
.match-title .title-teams .goals textarea::placeholder, .match-title .title-teams .goals textarea::-webkit-input-placeholder {
  color: #2DC46E;
}
.match-title .title-teams .goals textarea::-moz-placeholder {
  color: #2DC46E;
}
.match-title .title-teams .goals .goals-main {
  font-size: 30px;
  line-height: 52px;
  font-weight: 500;
}
@media (max-width: 959px) {
  .match-title .title-teams .goals .goals-main {
    font-size: 18px;
    line-height: 26px;
  }
}
.match-title .title-teams .goals .goals-main textarea {
  display: inline-block;
  text-align: center;
  height: 52px;
  width: 24px;
  overflow: hidden;
  resize: none;
  border: none;
  vertical-align: bottom;
  padding: 0;
  line-height: 52px;
  background: transparent;
  color: #2DC46E;
}
@media (max-width: 959px) {
  .match-title .title-teams .goals .goals-main textarea {
    height: 26px;
    width: 12px;
    line-height: 26px;
  }
}
.match-title .title-teams .goals .goals-ht {
  position: absolute;
  right: 0;
  bottom: -12px;
  left: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 21px;
}
@media (max-width: 959px) {
  .match-title .title-teams .goals .goals-ht {
    position: absolute;
    right: 0;
    bottom: -6px;
    left: 0;
    font-size: 12px;
    line-height: 11px;
  }
}
.match-title .title-teams .goals .goals-ht textarea {
  color: #2DC46E;
  display: inline-block;
  text-align: center;
  height: 21px;
  width: 12px;
  overflow: hidden;
  resize: none;
  border: none;
  vertical-align: bottom;
  padding: 0;
  line-height: 21px;
  background: transparent;
}
@media (max-width: 959px) {
  .match-title .title-teams .goals .goals-ht textarea {
    height: 11px;
    width: 5px;
    line-height: 11px;
  }
}
.match-title .title-teams .Select {
  line-height: 19px;
  display: inline-block;
  position: absolute;
  bottom: -30px;
  width: 133px;
}
@media (max-width: 959px) {
  .match-title .title-teams .Select {
    bottom: -23px;
    width: 114px;
  }
}
.match-title .title-teams .Select .Select-value-label,
.match-title .title-teams .Select .Select-placeholder {
  font-weight: 700 !important;
  color: #2DC46E !important;
}
.match-title .title-teams .Select .Select-arrow {
  border-color: #2DC46E transparent transparent;
}
.match-title .title-teams .Select .Select-control {
  border-bottom: none !important;
}
.match-title .title-teams .Select .Select-input input {
  text-align: inherit;
}
.match-title .title-teams .home,
.match-title .title-teams .away {
  display: inline-block;
  width: 40%;
  width: calc(50% - 45px);
  position: relative;
  font-size: 25px;
  font-weight: 700;
  vertical-align: middle;
}
@media (max-width: 959px) {
  .match-title .title-teams .home,
  .match-title .title-teams .away {
    width: calc(50% - 23px);
    font-size: 14px;
  }
}
.match-title .title-teams .home {
  text-align: right;
  padding-right: 90px;
}
@media (max-width: 959px) {
  .match-title .title-teams .home {
    padding-right: 45px;
  }
}
.match-title .title-teams .home .Select {
  right: 80px;
}
@media (max-width: 959px) {
  .match-title .title-teams .home .Select {
    right: 32px;
  }
}
.match-title .title-teams .home .team-image {
  right: 25px;
}
@media (max-width: 959px) {
  .match-title .title-teams .home .team-image {
    right: 13px;
  }
}
.match-title .title-teams .away {
  text-align: left;
  padding-left: 90px;
}
@media (max-width: 959px) {
  .match-title .title-teams .away {
    padding-left: 45px;
  }
}
.match-title .title-teams .away .Select {
  left: 90px;
}
@media (max-width: 959px) {
  .match-title .title-teams .away .Select {
    left: 44px;
  }
}
.match-title .title-teams .away .team-image {
  left: 25px;
}
@media (max-width: 959px) {
  .match-title .title-teams .away .team-image {
    left: 13px;
  }
}
.match-title .title-teams .team {
  line-height: 52px;
}
@media (max-width: 959px) {
  .match-title .title-teams .team {
    line-height: 26px;
    max-height: 26px;
    overflow: hidden;
  }
}
.match-title .title-teams .team-image {
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 50px;
  height: 60px;
  margin-top: -30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (max-width: 959px) {
  .match-title .title-teams .team-image {
    width: 25px;
    height: 30px;
    margin-top: -15px;
  }
}
.match-title .title-teams .team-name {
  line-height: 25px;
  vertical-align: middle;
}
@media (max-width: 959px) {
  .match-title .title-teams .team-name {
    line-height: 13px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.match-title .title-info {
  margin-top: 18px;
  color: rgba(255, 255, 255, 0.75);
  letter-spacing: 0.1em;
  font-size: 12px;
  text-transform: uppercase;
}
@media (max-width: 959px) {
  .match-title .title-info {
    margin-top: 9px;
    font-size: 6px;
  }
}
/* Colors */
/* Fonts */
/* Dimensions */
.snapshots {
  display: inline-block;
  vertical-align: top;
  -webkit-transform-origin: bottom left;
  -moz-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  -o-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  font-size: 18px;
  font-weight: 700;
  line-height: 50px;
  position: absolute;
  margin-top: -17px;
}
@media (max-width: 959px) {
  .snapshots {
    font-size: 12px;
    line-height: 32px;
    margin-top: -22px;
  }
}
.snapshots ul {
  white-space: nowrap;
  text-align: left;
}
.snapshots ul li {
  display: inline-block;
  margin-right: 20px;
}
.snapshots ul li.create {
  font-weight: 400;
}
@media (max-width: 959px) {
  .snapshots ul li {
    margin-right: 8px;
    min-width: 30px;
  }
}
.snapshots ul li a {
  color: #2DC46E;
  cursor: pointer;
}
.snapshots ul li.active a {
  color: #fff;
}
.snapshots ul li .snapshot-name {
  display: inline-block;
  max-width: calc(100% - 18px);
  min-width: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
.snapshots ul li .remove-snapshot {
  color: #2DC46E !important;
  margin-left: 6px;
}
@media (max-width: 959px) {
  .snapshots ul li .remove-snapshot {
    margin-left: -1px;
  }
}
.snapshots ul li .remove-snapshot svg {
  stroke: #2DC46E;
  stroke-width: 1px;
  width: 12px;
  height: 12px;
}
@media (max-width: 959px) {
  .snapshots ul li .remove-snapshot svg {
    width: 8px;
    height: 8px;
  }
}
.snapshots ul li .remove-snapshot:hover {
  color: #fff !important;
}
.snapshots ul li .remove-snapshot:hover svg {
  stroke: #fff;
}

.snapshot-error-message {
  color: #d33a61;
  margin: 10px 0;
}
/* Colors */
/* Fonts */
/* Dimensions */
.customer-list-item {
  height: 130px;
  margin-bottom: 4px;
  width: 100%;
  background: #2a3c48;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.2);
  transition: background-color 0.1s ease-out;
  will-change: background-color;
}
.customer-list-item:hover {
  background: #395263;
}
.customer-list-item__indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  line-height: 0;
  font-size: 0;
  border-radius: 100%;
  position: absolute;
  top: 20px;
  left: 7px;
}
.customer-list-item__indicator.regular {
  background: #3f809c;
}
.customer-list-item__indicator.paying {
  background: #2DC46E;
}
.customer-list-item__indicator.disabled {
  background: #d33a61;
}
.customer-list-item p {
  margin: 0 0 5px;
  font-size: 16px;
  line-height: 20px;
}
.customer-list-item p .label {
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.65);
}
.customer-list-item h4 {
  margin: 15px 0;
}
.customer-list-item__container {
  display: flex;
  padding: 15px 15px 15px 25px;
}
.customer-list-item__column {
  width: 33%;
  flex-grow: 1;
}
.customer-list-item__plan {
  font-weight: bold;
}
.customer-list-item__id, .customer-list-item__customer-type {
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  line-height: 20px;
}
.customer-list-item__name {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: #2DC46E;
  cursor: pointer;
}
.customer-list-item__buttons {
  width: 120px;
}
.customer-list-item__buttons .button {
  margin-top: 10px;
}
.customer-list-item__buttons .button:nth-child(1) {
  margin-top: 0;
}

.customer-list-item__loader .customer-list-item {
  opacity: 0.5;
  background: rgba(255, 255, 255, 0.1);
  animation: flickeringColor 0.5s infinite;
  height: 54px;
}
.customer-list-item__loader .customer-list-item__container {
  display: block;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
}

@keyframes flickeringColor {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.2);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
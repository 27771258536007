/* Colors */
/* Fonts */
/* Dimensions */
.settings .section-nav, .help .section-nav, .favorite .section-nav {
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 35px;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: #1B262E;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  box-shadow: 0 5px 12px -2px rgba(0, 0, 0, 0.6);
}
@media (max-width: 959px) {
  .settings .section-nav, .help .section-nav, .favorite .section-nav {
    font-size: 12px;
    margin: 0 0 -8px;
  }
}
.settings .section-nav .container, .help .section-nav .container, .favorite .section-nav .container {
  padding-top: 25px;
  padding-bottom: 0;
  display: flex;
}
@media (max-width: 959px) {
  .settings .section-nav .container, .help .section-nav .container, .favorite .section-nav .container {
    padding-top: 25px;
  }
}
.settings .section-nav a, .help .section-nav a, .favorite .section-nav a {
  color: rgba(255, 255, 255, 0.75);
  border-bottom: 2px solid transparent;
}
.settings .section-nav a:hover, .help .section-nav a:hover, .favorite .section-nav a:hover {
  color: #fff;
}
.settings .section-nav span, .help .section-nav span, .favorite .section-nav span {
  border-bottom: 2px solid rgba(255, 255, 255, 0.9);
  color: #fff;
  font-weight: 600;
}
.settings .section-nav span.locked, .help .section-nav span.locked, .favorite .section-nav span.locked {
  color: rgba(255, 255, 255, 0.45);
  font-weight: normal;
  border-bottom: 2px solid transparent;
  cursor: default;
}
.settings .section-nav span.locked svg, .help .section-nav span.locked svg, .favorite .section-nav span.locked svg {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 4px;
}
.settings .section-nav span, .settings .section-nav a, .help .section-nav span, .help .section-nav a, .favorite .section-nav span, .favorite .section-nav a {
  display: inline-block;
  width: 50%;
  padding: 10px 15px 20px;
  text-align: center;
  margin-bottom: -1px;
}
@media (max-width: 959px) {
  .settings .section-nav span, .settings .section-nav a, .help .section-nav span, .help .section-nav a, .favorite .section-nav span, .favorite .section-nav a {
    padding: 5px 8px;
  }
}

.favorite .section-nav {
  font-size: 14px;
  position: relative;
}
.favorite .section-nav .container {
  padding-top: 0;
}
.favorite .section-nav span, .favorite .section-nav a {
  flex-grow: 0;
  flex-shrink: 0;
  width: auto;
  padding: 16px 0;
  margin-right: 25px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.sub-nav {
  display: flex;
  height: 100%;
  background: #22323c;
}
.sub-nav .section-icon {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  left: -14px;
}
.sub-nav .section-icon__active {
  transform: rotate(90deg);
}
.sub-nav__navigation {
  width: 215px;
  overflow: auto;
  padding: 29px 25px 25px 30px;
  box-shadow: 0 4px 24px 2px rgba(0, 0, 0, 0.02);
  z-index: 1;
  height: 100%;
}
.sub-nav__navigation h2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 120%;
  text-transform: none;
  margin: 0 0 25px;
}
.sub-nav__navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sub-nav__navigation ul li {
  margin-top: 17px;
  font-size: 16px;
  line-height: 20px;
}
.sub-nav__navigation ul li a {
  position: relative;
  color: #CCC;
}
.sub-nav__navigation ul li a:hover {
  color: #2DC46E;
}
.sub-nav__navigation ul li a.active {
  color: #FFF;
  font-weight: bold;
}
.sub-nav__navigation ul li.deletable {
  padding-right: 20px;
  position: relative;
}
.sub-nav__navigation ul li .delete {
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 10px;
  margin-right: -10px;
  display: block;
}
.sub-nav__navigation ul li .delete:hover {
  color: #2DC46E;
}
.sub-nav__navigation ul li .delete svg {
  display: block;
  width: 10px;
  height: 10px;
}
.sub-nav__navigation ul li span {
  color: #FFF;
  font-weight: bold;
}
.sub-nav__second-level {
  padding-left: 14px;
}
.sub-nav__second-level ul li {
  margin-top: 12px;
  white-space: nowrap;
}
.sub-nav__content {
  width: 100%;
  overflow: auto;
  background: #263742;
  border-radius: 10px 0px 0px 10px;
  box-shadow: 0 4px 24px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;
}
/* Colors */
/* Fonts */
/* Dimensions */
.create-shortcut-form {
  max-width: 748px;
  width: 100%;
  min-width: 320px;
}
.create-shortcut-form label {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  color: #A4A6A4;
}
.create-shortcut-form .r-checkbox + label {
  display: inline-block;
  position: relative;
  top: -8px;
  margin-left: 7px;
}
.create-shortcut-form .checkbox-block {
  margin-top: 9px;
}
.create-shortcut-form .input:disabled {
  color: #637581;
  border-bottom-color: #637581;
}
.create-shortcut-form .row {
  margin-top: 24px;
}
.create-shortcut-form .buttons {
  padding-top: 20px;
  text-align: right;
}
.create-shortcut-form .buttons .btn {
  margin-left: 24px;
}
.create-shortcut-form .buttons .btn:nth-child(1) {
  margin-left: 0;
}
/* Colors */
/* Fonts */
/* Dimensions */
.auth-login {
  color: #1C2734;
  margin-top: 20px;
}
.auth-login input {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px !important;
  line-height: 24px !important;
  border: none;
  font-family: "IBM Plex Sans", sans-serif;
  color: #1C2734;
  background: #F1F4F6;
  border-radius: 4px;
}
.auth-login input:hover, .auth-login input:focus {
  background: #F1F4F6;
}
.auth-login input:-webkit-autofill, .auth-login input:-webkit-autofill:hover, .auth-login input:-webkit-autofill:focus, .auth-login input:-webkit-autofill, .auth-login input:-webkit-autofill::first-line {
  font-size: 16px;
  line-height: 24px;
  box-shadow: none;
  -webkit-text-fill-color: #1C2734;
  color: #1C2734;
  font-family: "IBM Plex Sans", sans-serif;
  background: #F1F4F6;
  border-radius: 4px;
}
.auth-login input::placeholder {
  color: #808080;
  font-size: 16px;
  opacity: 1;
}
.auth-login .btn {
  margin-top: 24px;
  color: #FFF;
  background: #35B374;
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
  padding: 12px;
  border: none;
  border-radius: 12px !important;
  text-transform: uppercase;
  cursor: pointer;
}
.auth-login .btn:hover {
  background-color: #1C2734;
}
.auth-login .error-message {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: -10px;
  color: red;
  display: none;
}
.auth-login .error-message.visible {
  display: block;
}
.auth-login .forgotpass {
  color: #1C2734;
  text-decoration: underline;
  text-align: left;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  line-height: 19px;
}
.auth-login .forgotpass:hover {
  color: #35B374;
}
.auth-login .signup-text {
  font-size: 14px;
  line-height: 19px;
  margin-top: 24px;
  text-align: center;
}
.auth-login .signup-link {
  color: #1C2734;
  text-decoration: underline;
}
.auth-login .signup-link:hover {
  color: #35B374;
}
.auth-login p {
  margin: 0 0 12px;
}
.auth-login p a {
  margin: 0;
}
.auth-login #Loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;
}
.auth-login #Loader .loader-image {
  width: 120px;
  height: 120px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -60px 0 0 -60px;
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 160px 0;
  }
}
.note-content .team-note {
  margin-top: 35px;
}
.note-content .team-name {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin: 0 0 10px;
}
.note-content .team-note-image {
  position: relative;
  top: -2px;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-position: center center;
  background-size: contain;
  vertical-align: middle;
  margin-right: 4px;
  background-repeat: no-repeat;
}
/* Colors */
/* Fonts */
/* Dimensions */
.billing-add-card .card-form:after {
  content: "";
  display: table;
  clear: both;
}

.billing-add-card h1 {
  margin-bottom: 4px;
}
.billing-add-card p {
  opacity: 0.7;
}
.billing-add-card .back-icon {
  position: relative;
  top: -1px;
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
}
.billing-add-card .card-form {
  width: 520px;
  margin: 25px 0 0;
  background: #f7f9fc;
  border-radius: 5px;
  padding: 25px;
  display: block;
}
.billing-add-card .card-form .btn:hover {
  background: #2DC46E;
  color: #FFF;
}
.billing-add-card .card-form .card-form-body {
  margin: 0 0 20px 0;
}
.billing-add-card .card-form .card-form-body .stripe-body > div > span, .billing-add-card .card-form .card-form-body .stripe-body > span, .billing-add-card .card-form .card-form-body > span {
  font-size: 14px;
  color: black;
  margin-left: 12px;
}
.billing-add-card .card-form .card-form-body input,
.billing-add-card .card-form .card-form-body .StripeElement {
  display: block;
  color: "#424770";
  margin: 5px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 16px;
  font-family: "Source Sans Pro", Helvetica, sans-seriface;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  min-height: 39px;
  color: #151C21;
}
.billing-add-card .card-form .card-form-body input.is-invalid,
.billing-add-card .card-form .card-form-body .StripeElement.is-invalid {
  color: #eb1c26;
}
.billing-add-card .card-form .card-form-body input::placeholder {
  color: #aab7c4;
}
.billing-add-card .card-form .card-form-body input:focus,
.billing-add-card .card-form .card-form-body .StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}
.billing-add-card .card-form p {
  margin: 20px 0;
  color: black;
  opacity: 0.5;
  font-size: 14px;
}
.billing-add-card .card-form > a {
  margin: 30px;
  color: black;
  opacity: 0.4;
}
.billing-add-card .card-form p.next-payment {
  font-weight: bold;
  text-transform: uppercase;
  color: #1E2A31 !important;
}
.billing-add-card .card-current {
  margin: 25px 0 0 0;
  width: 480px;
}
.billing-add-card .card-current p {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 10px;
}
.billing-add-card .card-current table {
  margin: 10px 0 0;
  border: none;
  width: 100%;
}
.billing-add-card .card-current table td {
  border: none;
  padding: 0;
}
.billing-add-card .add-card-error {
  color: #d33a61 !important;
  opacity: 1 !important;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  margin: -20px 0 20px 0;
}
.billing-add-card .sepa-mandate {
  color: #151C21;
  font-size: 11px;
  line-height: 18px;
  margin: 20px 0;
}
.billing-add-card .method-selector li {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  margin-top: 15px;
  cursor: pointer;
  display: inline-block;
  margin-right: 25px;
}
.billing-add-card .method-selector li.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.billing-add-card .method-selector li .radio {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #FFF;
  margin-right: 6px;
  border-radius: 100%;
  vertical-align: middle;
}
.billing-add-card .method-selector li .radio.checked {
  border-color: #2DC46E;
}
.billing-add-card .method-selector li .radio.checked:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  margin: 3px;
  border-radius: 100%;
  background: #2DC46E;
}
.billing-add-card .method-selector li.checked {
  font-weight: bold;
  color: #2DC46E;
}
/* Colors */
/* Fonts */
/* Dimensions */
.plan-selector-close {
  width: 25px;
  height: 25px;
  color: #2DC46E;
  position: absolute;
  right: 25px;
  top: 25px;
}
.plan-selector-close svg {
  width: 25px;
  height: 25px;
}

.plan-selector {
  display: flex;
  margin-top: 30px;
}
@media (max-width: 959px) {
  .plan-selector {
    flex-wrap: wrap;
  }
}
.plan-selector .plan {
  width: 33.3%;
  margin-left: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background: #2d424f;
  padding-bottom: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
@media (max-width: 959px) {
  .plan-selector .plan {
    width: 100%;
    margin-left: 0;
    margin-bottom: 35px;
  }
}
.plan-selector .plan:nth-child(1) {
  margin-left: 0;
  border-radius: 12px 0 0 12px;
}
.plan-selector .plan:nth-child(1).single {
  border-radius: 12px;
  min-width: 300px;
}
.plan-selector .plan:nth-child(2) {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 6px 20px rgba(0, 0, 0, 0.15);
  z-index: 3;
  margin-top: -20px;
  margin-bottom: -20px;
}
.plan-selector .plan:nth-child(2) .plan-head {
  padding-top: 45px !important;
}
@media (max-width: 959px) {
  .plan-selector .plan:nth-child(2) {
    width: 100%;
    margin: 0 0 35px;
  }
}
.plan-selector .plan:nth-child(3) {
  border-radius: 0 12px 12px 0;
}
.plan-selector .plan:only-child {
  border-radius: 12px 12px;
}
.plan-selector .plan .plan-head {
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  margin: 5px 0px 0px;
  padding: 25px 32px 20px;
}
@media (max-width: 959px) {
  .plan-selector .plan .plan-head {
    font-size: 12px;
    line-height: 18px;
  }
}
.plan-selector .plan .plan-head h4 {
  font-size: 24px;
  font-weight: bold;
  display: block;
  line-height: 30px;
}
@media (max-width: 959px) {
  .plan-selector .plan .plan-head h4 {
    font-size: 14px;
    line-height: 18px;
  }
}
.plan-selector .plan .plan-head h5 {
  display: block;
  font-size: 15px;
  opacity: 0.5;
}
@media (max-width: 959px) {
  .plan-selector .plan .plan-head h5 {
    font-size: 12px;
    line-height: 14px;
  }
}
.plan-selector .plan .body {
  margin: 0px;
  padding-bottom: 15px;
  flex-grow: 1;
}
.plan-selector .plan .body p {
  font-size: 16px;
  line-height: 20px;
  padding: 6px 40px;
}
.plan-selector .plan .body p:nth-child(odd) {
  background: #2c3f4c;
}
@media (max-width: 959px) {
  .plan-selector .plan .body p {
    font-size: 12px;
    line-height: 14px;
  }
}
.plan-selector .plan .body p:nth-child(1) {
  margin-top: 0;
}
.plan-selector .plan .payment-next {
  padding: 0px 40px 10px;
}
@media (max-width: 959px) {
  .plan-selector .plan .payment-next {
    font-size: 12px;
    line-height: 14px;
  }
}
.plan-selector .plan .price {
  text-align: center;
  color: #ccc;
}
.plan-selector .plan .price span {
  font-size: 38px;
  font-weight: bold;
  color: #fff;
}
.plan-selector .plan .cta {
  margin-top: 15px;
  text-align: center;
}
.plan-selector .plan .cta .btn {
  padding: 0 40px;
  border-radius: 4px;
}
@media (max-width: 959px) {
  .plan-selector .plan .cta .btn {
    font-size: 12px;
    height: 38px;
    line-height: 38px;
    padding: 0 20px;
  }
}
.plan-selector .plan .cta .current-plan {
  display: block;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
  letter-spacing: 0.5px;
  padding: 12px 10px;
}
.plan-selector .plan .cta .next-plan {
  display: block;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
  padding: 12px 0;
}
.plan-selector .trial-information {
  margin-left: 25px;
  margin-top: 15px;
}
.plan-selector__downgrade {
  margin: 80px 0 0;
}
.plan-selector__downgrade span {
  cursor: pointer;
  color: #2DC46E;
  border-bottom: 1px dotted;
  padding: 2px 0px;
  margin: 0px 10px;
}

.plan-comparison {
  margin-top: 25px;
}
.plan-comparison ul li span {
  display: inline-block;
  margin-left: 4px;
  font-weight: bold;
  vertical-align: middle;
}
.plan-comparison ul li span.type {
  font-weight: normal;
}
/* Colors */
/* Fonts */
/* Dimensions */
.styled-react-select {
  font-size: 14px;
}
.styled-react-select.Select--single .Select-control {
  height: auto;
  border: none;
  background: transparent;
  border-bottom: 1px solid blue !important;
}
.styled-react-select.Select--single .Select-control input {
  border-bottom: none;
  padding-top: 3px;
}
.styled-react-select.Select--single .Select-control .Select-value {
  line-height: 26px;
  left: 0;
}
.styled-react-select.Select--single .Select-control .Select-value .Select-value-label {
  padding: 0;
}
.styled-react-select.Select--single .Select-control .Select-input {
  height: 22px;
  padding-left: 0;
}
.styled-react-select.Select--single .Select-control .Select-input input {
  padding-left: 0;
  background: transparent;
}
.styled-react-select.has-value.Select--single .Select-control .Select-value .Select-value-label, .styled-react-select.is-focused.Select--single .Select-control .Select-value .Select-value-label, .styled-react-select.has-value.is-pseudo-focused.Select--single .Select-control .Select-value .Select-value-label {
  padding: 0 !important;
}
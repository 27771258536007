/* Colors */
/* Fonts */
/* Dimensions */
.player-view {
  display: flex;
}
.player-view__img {
  width: 25px;
  height: 25px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
}
.player-view__team-name {
  font-size: 12px;
  opacity: 0.7;
  display: block;
}
.player-view__team-img {
  position: relative;
  top: 3px;
  display: inline-block;
  margin-right: 4px;
  width: 12px;
  height: 16px;
  background-size: contain;
}
.clearfix:before,
.clearfix:after  {
	content: " ";
	display: table;
}
.clearfix:after  {
	clear: both;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}
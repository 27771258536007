/* Colors */
/* Fonts */
/* Dimensions */
.single-report__player {
  margin: 15px 0 28px;
  padding: 24px 0;
  background: #32414F;
  display: flex;
  position: relative;
}
.single-report__player .player-information__edit-buttons {
  top: -30px;
}
.single-report__player__info, .single-report__player__team {
  padding: 0 24px;
}
.single-report__player__info, .single-report__player__team {
  border-right: 1px solid #354C5C;
  width: calc(50% - 75px);
}
.single-report__player__position {
  padding-left: 24px;
  position: relative;
  width: 124px;
}
.single-report__player__position .note-button {
  border: none;
  background: #35B374;
  position: absolute;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  top: 100%;
  right: 0;
  color: #FFF;
  margin-top: 4px;
  transition: background 0.35s ease-out, color 0.35s ease-out;
}
.single-report__player__position .note-button svg {
  position: absolute;
  left: 18px;
  top: 18px;
  width: 24px;
  height: 24px;
  fill: currentColor;
}
.single-report__player__position .note-button:hover, .single-report__player__position .note-button:active {
  background: #FFF;
  color: #35B374;
}
@media (max-width: 959px) {
  .single-report__player {
    padding: 16px 0;
    min-height: 160px;
  }
  .single-report__player__info, .single-report__player__team {
    padding: 0 16px;
    width: calc(50% - 67px);
  }
  .single-report__player__info h4, .single-report__player__team h4 {
    display: none;
  }
  .single-report__player__info .player-information__main .player-information__edit-buttons {
    top: -15px;
  }
  .single-report__player__info .player-information__main .player-information__edit-buttons + .content-row {
    margin-top: 0;
  }
  .single-report__player__info .player-information__main .player-data__image {
    width: 36px;
    height: 36px;
    margin-right: 12px;
  }
  .single-report__player__info .player-information__main .player-data__image .player-image {
    border-radius: 18px;
    width: 36px;
    height: 36px;
    background-color: #19242b;
  }
  .single-report__player__info .player-information__main .player-data__firstName {
    font-size: 14px;
    line-height: 18px;
  }
  .single-report__player__info .player-information__main .player-data__lastName {
    font-size: 24px;
    line-height: 28px;
  }
  .single-report__player__position {
    padding-left: 16px;
    position: relative;
    width: 102px;
  }
}
/* Colors */
/* Fonts */
/* Dimensions */
.invite-item {
  display: flex;
  padding: 15px 0;
  margin-top: 2px;
  background: #2D3B46;
}
.invite-item__col {
  padding: 0 16px;
  font-size: 16px;
  line-height: 22px;
  text-overflow: ellipsis;
}
.invite-item__status {
  width: 56px;
}
.invite-item__status svg {
  fill: #FFF;
  width: 24px;
  height: 24px;
}
.invite-item__email {
  width: calc(100% - 342px);
  text-overflow: ellipsis;
  overflow: hidden;
}
.invite-item__message {
  width: 172px;
}
.invite-item__profit {
  width: 68px;
  white-space: nowrap;
}
.invite-item__control {
  width: 46px;
}
.invite-item__control svg {
  width: 14px;
  height: 12px;
}
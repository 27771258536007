/* Colors */
/* Fonts */
/* Dimensions */
.player-information__edit-buttons {
  z-index: 2;
  position: absolute;
  right: 20px;
  top: -10px;
}
.player-information__edit-button {
  margin-left: 10px;
  border: none;
  background: none;
  padding: 4px;
}
.player-information__edit-button svg {
  width: 12px;
  height: 12px;
  position: relative;
  top: 1px;
  margin-right: 4px;
}
.player-information__edit-button.cancel {
  color: #d33a61;
}
.player-information__edit-button.save, .player-information__edit-button.edit {
  color: #35B374;
}
.player-information__main {
  display: block;
  position: relative;
  width: 100%;
}
.player-information__main .data-item-changeable.edit-mode {
  margin-left: 20px;
  /*@media (max-width: $screen-sm-max) {
    margin-left: 18px;
  }*/
}
.player-information__main .data-item-changeable.edit-mode:nth-child(1) {
  margin-left: 0;
}
.player-information__main h4 {
  font-size: 18px;
  line-height: 36px;
  color: #FFF;
}
.player-information .player-data {
  display: flex;
}
.player-information .player-data__image {
  width: 72px;
  height: 72px;
  margin-right: 24px;
}
.player-information .player-data__image .player-image {
  border-radius: 36px;
  width: 72px;
  height: 72px;
  background-size: cover;
  background-position: center center;
  background-color: #19242b;
}
.player-information .player-data__content {
  flex-grow: 1;
}
.player-information .player-data__content .data-item, .player-information .player-data__content .data-item-icon, .player-information .player-data__content .data-item-changeable {
  flex-grow: 1;
  max-width: 50%;
}
.player-information .player-data__content .data-item .select_input, .player-information .player-data__content .data-item-icon .select_input, .player-information .player-data__content .data-item-changeable .select_input {
  color: #FFF;
}
.player-information .player-data__firstName {
  font-size: 18px;
  line-height: 23px;
}
.player-information .player-data__lastName {
  font-size: 36px;
  line-height: 40px;
  font-weight: bold;
}
.player-information .player-data .data-row {
  display: flex;
  margin-top: 25px;
}
.player-information .player-data .team-row {
  margin-top: 45px;
}
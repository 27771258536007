/* Colors */
/* Fonts */
/* Dimensions */
.notes {
  position: absolute;
  right: 0;
  left: 0;
}
.notes .note {
  position: relative;
  height: 50%;
}
.notes .team {
  color: #fff;
  line-height: 37px;
  padding: 6px 0 0 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  font-size: 15px;
  margin: 0 15px;
}
@media (max-width: 959px) {
  .notes .team {
    line-height: 24px;
    padding: 3px 0 0 16px;
    font-size: 12px;
    margin: 0 10px;
  }
}
.notes .team .team-image {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -6px;
  width: 17px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (max-width: 959px) {
  .notes .team .team-image {
    margin-top: -5px;
    width: 12px;
    height: 14px;
  }
}
.notes .teamnotes {
  position: absolute;
  top: 46px;
  right: 0;
  bottom: 0;
  left: 0;
}
@media (max-width: 959px) {
  .notes .teamnotes {
    top: 27px;
  }
}
.notes .teamnotes textarea {
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
  padding: 13px 16px;
  color: #fff;
  font-size: 15px;
  line-height: 20px;
  background: #2c404f;
  border-radius: 4px;
}
@media (max-width: 959px) {
  .notes .teamnotes textarea {
    padding: 10px;
    font-size: 12px;
    line-height: 16px;
  }
}
.notes .teamnotes textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
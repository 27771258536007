.demo-badge {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 48px;
  height: 48px;
  z-index: 2;
}
.demo-badge svg {
  width: 48px;
  height: 48px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.c-checkbox {
  width: 16px;
  height: 16px;
  display: inline-block;
  border: 1px solid #2DC46E;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}
.c-checkbox .checkbox-mark {
  fill: #FFF;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  margin-top: -6px;
  left: 50%;
  margin-left: -6px;
}
.c-checkbox.checked {
  background: #2DC46E;
}

.r-checkbox {
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 40px;
  height: 24px;
  color: #A4A6A4;
  border: 1px solid;
  border-radius: 12px;
  transition-property: color;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}
.r-checkbox .round-checkmark {
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  transform: translateX(3px);
  top: 3px;
  background-color: #A4A6A4;
  border-radius: 8px;
  transition-property: transform, background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}
.r-checkbox.checked {
  color: #35B374;
}
.r-checkbox.checked .round-checkmark {
  transform: translateX(19px);
  background-color: #35B374;
}
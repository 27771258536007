/* Colors */
/* Fonts */
/* Dimensions */
.shadow-team__players-list {
  transition-property: margin-bottom;
  transition-timing-function: ease-out;
  transition-duration: 350ms;
}
.shadow-team__players-list .player-item {
  padding: 6px 5px 6px 6px;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  background: #2F3C45;
  cursor: pointer;
  border-top: 1px solid #315065;
  position: relative;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.35);
}
.shadow-team__players-list .player-item .player-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shadow-team__players-list .player-item .player-name.with-rating {
  margin-right: 16px;
}
.shadow-team__players-list .player-item .final-rating {
  position: absolute;
  font-size: 10px;
  font-weight: bold;
  right: 0;
  top: 0;
  bottom: 0;
  width: 25px;
  text-align: center;
  color: #FFF;
  line-height: 34px;
}
.shadow-team__players-list .player-item .team-image {
  width: 17px;
  height: 22px;
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  background-size: contain;
}
.shadow-team__players-list .player-item.first {
  position: relative;
  border-radius: 2px;
  margin-left: -2px;
  margin-right: -2px;
  z-index: 2;
  background: #942845;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  border-top: none;
}
.shadow-team__players-list .player-item.active {
  background: #252f36;
}
.shadow-team__players-list .player-item.active.first {
  background: #80233c;
}
.shadow-team__players-list .player-item:hover {
  background: #252f36;
}
.shadow-team__players-list .player-item:hover.first {
  background: #80233c;
}
.shadow-team__players-list .player-item.first + .player-item {
  border-top: none;
}
.shadow-team__show-more-button {
  background: #2F3C45;
  color: #FFF;
  border: none;
  border-radius: 100%;
  display: block;
  margin: -24px 0px -26px;
  width: 41px;
  height: 50px;
  line-height: 14px;
  text-align: center;
  padding: 0;
}
.shadow-team__show-more-button svg {
  width: 20px !important;
  height: 20px !important;
  left: 0 !important;
  top: 8px !important;
  position: relative !important;
  fill: currentColor !important;
}
.shadow-team__show-more-button.isOpen svg {
  transform: rotate(-180deg);
}
.shadow-team__show-more-button:hover {
  color: #2DC46E;
}
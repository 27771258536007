.shadow-team__position {
  position: absolute;
  width: 36px;
  height: 36px;
  margin-left: -18px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 2;
}
.shadow-team__position.active {
  z-index: 4;
}
.shadow-team__position .position-mark {
  position: absolute;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  z-index: 5;
}
.shadow-team__position .position-mark:hover {
  background: rgba(255, 255, 255, 0.5);
}
.shadow-team__position .position-mark:hover svg {
  fill: white;
}
.shadow-team__position .position-mark__empty {
  width: 38px;
  height: 38px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}
.shadow-team__position svg {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 11px;
  top: 11px;
  fill: #a8d1b7;
  pointer-events: none;
}
.shadow-team__players {
  width: 121px;
  margin-left: -41px;
  position: absolute;
  bottom: 100%;
  /*margin-bottom: 2px;*/
}
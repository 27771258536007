/* Colors */
/* Fonts */
/* Dimensions */
.narrow-container {
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
}

.earn h4 {
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
  margin: 0 0 8px;
}
.earn__status {
  display: flex;
  max-width: 550px;
  align-items: center;
  margin-top: 24px;
}
.earn__status-description {
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(100% - 279px);
  margin-right: 24px;
  font-size: 13px;
  line-height: 16px;
}
.earn__status-chart {
  flex-shrink: 0;
  flex-grow: 0;
  width: 255px;
  padding: 24px;
}
.earn__status-chart .CircularProgressbar-text {
  font-size: 13px;
  line-height: 16px;
  color: #FFF;
  text-align: center;
}
.earn__status-chart .CircularProgressbar-text .value, .earn__status-chart .CircularProgressbar-text .of-value {
  display: block;
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
}
.earn__status-chart .CircularProgressbar-text .value {
  color: #2DC46E;
}
.earn__status-chart .btn {
  font-size: 13px;
  white-space: nowrap;
  margin-top: 24px;
}
.earn__btn-loader {
  text-align: center;
  margin-top: 24px;
  max-height: 46px;
}
.earn__btn-loader .inline-loader-container {
  transform: translate(0, -1em) scale(0.5, 0.5);
}
.earn__invite-list {
  margin-top: 58px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.favorites-list__wrapper .recent-list {
  min-width: 950px;
}
@media screen and (max-width: 959px) {
  .favorites-list__wrapper .recent-list {
    min-width: 900px;
  }
}
.favorites-list__wrapper .recent-list th, .favorites-list__wrapper .recent-list td {
  padding-top: 12px !important;
  padding-bottom: 15px !important;
}
.favorites-list__wrapper .recent-list th .recent-player-name, .favorites-list__wrapper .recent-list td .recent-player-name {
  padding-left: 46px;
  position: relative;
  min-height: 38px;
  font-size: 16px;
  line-height: 19px;
}
.favorites-list__wrapper .recent-list th .recent-player-name .favorite-player-name_last, .favorites-list__wrapper .recent-list td .recent-player-name .favorite-player-name_last {
  display: block;
  font-weight: bold;
}
.favorites-list__wrapper .recent-list th .player-image, .favorites-list__wrapper .recent-list td .player-image {
  width: 36px;
  height: 44px;
  margin-top: -23px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 0;
}
@media (max-width: 959px) {
  .favorites-list__wrapper .recent-list th .player-image, .favorites-list__wrapper .recent-list td .player-image {
    width: 24px;
    height: 28px;
    margin-top: -15px;
  }
}
.favorites-list__wrapper .recent-list th.col__last-name, .favorites-list__wrapper .recent-list td.col__last-name {
  padding-left: 16px !important;
}
.favorites-list__wrapper .recent-list th.col__updated-at, .favorites-list__wrapper .recent-list td.col__updated-at {
  width: 110px;
}
@media screen and (max-width: 959px) {
  .favorites-list__wrapper .recent-list th.col__updated-at, .favorites-list__wrapper .recent-list td.col__updated-at {
    width: 80px;
  }
}
.favorites-list__wrapper .recent-list th.col__sort, .favorites-list__wrapper .recent-list td.col__sort {
  width: 45px;
}
.favorites-list__wrapper .recent-list th.col__fav, .favorites-list__wrapper .recent-list td.col__fav {
  width: 40px;
}
.favorites-list__wrapper .recent-list th.col__rating, .favorites-list__wrapper .recent-list td.col__rating {
  width: 145px;
}
.favorites-list__wrapper .recent-list th.col__birth-date, .favorites-list__wrapper .recent-list td.col__birth-date {
  width: 40px;
}
.favorites-list__wrapper .recent-list th.col__notes, .favorites-list__wrapper .recent-list td.col__notes {
  width: 95px;
}
.favorites-list__wrapper .recent-list th.col__evaluations, .favorites-list__wrapper .recent-list td.col__evaluations {
  width: 95px;
}
.favorites-list__wrapper .recent-list th.col__country, .favorites-list__wrapper .recent-list td.col__country {
  width: 90px;
}
.favorites-list__wrapper .recent-list th {
  cursor: default !important;
}
.favorites-list__wrapper .recent-list th:hover {
  opacity: 0.6 !important;
}
/* Colors */
/* Fonts */
/* Dimensions */
.watchlist__content {
  display: flex;
  padding: 30px 0 0;
}
.watchlist__heading-top {
  display: flex;
  align-content: center;
}
.watchlist__heading {
  padding-left: 96px;
  position: relative;
  margin-bottom: 40px;
}
.watchlist__heading .watchlist-icon {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -40px;
}
.watchlist__heading h2 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}
.watchlist__heading .edit {
  line-height: 41px;
  margin-left: 38px;
  font-size: 13px;
  text-transform: uppercase;
  color: #2DC46E;
  cursor: pointer;
}
.watchlist__heading .edit:hover {
  color: #FFF;
}
.watchlist__heading .edit .edit-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 4px;
}
.watchlist__heading .add-new-player {
  position: relative;
  margin-left: auto;
}
.watchlist__heading .add-new-player .create-icon {
  width: 11px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: 6px;
}
.watchlist__description {
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 20px;
  max-width: 320px;
}
.watchlist .empty-message {
  color: #CCC;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  margin: 96px 0;
  width: 100%;
}
/* Colors */
/* Fonts */
/* Dimensions */
.pdf-header {
  padding: 0 0 42px;
}
.pdf-header.small {
  padding-bottom: 0;
  margin-top: 26px;
  height: 48px;
  width: 100%;
}
@media screen and (max-width: 959px) {
  .pdf-header {
    padding: 0 0 28px;
  }
  .pdf-header.small-header {
    padding-bottom: 0;
  }
}
.pdf-header .match-info {
  text-align: center;
}
.pdf-header .match-info .match-info-teams {
  display: flex;
}
.pdf-header .match-info .match-info-teams .home-team,
.pdf-header .match-info .match-info-teams .away-team {
  font-size: 26px;
  line-height: 31px;
  font-weight: bold;
  vertical-align: middle;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.small-header .pdf-header .match-info .match-info-teams .home-team,
.small-header .pdf-header .match-info .match-info-teams .away-team {
  font-size: 19px;
  line-height: 20px;
}
@media screen and (max-width: 959px) {
  .pdf-header .match-info .match-info-teams .home-team,
  .pdf-header .match-info .match-info-teams .away-team {
    font-size: 18px;
    line-height: 21px;
  }
  .small-header .pdf-header .match-info .match-info-teams .home-team,
  .small-header .pdf-header .match-info .match-info-teams .away-team {
    font-size: 12px;
    line-height: 14px;
  }
}
.pdf-header .match-info .match-info-teams .home-team .team-logo,
.pdf-header .match-info .match-info-teams .away-team .team-logo {
  position: absolute;
  top: 50%;
  width: 54px;
  height: 68px;
  margin-top: -34px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.small-header .pdf-header .match-info .match-info-teams .home-team .team-logo,
.small-header .pdf-header .match-info .match-info-teams .away-team .team-logo {
  width: 48px;
  height: 58px;
  margin-top: -29px;
}
@media screen and (max-width: 959px) {
  .pdf-header .match-info .match-info-teams .home-team .team-logo,
  .pdf-header .match-info .match-info-teams .away-team .team-logo {
    width: 36px;
    height: 46px;
    margin-top: -23px;
  }
  .small-header .pdf-header .match-info .match-info-teams .home-team .team-logo,
  .small-header .pdf-header .match-info .match-info-teams .away-team .team-logo {
    width: 28px;
    height: 36px;
    margin-top: -18px;
  }
}
.pdf-header .match-info .match-info-teams .home-team {
  align-items: flex-end;
  padding-right: 68px;
}
.small-header .pdf-header .match-info .match-info-teams .home-team {
  padding-right: 56px;
}
@media screen and (max-width: 959px) {
  .pdf-header .match-info .match-info-teams .home-team {
    padding-right: 45px;
  }
  .small-header .pdf-header .match-info .match-info-teams .home-team {
    padding-right: 34px;
  }
}
.pdf-header .match-info .match-info-teams .home-team .team-logo {
  right: 0;
}
.pdf-header .match-info .match-info-teams .away-team {
  text-align: left;
  padding-left: 68px;
}
.small-header .pdf-header .match-info .match-info-teams .away-team {
  padding-left: 56px;
}
@media screen and (max-width: 959px) {
  .pdf-header .match-info .match-info-teams .away-team {
    padding-left: 45px;
  }
  .small-header .pdf-header .match-info .match-info-teams .away-team {
    padding-right: 34px;
  }
}
.pdf-header .match-info .match-info-teams .away-team .team-logo {
  left: 0;
}
.pdf-header .match-info .match-info-teams .match-result {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0 18px;
}
.small-header .pdf-header .match-info .match-info-teams .match-result {
  margin: 0;
}
@media screen and (max-width: 959px) {
  .pdf-header .match-info .match-info-teams .match-result {
    margin: 0 12px;
  }
  .small-header .pdf-header .match-info .match-info-teams .match-result {
    margin: 0;
  }
}
.pdf-header .match-info .match-info-teams .match-result .min,
.pdf-header .match-info .match-info-teams .match-result .second-score {
  position: absolute;
  right: 0;
  left: 0;
  display: inline;
}
.pdf-header .match-info .match-info-teams .match-result .min {
  font-size: 10px;
  font-weight: 400;
  color: #4B4B4B;
  top: -5px;
}
@media screen and (max-width: 959px) {
  .pdf-header .match-info .match-info-teams .match-result .min {
    font-size: 7px;
    top: -4px;
  }
}
.pdf-header .match-info .match-info-teams .match-result .second-score {
  font-size: 16px;
  font-weight: 500;
  color: #B1B1B1;
  bottom: -12px;
  line-height: 21px;
}
.small-header .pdf-header .match-info .match-info-teams .match-result .second-score {
  font-size: 12px;
  line-height: 14px;
  color: #637581;
  bottom: -2px;
}
@media screen and (max-width: 959px) {
  .pdf-header .match-info .match-info-teams .match-result .second-score {
    font-size: 12px;
    bottom: -8px;
    line-height: 14px;
  }
}
.pdf-header .match-info .match-info-teams .match-result .second-score textarea {
  display: inline-block;
  text-align: center;
  height: 21px;
  width: 10px;
  overflow: hidden;
  resize: none;
  border: none;
  vertical-align: bottom;
  padding: 0;
  line-height: 21px;
}
.small-header .pdf-header .match-info .match-info-teams .match-result .second-score textarea {
  font-size: 12px;
  line-height: 14px;
  color: #637581;
}
@media screen and (max-width: 959px) {
  .pdf-header .match-info .match-info-teams .match-result .second-score textarea {
    height: 14px;
    width: 7px;
    line-height: 14px;
  }
}
.pdf-header .match-info .match-info-teams .match-result .score {
  font-weight: 500;
  font-size: 42px;
  color: #4B4B4B;
  line-height: 54px;
  display: flex;
}
.small-header .pdf-header .match-info .match-info-teams .match-result .score {
  font-size: 19px;
  line-height: 29px;
  color: #181C20;
  font-weight: bold;
}
@media screen and (max-width: 959px) {
  .pdf-header .match-info .match-info-teams .match-result .score {
    font-size: 28px;
    line-height: 36px;
  }
  .small-header .pdf-header .match-info .match-info-teams .match-result .score {
    font-size: 13px;
    line-height: 17px;
  }
}
.pdf-header .match-info .match-info-teams .match-result .score textarea {
  display: inline-block;
  text-align: center;
  height: 54px;
  width: 24px;
  overflow: hidden;
  resize: none;
  border: none;
  vertical-align: bottom;
  padding: 0;
  line-height: 54px;
}
.small-header .pdf-header .match-info .match-info-teams .match-result .score textarea {
  line-height: 29px;
}
@media screen and (max-width: 959px) {
  .pdf-header .match-info .match-info-teams .match-result .score textarea {
    height: 36px;
    width: 16px;
    line-height: 36px;
  }
  .small-header .pdf-header .match-info .match-info-teams .match-result .score textarea {
    line-height: 18px;
  }
}
.pdf-header .match-info .match-info-other {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 16px;
}
@media screen and (max-width: 959px) {
  .pdf-header .match-info .match-info-other {
    font-size: 10px;
    margin-top: 10px;
  }
}
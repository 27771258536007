/* Colors */
/* Fonts */
/* Dimensions */
.favorites-wrapper {
  height: 100%;
}
.favorites-wrapper .sub-nav__navigation {
  overflow: hidden;
  padding: 0;
}
.favorites-wrapper .sub-nav__navigation .pad {
  padding: 25px 22px 25px 23px;
}
.favorites-wrapper .sub-nav__navigation h2 {
  margin-bottom: 20px;
}
.favorites-wrapper .sub-nav__navigation ul li {
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 5px;
}
.favorites-wrapper .sub-nav__navigation .nav-link {
  margin-bottom: 5px;
  font-size: 17px;
  line-height: 21px;
}
.favorites-wrapper .sub-nav__navigation .divider {
  height: 0;
  overflow: hidden;
  border-bottom: 1px solid #334A59;
}
.favorites-wrapper .sub-nav__navigation a {
  cursor: pointer;
}
.favorites-wrapper .sub-nav__navigation .active {
  color: #FFF;
  pointer: default;
  font-weight: bold;
}
.favorites-wrapper .sub-nav__navigation.watchlists h2 {
  font-size: 22px;
  line-height: 28px;
  font-weight: bold;
}
.favorites-wrapper__content {
  overflow: hidden;
}
.rc-slider {
  position: relative;
  padding: 11px 0;
}
.rc-slider .rc-slider-rail {
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  border-radius: 2px;
}
.rc-slider .rc-slider-track {
  background: #2DC46E;
  height: 4px;
  border-radius: 2px;
  position: absolute;
  top: 11px;
}
.rc-slider .rc-slider-handle {
  position: absolute;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  background: #3A4C5A;
  border: 1px solid #2DC46E;
  width: 26px;
  height: 26px;
  margin-top: -15px;
  margin-left: -13px;
}
.rc-slider .handle-value {
  position: absolute;
  top: -25px;
  left: 13px;
  padding: 2px 6px;
  transform: translate(-50%, 0);
  pointer-events: none;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  color: #2DC46E;
  font-size: 0.8rem;
  font-family: "Helvetica Neue", san-serif;
  white-space: nowrap;
}
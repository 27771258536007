/* Colors */
/* Fonts */
/* Dimensions */
.sp-modal {
  margin: 0 auto;
  background: #2E3C47;
  max-height: 100vh;
  padding: 24px;
}
.sp-modal__heading {
  display: flex;
  margin-bottom: 16px;
}
.sp-modal__name {
  flex-grow: 2;
  font-size: 13px;
  text-transform: uppercase;
}
.sp-modal__name svg {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  position: relative;
  top: -2px;
}
.sp-modal__close {
  display: block;
  border: none;
  background: none;
  padding: 8px;
}
.sp-modal__close:hover {
  color: #2DC46E;
}
.sp-modal__close svg {
  display: block;
  width: 8px;
  height: 8px;
}
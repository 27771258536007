/* Colors */
/* Fonts */
/* Dimensions */
.pitch {
  padding: 20px 0 20px 20px;
  text-align: center;
  margin-right: 50px;
  height: 100%;
  position: relative;
  font-size: 0;
  -webkit-transition: margin 0.5s ease-out;
  -moz-transition: margin 0.5s ease-out;
  transition: margin 0.5s ease-out;
  z-index: 1;
}
@media (max-width: 959px) {
  .pitch {
    padding: 10px 0 10px 10px;
    margin-left: 27px;
    margin-right: 25px;
  }
}
.pitch:before {
  box-shadow: 0 0 8px rgba(21, 28, 33, 0.3);
  z-index: 1;
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  right: -50px;
  bottom: 0;
  left: 0;
}
.sidebar--visible .pitch {
  margin-left: 285px;
}
@media (max-width: 959px) {
  .sidebar--visible .pitch {
    margin-left: 170px;
  }
}
/* Colors */
/* Fonts */
/* Dimensions */
.watchlists .container {
  padding-left: 36px;
  padding-right: 36px;
}
.watchlists__heading h3 {
  vertical-align: middle;
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
}
.watchlists .demo-watchlists-list {
  margin-top: 24px;
}
.watchlists .demo-message {
  top: 60px;
  left: 140px;
}
.watchlists .empty-message {
  letter-spacing: 0.2px;
  font-size: 18px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.75);
  margin: 25px 0;
  width: 50%;
  text-align: left;
  opacity: 0.8;
  font-weight: 400;
}
.watchlists__controls {
  display: flex;
  position: relative;
  z-index: 5;
}
.watchlists__create {
  margin-left: 25px;
}
.watchlists__filters {
  max-width: 320px;
  margin-right: 25px;
}
.watchlists .create-team {
  margin: 40px 0 10px 0;
  max-width: 360px;
  white-space: nowrap;
  /*    &:hover{
        color: $color_GreenAction;
      }*/
}
@media (max-width: 959px) {
  .watchlists .create-team {
    width: 300px;
    font-size: 13px;
  }
}
.watchlists .create-team .create-icon {
  width: 11px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: 6px;
  margin-left: 6px;
}
.watchlists .group-check {
  margin: 25px 0;
  color: #2DC46E;
  position: relative;
  padding-top: 8px;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}
.watchlists .group-check .checkbox {
  border-color: #2DC46E;
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  margin-right: 8px;
  position: relative;
  top: -1px;
}
.watchlists .group-check .checkbox svg {
  width: 16px;
  height: 16px;
  top: 2px;
  left: 2px;
  position: absolute;
  stroke: #2DC46E;
}
.watchlists .group-check label {
  cursor: pointer;
}
.watchlists .group-check label.checked {
  opacity: 1;
}
.watchlists .filter {
  min-width: 150px;
  user-select: none;
  max-width: 180px;
  margin: 35px 0 20px;
}
.watchlists .filter__position {
  min-width: 140px;
}
.watchlists .filter label {
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 1.2;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
}
.watchlists .filter .input-range {
  margin-top: 25px;
}
.watchlists .filter .input-range .input-range__label--min, .watchlists .filter .input-range .input-range__label--max {
  display: none;
}
.watchlists .filter .input-range .input-range__label--value {
  top: -2.2rem;
  color: #2DC46E;
}
.watchlists .filter .input-range .input-range__track--active {
  background: #2DC46E;
}
.watchlists .filter .input-range .input-range__slider {
  background: #263742;
  border-color: #2DC46E;
  width: 26px;
  height: 26px;
  margin-left: -11px;
  margin-top: -13px;
}
.watchlists .filter .input-range .input-range__track--background {
  background: rgba(255, 255, 255, 0.3);
}
.watchlists .filter:nth-child(1) {
  margin-left: 0;
}
.watchlists .filter__checkbox {
  min-width: 160px;
  margin-left: 25px;
  position: relative;
  padding-left: 25px;
  /*text-transform: uppercase;*/
  /*letter-spacing: 1px;*/
  font-size: 15px;
  margin-top: 25px;
  opacity: 0.7;
  color: #2DC46E;
}
.watchlists .filter__checkbox.checked {
  opacity: 1;
}
.watchlists .filter .checkbox_label {
  margin-left: 6px;
  display: block;
  line-height: 130%;
  width: 80px;
}
.watchlists .filter .checkbox {
  border: 1px solid #2DC46E;
  width: 24px;
  height: 24px;
  top: -2px;
}
.watchlists .filter .checkbox svg {
  position: absolute;
  left: 3px;
  top: 3px;
  width: 16px;
  height: 16px;
}
.watchlists .filter.select-scout {
  margin-left: 0px;
}
.watchlists .upgrade-message {
  margin: 45px 0;
  font-size: 18px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.75);
  text-align: left;
  opacity: 0.8;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.upgrade-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.upgrade-placeholder .img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  opacity: 0.1;
}
.upgrade-placeholder h3 {
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.sub-nav__content.watchlists .container .container {
  padding-left: 0;
  padding-right: 0;
}

.sub-nav__content.watchlists .section-nav .container {
  margin-left: 0;
  margin-right: 0;
}
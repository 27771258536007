/* Colors */
/* Fonts */
/* Dimensions */
.biling-form .submit-row:after {
  content: "";
  display: table;
  clear: both;
}

.billing-address-form-page .container {
  width: 680px;
  margin: 0 auto;
}

.biling-form {
  margin-bottom: 60px;
}
.biling-form__warning-message {
  margin: 24px 0;
  color: #FFF;
  background: #d33a61;
  padding: 24px;
  max-width: 540px;
}
.biling-form .settings-h4 {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 35px;
  margin-bottom: 5px;
  color: white;
  opacity: 0.7;
}
.biling-form label {
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 0px;
  text-transform: uppercase;
  display: block;
  color: white;
  opacity: 0.7;
}
.biling-form .form-row {
  display: flex;
}
.biling-form h4 + .form-row {
  margin-top: 15px;
}
.biling-form .input-container-full-width {
  min-width: 300px;
  width: 100%;
  max-width: 530px;
  margin-bottom: 40px;
}
.biling-form .input-container {
  margin-left: 30px;
  width: 250px;
  flex-shrink: 0;
}
.biling-form .input-container .input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.biling-form .input-container .input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.biling-form .input-container .input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.biling-form .input-container .input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.biling-form .input-container:nth-child(1) {
  margin-left: 0;
}
.biling-form .billing-form .btn {
  display: block;
}
.biling-form .submit-row {
  justify-content: flex-end;
  width: 550px;
}
.biling-form .submit-row .btn {
  margin-left: 25px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.my-account .settings-h2 {
  text-transform: uppercase;
  margin-top: 60px;
}
.my-account .settings-h2:nth-child(1) {
  margin-top: 0;
}
.my-account > div {
  border-top: 1px solid #334a59;
  padding-top: 50px;
}
.my-account > div:nth-child(1) {
  padding-top: 0;
  border-top: none;
}
.my-account__delete {
  margin-top: 60px;
}
.my-account__export {
  margin-top: 60px;
}
.my-account__export .row {
  margin-bottom: 40px;
}

.delete-confirm p {
  margin-top: 20px;
  line-height: 145%;
}
.delete-confirm .h2 {
  font-weight: 700;
  font-size: 19px;
  margin: 0 0 1px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.95);
}
@media (max-width: 959px) {
  .delete-confirm .h2 {
    font-size: 14px;
  }
}
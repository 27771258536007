/* Colors */
/* Fonts */
/* Dimensions */
.favorites-filter {
  display: inline-block;
}
.favorites-filter .btn {
  position: relative;
  height: 44px;
  line-height: 42px;
}
.favorites-filter .btn.btn--filter {
  width: 44px;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
}
.favorites-filter .btn.btn--filter svg {
  width: 14px;
  height: 42px;
  stroke: currentColor;
  fill: none;
}
.favorites-filter .btn.btn--filter .btn--filter__num {
  text-align: center;
  position: absolute;
  display: block;
  right: -11px;
  bottom: -11px;
  border-radius: 11px;
  background: #d33a61;
  color: #FFF;
  font-size: 12px;
  line-height: 22px;
  width: 22px;
  height: 22px;
}
.favorites-filter .btn.btn--filter.active {
  color: #FFF;
  background: #35B374;
}
/* Colors */
/* Fonts */
/* Dimensions */
.homepage .players-block {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}
.homepage .players-block .homepage__block-content {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}
.homepage__players-list {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: stretch;
  align-items: stretch;
  align-content: center;
}
.homepage__players-list .demo-message {
  left: 30%;
  margin-top: 70px;
}
.homepage__players-add {
  width: 100%;
  margin-top: 8px;
  padding: 8px;
  display: flex;
  text-align: left;
  color: #FFF;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  text-transform: uppercase;
  align-items: center;
  position: relative;
  flex-basis: 17%;
}
.homepage__players-add svg {
  width: 22px;
  height: 22px;
  position: absolute;
  right: 21px;
  top: 50%;
  margin-top: -11px;
}
.homepage__players-add .homepage__player-image {
  background-size: contain;
  margin-right: 8px;
  vertical-align: middle;
}
.homepage__players-add:hover {
  color: #35B374;
}
.homepage__player {
  margin-top: 8px;
  border-radius: 6px;
  padding: 8px;
  display: flex;
  align-items: center;
  position: relative;
  flex-basis: 17%;
  color: #FFF;
}
.homepage__player .favorite-icon {
  width: 28px;
  height: 28px;
  margin-right: 12px;
}
.homepage__player:hover {
  color: #35B374;
}
.homepage__player:hover .homepage__player-image {
  background-color: #3A4C5A;
}
.homepage__player:hover .homepage__player-info .team {
  color: #FFF;
}
.homepage__player-image {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  margin-right: 8px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #2D3B46;
}
.homepage__team-image {
  width: 16px;
  height: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 4px;
  display: inline-block;
  background-color: #24323D;
  background-blend-mode: screen;
}
.homepage__player-info {
  flex-grow: 1;
  width: 100%;
}
.homepage__player-info .name {
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  margin-bottom: 2px;
}
.homepage__player-info .team {
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #CCC;
}
.homepage__players-demo {
  margin-top: 8px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 700;
}
.homepage__add-to-favorite-container {
  position: relative;
}
/* Colors */
/* Fonts */
/* Dimensions */
.sidebar {
  position: relative;
  min-height: 100%;
  float: left;
  width: 285px;
  background: #324859;
  margin-left: -285px;
  -webkit-transition: margin-left 0.5s ease-out;
  -moz-transition: margin-left 0.5s ease-out;
  transition: margin-left 0.5s ease-out;
}
@media (max-width: 959px) {
  .sidebar {
    width: 170px;
    margin-left: -170px;
  }
}
.sidebar--visible .sidebar {
  margin-left: 0;
}
.sidebar .toggle-btn {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: -60px;
  z-index: 2;
  padding: 10px;
}
@media (max-width: 959px) {
  .sidebar .toggle-btn {
    top: 4px;
    right: -40px;
  }
}
.sidebar .toggle-btn svg {
  vertical-align: middle;
  width: 26px;
  height: 26px;
}
@media (max-width: 959px) {
  .sidebar .toggle-btn svg {
    width: 16px;
    height: 16px;
  }
}
/* Colors */
/* Fonts */
/* Dimensions */
.create-watchlist-form {
  max-width: 582px;
  min-width: 320px;
  margin: 0 auto;
  background: #2E3C47;
  padding: 24px;
  box-sizing: border-box;
  flex-grow: 1;
  position: relative;
}
.create-watchlist-form h1 {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 0 20px;
}
.create-watchlist-form h1 .title-icon {
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
  width: 16px;
  height: 16px;
}
.create-watchlist-form label {
  font-size: 12px;
  line-height: 16px;
  color: #A4A6A4;
  text-transform: uppercase;
}
.create-watchlist-form .input {
  margin-top: 20px;
}
.create-watchlist-form .input input {
  color: #2DC46E;
}
.create-watchlist-form .input input:focus {
  color: #FFF;
}
.create-watchlist-form .close-icon {
  position: absolute;
  width: 10px;
  height: 10px;
  right: 25px;
  top: 25px;
  color: #FFF;
  cursor: pointer;
}
.create-watchlist-form .close-icon:hover {
  color: #2DC46E;
}
.create-watchlist-form button {
  margin-top: 20px;
}
.create-watchlist-form__wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 99999;
}
.create-watchlist-form__row {
  margin: 32px 0;
  display: flex;
  justify-content: space-between;
}
.create-watchlist-form__colors-list {
  display: flex;
  margin-top: 8px;
}
.create-watchlist-form__icons-list {
  margin-top: 8px;
  display: flex;
}
.create-watchlist-form__color {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-width: 3px;
  border-radius: 50%;
  border-style: solid;
  margin-right: 8px;
}
.create-watchlist-form__icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border-style: solid;
  margin-left: 8px;
  background: #3A4C5A;
}
.create-watchlist-form__icon svg {
  width: 24px;
  height: 24px;
  margin: 4px;
  fill: currentColor;
}
.create-watchlist-form__icon:hover svg {
  fill: #35B374;
}
.create-watchlist-form__icon.active {
  background: #FFF;
}
.create-watchlist-form__icon.active svg {
  fill: #35B374;
}
.reports-list {
  height: 100%;
}
.reports-list__empty-container {
  position: relative;
  max-width: 1023px;
}
.reports-list__empty-message {
  position: absolute;
  right: 48px;
  top: 36px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.data-item-icon {
  display: flex;
}
.data-item-icon__icon {
  width: 22px;
  height: 22px;
  position: relative;
  top: 10px;
}
.data-item-icon__icon svg {
  width: 100%;
  height: 100%;
}
.data-item-icon__column {
  margin-left: 11px;
  width: 60px;
}
.data-item-icon__value {
  color: #FFF;
  font-size: 16px;
  line-height: 22px;
}
.data-item-icon__value .data-item__image {
  position: relative;
  top: -2px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-position: center center;
  background-size: contain;
  vertical-align: middle;
  margin-right: 4px;
  background-repeat: no-repeat;
}
.data-item-icon__label {
  font-size: 13px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.7);
}
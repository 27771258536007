/* Colors */
/* Fonts */
/* Dimensions */
.shadow-team__content {
  display: flex;
  padding: 30px 0 0;
}
.shadow-team__content.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.shadow-team__lineup-container {
  margin: 0 auto;
  width: 100%;
}
.shadow-team__lineup-content {
  margin: 0 0 0 5px;
  position: relative;
}
.shadow-team__lineup-content.active {
  z-index: 3;
}
.shadow-team__lineup-content.active .fade {
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.shadow-team__lineup {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.shadow-team__settings {
  margin: 0 0 30px;
  position: relative;
  z-index: 3;
  width: 685px;
}
.shadow-team__settings::after {
  clear: both;
  content: "";
  display: table;
}
.shadow-team__favorite-add {
  float: right;
  position: relative;
  top: 16px;
}
.shadow-team__favorite-add .create-icon {
  width: 11px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: 6px;
}
.shadow-team__name, .shadow-team__formation, .shadow-team__autofill {
  float: left;
}
.shadow-team__name label, .shadow-team__formation label, .shadow-team__autofill label {
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 1.2;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
}
.shadow-team__name .input input, .shadow-team__name .input select, .shadow-team__formation .input input, .shadow-team__formation .input select, .shadow-team__autofill .input input, .shadow-team__autofill .input select {
  display: block;
  width: 100%;
  color: #2DC46E;
  font-size: 16px;
  line-height: 27px;
  border-bottom: 1px solid;
  border-radius: 0;
}
.shadow-team__name .input .Select-control input, .shadow-team__formation .input .Select-control input, .shadow-team__autofill .input .Select-control input {
  border-bottom: none;
}
.shadow-team__name {
  width: 180px;
  margin-right: 20px;
}
.shadow-team__formation {
  width: 120px;
  margin-right: 20px;
}
.shadow-team__autofill {
  width: 120px;
  margin-right: 20px;
}
.shadow-team__checkbox {
  float: left;
}
.shadow-team__checkbox .group-check {
  margin: 25px 0 0;
}
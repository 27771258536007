/* Colors */
/* Fonts */
/* Dimensions */
.auth {
  height: 100vh;
  vertical-align: middle;
  background-color: #021c29;
  background-image: url(../../../images/auth_bg3.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  font-family: "IBM Plex Sans", sans-serif;
  overflow: auto;
}
@media (max-width: 959px) {
  .auth {
    border: none;
  }
}
.modal-enter .auth {
  opacity: 0;
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}
.modal-enter-active .auth {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 500ms ease-out, opacity 500ms ease-out;
  -moz-transition: -moz-transform 500ms ease-out, opacity 500ms ease-out;
  transition: transform 500ms ease-out, opacity 500ms ease-out;
}
.modal-leave .auth {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.modal-leave-active .auth {
  opacity: 0;
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
  -webkit-transition: -webkit-transform 500ms ease-out, opacity 500ms ease-out;
  -moz-transition: -moz-transform 500ms ease-out, opacity 500ms ease-out;
  transition: transform 500ms ease-out, opacity 500ms ease-out;
}
.auth .auth-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 24px;
  width: 333px;
  height: calc(100vh - 121px);
  margin: 0 auto;
  padding-top: 89px;
  min-height: 480px;
}
@media (max-width: 959px) {
  .auth .auth-align {
    width: 100%;
    max-width: 380px;
    line-height: 12px;
    padding: 24px;
  }
}
.auth .auth-container {
  padding: 24px 32px;
  background: #FFF;
  color: #1C2734;
  border-radius: 12px;
  border-bottom: 1px solid #555;
  box-shadow: 0px 2px 8px rgba(27, 40, 47, 0.08);
  position: relative;
  z-index: 3;
}
@media (max-width: 959px) {
  .auth .auth-container {
    padding: 24px 32px;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.2);
  }
}
.auth .auth-title {
  color: #1C2734;
  font-size: 25px;
  line-height: 32px;
  font-weight: 700;
  opacity: 1;
  font-family: "IBM Plex Sans", sans-serif;
}
.auth .input {
  color: #1C2734;
  margin-top: 16px;
  position: relative;
}
.auth .input label {
  display: block;
  margin: 0 0 8px;
  color: #1C2734;
  font-size: 14px;
  line-height: 19px;
}
.auth .auth-text {
  color: #1C2734;
  margin-top: 20px;
  font-weight: 200;
  font-size: 16px;
  letter-spacing: 0.5px;
}
@media (max-width: 959px) {
  .auth .auth-text {
    margin-top: 10px;
    line-height: 20px;
  }
}
.auth .auth-text b {
  font-weight: 400;
}
.auth .auth-helpers {
  font-size: 13px;
  line-height: 17px;
  margin-top: 24px;
}
@media (max-width: 959px) {
  .auth .auth-helpers {
    font-size: 7px;
    line-height: 9px;
    margin-top: 5px;
    padding: 0 3px;
  }
}
.auth .auth-helpers .pull-left {
  float: left;
}
.auth .auth-helpers .pull-right {
  float: right;
}
.auth .auth-helpers a {
  cursor: pointer;
  color: #1C2734;
  text-decoration: underline;
}
.auth .auth-helpers a:hover {
  color: #35B374;
}

.auth-logo {
  position: relative;
  top: 32px;
  margin: 0 auto 32px;
  width: 196px;
  height: 57px;
}
.auth-logo img {
  position: relative;
  z-index: 2;
}

.top-shade {
  /*position: absolute;
  z-index: 1;
  left: 50%;
  top: 32px;
  width:240px;
  height: 240px;
  transform: translateX(-50%) translateY(-50%);
  background: rgb(2,28,41);
  background: radial-gradient(circle, rgba(2, 28, 41, .86) 0, rgba(2, 28, 41, 0) 70%);
  border-radius: 50%;*/
}

#Loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;
}
#Loader .loader-image {
  width: 120px;
  height: 120px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -60px 0 0 -60px;
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 160px 0;
  }
}
.performance-stats-overtime__heading {
  font-size: 18px;
  line-height: 26px;
}
.performance-stats-overtime__heading label {
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.7);
}
.performance-stats-overtime__body table {
  margin-top: 5px;
}
.performance-stats-overtime__body table tr, .performance-stats-overtime__body table td {
  border: none;
  padding: 0;
}
.performance-stats-overtime__body table td {
  padding-top: 5px;
  vertical-align: middle;
}
.performance-stats-overtime__body table .label {
  width: 80px;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.9);
  padding-right: 10px;
}
.performance-stats-overtime__body table .value {
  width: 30px;
  font-size: 13px;
  line-height: 18px;
  padding-left: 10px;
}
.performance-stats-overtime__body table .positive .bar .bar-section {
  background-color: #DA4B06;
}
.performance-stats-overtime__body table .neutral .bar .bar-section {
  background-color: #A4A6A4;
}
.performance-stats-overtime__body table .negative .bar .bar-section {
  background-color: #1E619F;
}
.performance-stats-overtime__body table .bar {
  display: flex;
  height: 8px;
  border-radius: 4px;
}
.performance-stats-overtime__body table .bar .bar-section {
  height: 8px;
  /*&.on{
    background: #7FAF7E;
  }
  &.off{
    background: #EFE464;
  }
  &.physis{
    background: #43687A;
  }*/
}
.performance-stats-overtime__body table .bar .bar-section:nth-last-child(1) {
  border-radius: 0 4px 4px 0;
}
.performance-stats-overtime__body table .bar .bar-section:nth-child(1) {
  border-radius: 4px 0 0 4px;
}
.performance-stats-overtime__body table .bar .bar-section:nth-child(1):nth-last-child(1) {
  border-radius: 4px !important;
}
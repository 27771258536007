/* Colors */
/* Fonts */
/* Dimensions */
.area-formations .formation-target {
  width: 42px;
  height: 42px;
  margin: -21px 0 0 -21px;
  position: absolute;
  border-radius: 50%;
  -webkit-transition: left 0.5s, top 0.5s;
  -moz-transition: left 0.5s, top 0.5s;
  transition: left 0.5s, top 0.5s;
}
@media (max-width: 959px) {
  .area-formations .formation-target {
    width: 18px;
    height: 18px;
    margin: -9px 0 0 -9px;
  }
}
.area-formations .formation-target:before {
  position: absolute;
  top: 50%;
  right: 50%;
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #36D07F;
  margin: -3px -5px 0 0;
  content: "";
}
.area-formations .formation-target.active:before {
  border-bottom: 6px solid #36D07F;
  border-top: none;
}
.area-formations .formation-target.home {
  background: rgba(32, 32, 32, 0.5);
}
.area-formations .formation-target.away {
  background: rgba(255, 255, 255, 0.5);
}
.area-formations .formation-target.away:before {
  border-top-color: #2BA664;
}
.area-formations .formation-target.away.active:before {
  border-bottom-color: #2BA664;
}

.position-players-list {
  position: absolute;
  text-align: left;
  z-index: 1;
  border-radius: 3px;
  overflow: auto;
  margin: -21px 23px;
  max-height: 200px;
}
.position-players-list .player {
  padding: 2px 15px 2px 69px;
  color: #2DC46E;
  cursor: pointer;
  overflow: hidden;
}
.position-players-list .player:hover {
  color: #fff;
}
@media (max-width: 959px) {
  .position-players-list .player {
    padding: 0 24px 0 50px;
    height: 33px;
    font-size: 11px;
  }
}
.position-players-list .player:nth-child(even) {
  background-color: #212f38;
}
.position-players-list .player .player__number {
  color: #fff;
}
.position-players-list .player .player__name {
  width: 100%;
}
.position-players-list .no-players {
  color: #fff;
  line-height: 35px;
  display: inline-block;
  font-size: 15px;
  background-color: #212f38;
  padding: 2px 15px;
  white-space: nowrap;
}
.layout-page {
	position: relative;
	min-height: 100%;
}

.branding {
	text-align: center;
    padding-bottom: 10px;
    padding-top: 20px;

    @media (max-width: $screen-sm-max) {
        padding-bottom: 5px;
        padding-top: 10px;
    }

	h1 {
        font-size: 25px;
        padding-top: 5px;
        letter-spacing: .02em;

        @media (max-width: $screen-sm-max) {
            font-size: 13px;
            padding-top: 3px;
        }
    }
}

/* Colors */
/* Fonts */
/* Dimensions */
.watchlists__players-table {
  margin-top: 25px;
  min-width: 560px;
}
.watchlists__lists-table {
  margin-top: 25px;
}
@media screen and (max-width: 959px) {
  .watchlists__players-table, .watchlists__lists-table {
    font-size: 13px;
    line-height: 19px;
  }
}
.watchlists__players-table li.heading, .watchlists__lists-table li.heading {
  display: flex;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 19px;
  color: #CCC;
  padding: 10px;
  position: relative;
}
.watchlists__players-table li.heading span.th, .watchlists__lists-table li.heading span.th {
  display: block;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
}
.watchlists__players-table li.heading span.th:hover, .watchlists__lists-table li.heading span.th:hover {
  color: #FFF;
}
.watchlists__players-table li.heading span.th.sorted, .watchlists__lists-table li.heading span.th.sorted {
  color: #FFF;
}
.watchlists__players-table li.heading span.th.sorted:hover, .watchlists__lists-table li.heading span.th.sorted:hover {
  color: #2DC46E;
}
.watchlists__players-table li.heading .tri, .watchlists__lists-table li.heading .tri {
  color: #FFF;
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  left: 0;
  margin-right: 3px;
  position: relative;
  vertical-align: middle;
  margin-top: -3px;
  font-weight: 600;
}
.watchlists__players-table li.heading .tri.asc, .watchlists__lists-table li.heading .tri.asc {
  border-bottom: none;
  border-top: 4px solid #FFF;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.watchlists__players-table li.heading .tri.desc, .watchlists__lists-table li.heading .tri.desc {
  border-top: none;
  border-bottom: 4px solid #FFF;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.watchlists__players-table li.heading span.col-drag-handle, .watchlists__lists-table li.heading span.col-drag-handle {
  padding: 0 4px;
}
.watchlists__players-table li.heading span.col-player, .watchlists__lists-table li.heading span.col-player {
  padding-left: 16px;
}
.watchlists__players-table li.heading span.col-title, .watchlists__lists-table li.heading span.col-title {
  padding-left: 50px;
}
@media screen and (max-width: 959px) {
  .watchlists__players-table li.heading span.col-title, .watchlists__lists-table li.heading span.col-title {
    padding-left: 16px;
  }
}
.watchlists__players-table .td, .watchlists__players-table .th, .watchlists__lists-table .td, .watchlists__lists-table .th {
  overflow: hidden;
  box-sizing: border-box;
}
.watchlists__players-table .td, .watchlists__lists-table .td {
  color: #FFF;
}
.watchlists__players-table .col-drag-handle, .watchlists__lists-table .col-drag-handle {
  vertical-align: middle;
  width: 45px;
}
.watchlists__players-table .col-drag-handle .drag-handle, .watchlists__lists-table .col-drag-handle .drag-handle {
  padding: 8px 4px;
}
.watchlists__players-table .col-drag-handle .drag-handle.inactive, .watchlists__lists-table .col-drag-handle .drag-handle.inactive {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
.watchlists__players-table .col-drag-handle .drag-handle.disabled, .watchlists__lists-table .col-drag-handle .drag-handle.disabled {
  opacity: 0.5;
  cursor: default;
}
.watchlists__players-table .col-drag-handle .drag-handle.disabled svg, .watchlists__lists-table .col-drag-handle .drag-handle.disabled svg {
  fill: #637581 !important;
}
.watchlists__players-table .col-drag-handle .drag-handle.disabled:hover svg, .watchlists__lists-table .col-drag-handle .drag-handle.disabled:hover svg {
  fill: #637581 !important;
}
.watchlists__players-table .col-drag-handle > span, .watchlists__lists-table .col-drag-handle > span {
  display: inline-block;
  height: 16px;
}
.watchlists__players-table .col-drag-handle svg, .watchlists__lists-table .col-drag-handle svg {
  width: 16px;
  height: 16px;
  fill: #637581;
  cursor: pointer;
}
.watchlists__players-table .col-drag-handle:hover svg, .watchlists__lists-table .col-drag-handle:hover svg {
  fill: #2DC46E;
}
.watchlists__players-table .col-player, .watchlists__players-table .col-team, .watchlists__lists-table .col-player, .watchlists__lists-table .col-team {
  width: calc(50% - 245px);
}
@media screen and (max-width: 1025px) {
  .watchlists__players-table .col-player, .watchlists__players-table .col-team, .watchlists__lists-table .col-player, .watchlists__lists-table .col-team {
    width: calc(50% - 185px);
  }
}
.watchlists__players-table .col-position, .watchlists__lists-table .col-position {
  padding: 0 8px;
  flex-grow: 0;
  width: 47px;
}
.watchlists__players-table .col-age, .watchlists__lists-table .col-age {
  padding: 0 8px;
  flex-grow: 0;
  width: 53px;
}
.watchlists__players-table .col-team, .watchlists__lists-table .col-team {
  padding: 0 8px;
}
.watchlists__players-table .col-date, .watchlists__lists-table .col-date {
  padding: 0 8px;
  flex-grow: 0;
  width: 133px;
}
@media screen and (max-width: 1024px) {
  .watchlists__players-table .col-date, .watchlists__lists-table .col-date {
    display: none !important;
  }
}
.watchlists__players-table .col-final-rating, .watchlists__lists-table .col-final-rating {
  width: 68px;
}
@media screen and (max-width: 959px) {
  .watchlists__players-table .col-final-rating, .watchlists__lists-table .col-final-rating {
    padding-left: 5px;
  }
}
@media screen and (max-width: 1024px) {
  .watchlists__players-table .col-author, .watchlists__lists-table .col-author {
    display: none !important;
  }
}
.watchlists__players-table .col-favorite, .watchlists__lists-table .col-favorite {
  padding: 0 12px;
  width: 168px;
  white-space: nowrap;
  overflow: visible;
}
.watchlists__players-table .col-delete, .watchlists__lists-table .col-delete {
  padding: 0 8px;
  width: 24px;
  padding-right: 16px;
}
.watchlists__players-table .col-delete svg, .watchlists__lists-table .col-delete svg {
  width: 10px;
  height: 10px;
  fill: currentColor;
  cursor: pointer;
}
.watchlists__players-table .col-delete svg:hover, .watchlists__lists-table .col-delete svg:hover {
  color: #2DC46E;
}
.watchlists__players-table .col-menu, .watchlists__lists-table .col-menu {
  overflow: visible;
  padding: 0 8px;
  width: 40px;
  padding-right: 16px;
}
.watchlists__players-table .col-title, .watchlists__lists-table .col-title {
  width: calc(100% - 488px);
}
@media screen and (max-width: 1199px) {
  .watchlists__players-table .col-title, .watchlists__lists-table .col-title {
    width: calc(100% - 488px);
  }
}
@media screen and (max-width: 1024px) {
  .watchlists__players-table .col-title, .watchlists__lists-table .col-title {
    width: calc(100% - 198px);
  }
}
.watchlists__players-table .col-author, .watchlists__lists-table .col-author {
  width: 60px;
}
.watchlists__players-table .col-players, .watchlists__lists-table .col-players {
  width: 50px;
}
.watchlists__players-table .dragable-player, .watchlists__players-table .dragable-watchlist, .watchlists__lists-table .dragable-player, .watchlists__lists-table .dragable-watchlist {
  align-items: center;
  background: #2D3B46;
  padding: 10px;
  margin-top: 5px;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  position: relative;
  color: #CCC;
}
.watchlists__players-table .dragable-player.inactive, .watchlists__players-table .dragable-watchlist.inactive, .watchlists__lists-table .dragable-player.inactive, .watchlists__lists-table .dragable-watchlist.inactive {
  opacity: 0.45;
  pointer-events: none;
}
@media screen and (max-width: 959px) {
  .watchlists__players-table .dragable-player, .watchlists__players-table .dragable-watchlist, .watchlists__lists-table .dragable-player, .watchlists__lists-table .dragable-watchlist {
    font-size: 13px;
    line-height: 19px;
  }
}
.watchlists__players-table .dragable-player.dragging, .watchlists__players-table .dragable-watchlist.dragging, .watchlists__lists-table .dragable-player.dragging, .watchlists__lists-table .dragable-watchlist.dragging {
  opacity: 0.5;
}
.watchlists__players-table .dragable-player .team, .watchlists__players-table .dragable-watchlist .team, .watchlists__lists-table .dragable-player .team, .watchlists__lists-table .dragable-watchlist .team {
  position: relative;
  display: block;
  padding-left: 24px;
}
.watchlists__players-table .dragable-player .team .team-image, .watchlists__players-table .dragable-watchlist .team .team-image, .watchlists__lists-table .dragable-player .team .team-image, .watchlists__lists-table .dragable-watchlist .team .team-image {
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 4px;
  top: 50%;
  margin-top: -8px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.watchlists__players-table .dragable-player .dragable-player__name, .watchlists__players-table .dragable-watchlist .dragable-player__name, .watchlists__lists-table .dragable-player .dragable-player__name, .watchlists__lists-table .dragable-watchlist .dragable-player__name {
  position: relative;
  display: block;
  padding-left: 48px;
}
.watchlists__players-table .dragable-player .dragable-player__name .player-image, .watchlists__players-table .dragable-watchlist .dragable-player__name .player-image, .watchlists__lists-table .dragable-player .dragable-player__name .player-image, .watchlists__lists-table .dragable-watchlist .dragable-player__name .player-image {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  position: absolute;
  left: 8px;
  top: 50%;
  margin-top: -16px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #e0e8ed;
}
.watchlists__players-table .dragable-player .dragable-player__name .last-name, .watchlists__players-table .dragable-watchlist .dragable-player__name .last-name, .watchlists__lists-table .dragable-player .dragable-player__name .last-name, .watchlists__lists-table .dragable-watchlist .dragable-player__name .last-name {
  font-weight: bold;
  display: block;
}
.watchlists__players-table .dragable-player .add-to-favorites, .watchlists__players-table .dragable-watchlist .add-to-favorites, .watchlists__lists-table .dragable-player .add-to-favorites, .watchlists__lists-table .dragable-watchlist .add-to-favorites {
  position: relative;
  top: -1px;
}
.watchlists__players-table .dragable-player .favorite-selector, .watchlists__players-table .dragable-watchlist .favorite-selector, .watchlists__lists-table .dragable-player .favorite-selector, .watchlists__lists-table .dragable-watchlist .favorite-selector {
  cursor: pointer;
  line-height: 25px;
}
.watchlists__players-table .dragable-player .favorite-selector svg, .watchlists__players-table .dragable-watchlist .favorite-selector svg, .watchlists__lists-table .dragable-player .favorite-selector svg, .watchlists__lists-table .dragable-watchlist .favorite-selector svg {
  width: 21px;
  height: 21px;
  display: inline-block;
  position: relative;
  top: -2px;
  vertical-align: middle;
}
.watchlists__players-table .dragable-player .favorite-selector .chevron-down, .watchlists__players-table .dragable-watchlist .favorite-selector .chevron-down, .watchlists__lists-table .dragable-player .favorite-selector .chevron-down, .watchlists__lists-table .dragable-watchlist .favorite-selector .chevron-down {
  vertical-align: middle;
  width: 15px;
  height: 15px;
  position: relative;
  fill: #FFF !important;
  stroke: none !important;
  top: -1px;
}
.watchlists__players-table .dragable-watchlist .col-title, .watchlists__lists-table .dragable-watchlist .col-title {
  overflow: visible;
  vertical-align: middle;
}
.watchlists__players-table .dragable-watchlist .col-title .watchlist-icon, .watchlists__lists-table .dragable-watchlist .col-title .watchlist-icon {
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -16px;
}
.watchlists__players-table .dragable-watchlist .col-title a, .watchlists__lists-table .dragable-watchlist .col-title a {
  display: block;
  position: relative;
  padding-left: 42px;
  vertical-align: middle;
}
.watchlists__players-table .dragable-watchlist .avatars, .watchlists__lists-table .dragable-watchlist .avatars {
  display: block;
  white-space: nowrap;
  width: 32px;
  height: 32px;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
  background: #24323D;
  color: #CCCCCC;
  border-radius: 16px;
}
.watchlists__players-table .dragable-watchlist .col-controls, .watchlists__lists-table .dragable-watchlist .col-controls {
  width: 90px;
}
.watchlists__players-table .dragable-watchlist .col-controls .control-btn, .watchlists__lists-table .dragable-watchlist .col-controls .control-btn {
  display: inline-block;
  text-align: center;
  width: 42px;
  height: 18px;
  cursor: pointer;
  color: #2DC46E;
  opacity: 0.7;
}
.watchlists__players-table .dragable-watchlist .col-controls .control-btn:nth-child(1), .watchlists__lists-table .dragable-watchlist .col-controls .control-btn:nth-child(1) {
  margin-left: 0;
}
.watchlists__players-table .dragable-watchlist .col-controls .control-btn svg, .watchlists__lists-table .dragable-watchlist .col-controls .control-btn svg {
  width: 18px;
  height: 18px;
  fill: none;
  stroke: currentColor;
}
.watchlists__players-table .dragable-watchlist .col-controls .control-btn:hover, .watchlists__lists-table .dragable-watchlist .col-controls .control-btn:hover {
  color: #fff;
  opacity: 1;
}
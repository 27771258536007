/* Colors */
/* Fonts */
/* Dimensions */
.report-main {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.report-main .player-body {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}
@media (max-width: 959px) {
  .report-main .player-body .scroll-container {
    min-width: 959px;
  }
}
.report-main .player-body.with-scroll {
  position: relative;
}
.report-main .player-body.with-scroll > div > div:nth-child(1) {
  scroll-behavior: smooth;
}
.report-main .player-body.with-scroll:after {
  content: "";
  width: 100%;
  height: 24px;
  bottom: -3px;
  left: 0;
  position: absolute;
  z-index: 2;
  background: linear-gradient(180deg, rgba(38, 55, 66, 0) 0%, rgb(38, 55, 66) 59.37%);
}
.report-main .player-body.with-scroll .scroll-arrow {
  width: 24px;
  height: 6px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  bottom: 0;
  z-index: 3;
  cursor: pointer;
  color: #FFF;
  stroke: currentColor;
  fill: none;
}
.report-main .player-body.with-scroll .scroll-arrow:hover {
  color: #35B374;
}
@media (max-width: 959px) {
  .report-main {
    padding: 0 20px;
  }
}
/* Colors */
/* Fonts */
/* Dimensions */
.navigation {
  background: #19242b;
  width: 64px;
  position: relative;
}
@media (max-width: 959px) {
  .navigation {
    width: 40px;
  }
}
.navigation .logo {
  width: 48px;
  height: 48px;
  margin: 8px;
}
@media (max-width: 959px) {
  .navigation .logo {
    width: 24px;
    height: 24px;
    margin: 6px;
  }
}
.navigation .links {
  position: absolute;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 470px;
}
@media (max-width: 959px) {
  .navigation .links {
    position: relative;
    margin-top: 10px;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
  }
}
.navigation .links li {
  width: 100%;
}
.navigation .links li:nth-child(1) {
  position: absolute;
  top: 0;
}
.navigation .links li:nth-child(2) {
  position: absolute;
  top: 58px;
}
.navigation .links li:nth-child(3) {
  position: absolute;
  top: 116px;
}
.navigation .links li:nth-child(4) {
  position: absolute;
  top: 174px;
}
.navigation .links li:nth-child(5) {
  position: absolute;
  top: 232px;
}
.navigation .links a {
  display: block;
  padding: 18px 20px;
  font-size: 0;
}
.navigation .links a svg {
  stroke: #CCCCCC;
  fill: none;
}
.navigation .links a svg.svg-active {
  display: none;
}
.navigation .links a svg.svg-inactive {
  display: block;
}
.navigation .links a.reports svg {
  fill: #CCCCCC;
  stroke: none;
}
.navigation .links a.reports:hover svg {
  fill: #2DC46E;
  stroke: none;
}
.navigation .links a.watchlists svg {
  fill: #CCCCCC;
  stroke: none;
}
.navigation .links a.watchlists:hover svg {
  fill: #2DC46E;
  stroke: none;
}
.navigation .links a:hover svg {
  stroke: #2DC46E;
}
.navigation .links a.active {
  background: #22323c;
}
.navigation .links a.active svg {
  stroke: #FFF;
}
.navigation .links a.active svg.svg-active {
  display: block;
  fill: #FFF;
  stroke: none;
}
.navigation .links a.active svg.svg-inactive {
  display: none;
}
@media (max-width: 959px) {
  .navigation .links a {
    padding: 14px 11px;
  }
}
.navigation .links svg {
  width: 22px;
  height: 22px;
}
@media (max-width: 959px) {
  .navigation .links svg {
    width: 18px;
    height: 18px;
  }
}
/* Colors */
/* Fonts */
/* Dimensions */
.pdf-lineups .title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  color: #393939;
  margin: 0 0 12px;
  border: none;
  resize: none;
  padding: 0;
}
@media screen and (max-width: 959px) {
  .pdf-lineups .title {
    font-size: 11px;
    margin: 0 0 6px;
  }
}
.pdf-lineups .pdf-lineups-mins {
  margin-top: 40px;
  font-size: 16px;
  font-weight: 400;
}
@media screen and (max-width: 959px) {
  .pdf-lineups .pdf-lineups-mins {
    margin-top: 20px;
    font-size: 12px;
  }
}
.pdf-lineups .pdf-pitch .pdf-pitch-player {
  width: 21px;
  height: 21px;
  margin: -10px 0 0 -10px;
}
@media screen and (max-width: 959px) {
  .pdf-lineups .pdf-pitch .pdf-pitch-player {
    width: 18px;
    height: 18px;
    margin: -9px 0 0 -9px;
  }
}
.pdf-lineups .pdf-pitch .pdf-pitch-player .player__number {
  font-size: 11px;
  line-height: 21px;
  width: 21px;
  height: 21px;
}
@media screen and (max-width: 959px) {
  .pdf-lineups .pdf-pitch .pdf-pitch-player .player__number {
    font-size: 9px;
    line-height: 18px;
    width: 18px;
    height: 18px;
  }
}
.pdf-lineups .pdf-pitch .pdf-pitch-player .player__name {
  font-size: 8px;
}
@media screen and (max-width: 959px) {
  .pdf-lineups .pdf-pitch .pdf-pitch-player .player__name {
    font-size: 6px;
  }
}
.pdf-lineups .pdf-pitch .pdf-pitch-player.substituted .player__number:before {
  height: 0;
  width: 0;
  border-bottom: 8px solid #fff;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: none;
  margin-top: -2px;
}
@media screen and (max-width: 959px) {
  .pdf-lineups .pdf-pitch .pdf-pitch-player.substituted .player__number:before {
    height: 0;
    width: 0;
    border-bottom: 4px solid #fff;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
  }
}
.pdf-lineups .pdf-pitch .pdf-pitch-player.substituted .player__number:after {
  height: 0;
  width: 0;
  border-bottom: 6px solid #188538;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: none;
  margin-top: -2px;
}
@media screen and (max-width: 959px) {
  .pdf-lineups .pdf-pitch .pdf-pitch-player.substituted .player__number:after {
    height: 0;
    width: 0;
    border-bottom: 2px solid #188538;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
  }
}
.pdf-lineups .col-6 {
  margin-bottom: 100px;
}
@media screen and (max-width: 959px) {
  .pdf-lineups .col-6 {
    margin-bottom: 50px;
  }
}
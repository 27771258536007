/* Colors */
/* Fonts */
/* Dimensions */
.api-list-filters.reports-filters .api-list-filters__pane {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  margin-top: 12px;
  margin-left: -255px;
  border-radius: 8px;
  background: #3A4C5A;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
}
/* Colors */
/* Fonts */
/* Dimensions */
.side-add-player {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -285px;
  width: 285px;
  background: #1E2A31;
  border-right: 1px solid #151C21;
  z-index: 3;
  padding: 15px;
  overflow: auto;
}
@media (max-width: 959px) {
  .side-add-player {
    left: -186px;
    width: 186px;
    padding: 8px;
  }
}
.side-add-player .side-add-player__close {
  position: absolute;
  top: 28px;
  right: 28px;
  cursor: pointer;
}
@media (max-width: 959px) {
  .side-add-player .side-add-player__close {
    top: 12px;
    right: 12px;
  }
}
.side-add-player .side-add-player__close svg {
  width: 24px;
  height: 24px;
}
@media (max-width: 959px) {
  .side-add-player .side-add-player__close svg {
    width: 12px;
    height: 12px;
  }
}
.side-add-player .side-add-player__title {
  font-weight: 700;
  font-size: 22px;
  padding: 10px 0 35px;
  line-height: 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-right: 25px;
}
@media (max-width: 959px) {
  .side-add-player .side-add-player__title {
    font-size: 12px;
    padding: 5px 0 18px;
    line-height: 13px;
  }
}
/* Colors */
/* Fonts */
/* Dimensions */
.shadow-team .container {
  padding-left: 36px;
  padding-right: 36px;
}
.shadow-team__heading {
  margin-bottom: 40px;
}
.shadow-team__heading h3 {
  vertical-align: middle;
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
}
.shadow-team .demo-message {
  margin: 60px 0 0 120px;
}
.shadow-team .empty-message {
  letter-spacing: 0.2px;
  font-size: 18px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.75);
  margin: 25px 0;
  width: 50%;
  text-align: left;
  opacity: 0.8;
  font-weight: 400;
}
.shadow-team .tabs-nav {
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 1px solid #385161;
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  line-height: 20px;
  margin: 15px 0 0;
}
.shadow-team .tabs-nav .shadow-container {
  padding-bottom: 0 !important;
}
.shadow-team .tabs-nav a:first-child {
  text-align: center;
}
.shadow-team .tabs-nav a, .shadow-team .tabs-nav span {
  padding: 12px 10px 9px;
  margin-right: 0;
  cursor: pointer;
  height: 44px;
  overflow: hidden;
  color: #D8D8D8;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.shadow-team .tabs-nav a .create-icon, .shadow-team .tabs-nav span .create-icon {
  width: 11px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: 6px;
}
.shadow-team .tabs-nav a .close-icon, .shadow-team .tabs-nav span .close-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  cursor: pointer;
}
.shadow-team .tabs-nav a .close-icon svg, .shadow-team .tabs-nav span .close-icon svg {
  width: 10px;
  height: 10px;
  position: relative;
}
.shadow-team .tabs-nav a .more-icon, .shadow-team .tabs-nav span .more-icon {
  width: 0;
  height: 0;
  transform: rotateX(180deg);
  transform-origin: 50% 50%;
  padding: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 5px solid;
  display: inline-block;
  vertical-align: middle;
  top: -1px;
  position: relative;
}
.shadow-team .tabs-nav a.open .more-icon, .shadow-team .tabs-nav span.open .more-icon {
  transform: rotateX(0deg);
}
.shadow-team .tabs-nav a.more, .shadow-team .tabs-nav span.more {
  position: relative;
  overflow: visible;
}
.shadow-team .tabs-nav a.active, .shadow-team .tabs-nav span.active {
  color: #FFF;
  font-weight: bold;
  border-bottom: 2px solid;
  padding: 12px 10px 8px;
  cursor: default;
}
.shadow-team .tabs-nav a:hover, .shadow-team .tabs-nav span:hover {
  color: #FFF;
}
.shadow-team .tabs-nav .dropdown {
  height: auto;
  width: 100%;
  position: absolute;
  background: #395262;
  padding: 0;
  top: 100%;
  left: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.shadow-team .tabs-nav .dropdown > a, .shadow-team .tabs-nav .dropdown > span {
  text-align: left;
  width: 100%;
  padding: 12px 10px 9px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.shadow-team .tabs-nav .dropdown > a.active, .shadow-team .tabs-nav .dropdown > span.active {
  border-bottom-color: transparent;
  color: #FFF;
}
.shadow-team .tabs-nav .dropdown > a.active .close-icon svg, .shadow-team .tabs-nav .dropdown > span.active .close-icon svg {
  color: #FFF;
  fill: #FFF;
}
.shadow-team .heading {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 200;
  margin: 0 0 25px;
}
.shadow-team .heading.lined {
  position: relative;
}
.shadow-team .heading.lined span {
  display: inline-block;
  padding-right: 25px;
  background: #f5f6f7;
  position: relative;
  z-index: 2;
}
.shadow-team .heading.lined:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #DFE3E6;
  left: 0;
  top: 50%;
}
.shadow-team__table {
  width: 100%;
  table-layout: fixed;
  font-size: 16px;
  line-height: 1.2;
  border: none;
  max-width: 1064px;
  border-collapse: separate;
  border-spacing: 0px 4px;
  margin-bottom: 0px;
}
.shadow-team__table .team-author {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  opacity: 0.5;
}
.shadow-team__table th, .shadow-team__table td {
  text-align: left;
  padding: 12px 8px 15px;
  border: none;
}
.shadow-team__table th:nth-child(1), .shadow-team__table td:nth-child(1) {
  padding-left: 15px;
}
.shadow-team__table th {
  padding-top: 0;
  padding-bottom: 7px;
  text-align: left;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 400;
  font-weight: normal;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
}
.shadow-team__table th .sort {
  width: 6px;
  height: 3px;
  transform: rotate(180deg);
}
.shadow-team__table th .sort.sort-asc {
  transform: rotate(0deg);
}
.shadow-team__table th:last-child {
  width: 41%;
}
.shadow-team__table a {
  color: #2DC46E;
  display: block;
}
.shadow-team__table a:hover {
  color: #fff;
}
.shadow-team__table tbody tr {
  background: #2a3c48;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.shadow-team__table tbody td {
  margin-bottom: 4px;
  position: relative;
}
.shadow-team__table tbody td.with-demo-badge {
  padding-left: 35px;
}
.shadow-team__table tbody tr:last-child th, .shadow-team__table tbody tr:last-child td {
  border-bottom: none;
  vertical-align: middle;
}
.shadow-team__table tbody td:last-child {
  padding-top: 0px;
  padding-bottom: 0px;
}
.shadow-team__table tbody td:last-child .control-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 42px;
  vertical-align: middle;
  margin-top: 2px;
}
.shadow-team__table tbody td:last-child a {
  padding-top: 12px;
  padding-bottom: 15px;
}
.shadow-team__table tbody td:last-child {
  padding-left: 0px;
}
.shadow-team__table .col__date, .shadow-team__table .col__formation {
  width: 120px;
}
@media screen and (max-width: 959px) {
  .shadow-team__table .col__date {
    display: none;
  }
}
.shadow-team__table .col__controls {
  width: 125px;
}
.shadow-team__table .shadow-team__table-controls {
  min-width: 130px;
  display: block;
}
.shadow-team__table .control-btn {
  display: inline-block;
  text-align: center;
  width: 42px;
  height: 18px;
  cursor: pointer;
  color: #2DC46E;
  opacity: 0.7;
}
.shadow-team__table .control-btn:nth-child(1) {
  margin-left: 0;
}
.shadow-team__table .control-btn svg {
  width: 18px;
  height: 18px;
  fill: none;
  stroke: currentColor;
}
.shadow-team__table .control-btn:hover {
  color: #fff;
  opacity: 1;
}
.shadow-team__table input {
  font-size: 16px;
  line-height: 21px;
  padding: 0;
}
.shadow-team__edit-row-controls {
  text-align: left;
}
.shadow-team__edit-row-controls a {
  margin-left: 20px;
}
.shadow-team__edit-row-controls a:nth-child(1) {
  margin-left: 0;
}
.shadow-team__edit-row-controls .green-btn {
  color: #2DC46E;
}
.shadow-team__edit-row-controls .red-btn {
  color: #d33a61;
}
.shadow-team__edit-row-controls .red-btn:hover {
  color: #d33a61;
}
.shadow-team .create-team {
  margin: 40px 0 10px 0px;
  max-width: 360px;
  white-space: nowrap;
  /*    &:hover{
        color: $color_GreenAction;
      }*/
}
@media (max-width: 959px) {
  .shadow-team .create-team {
    width: 300px;
    font-size: 13px;
  }
}
.shadow-team .create-team .create-icon {
  width: 11px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: 6px;
  margin-left: 6px;
}
.shadow-team .group-check {
  margin: 25px 0;
  color: #2DC46E;
  position: relative;
  padding-top: 8px;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}
.shadow-team .group-check .checkbox {
  border-color: #2DC46E;
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  margin-right: 8px;
  position: relative;
  top: -1px;
}
.shadow-team .group-check .checkbox svg {
  width: 16px;
  height: 16px;
  top: 2px;
  left: 2px;
  position: absolute;
  stroke: #2DC46E;
}
.shadow-team .group-check label {
  cursor: pointer;
}
.shadow-team .group-check label.checked {
  opacity: 1;
}
.shadow-team .filter {
  min-width: 150px;
  user-select: none;
  max-width: 180px;
  margin: 35px 0 20px;
}
.shadow-team .filter__position {
  min-width: 140px;
}
.shadow-team .filter label {
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 1.2;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
}
.shadow-team .filter .input-range {
  margin-top: 25px;
}
.shadow-team .filter .input-range .input-range__label--min, .shadow-team .filter .input-range .input-range__label--max {
  display: none;
}
.shadow-team .filter .input-range .input-range__label--value {
  top: -2.2rem;
  color: #2DC46E;
}
.shadow-team .filter .input-range .input-range__track--active {
  background: #2DC46E;
}
.shadow-team .filter .input-range .input-range__slider {
  background: #263742;
  border-color: #2DC46E;
  width: 26px;
  height: 26px;
  margin-left: -11px;
  margin-top: -13px;
}
.shadow-team .filter .input-range .input-range__track--background {
  background: rgba(255, 255, 255, 0.3);
}
.shadow-team .filter:nth-child(1) {
  margin-left: 0;
}
.shadow-team .filter__checkbox {
  min-width: 160px;
  margin-left: 25px;
  position: relative;
  padding-left: 25px;
  /*text-transform: uppercase;*/
  /*letter-spacing: 1px;*/
  font-size: 15px;
  margin-top: 25px;
  opacity: 0.7;
  color: #2DC46E;
}
.shadow-team .filter__checkbox.checked {
  opacity: 1;
}
.shadow-team .filter .checkbox_label {
  margin-left: 6px;
  display: block;
  line-height: 130%;
  width: 80px;
}
.shadow-team .filter .checkbox {
  border: 1px solid #2DC46E;
  width: 24px;
  height: 24px;
  top: -2px;
}
.shadow-team .filter .checkbox svg {
  position: absolute;
  left: 3px;
  top: 3px;
  width: 16px;
  height: 16px;
}
.shadow-team .filter.select-scout {
  margin-left: 0px;
}
.shadow-team .upgrade-message {
  margin-top: 45px;
  font-size: 18px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.75);
  text-align: left;
  opacity: 0.8;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.upgrade-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.upgrade-placeholder .img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  opacity: 0.1;
}
.upgrade-placeholder h3 {
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}
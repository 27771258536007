/* Colors */
/* Fonts */
/* Dimensions */
.sub-tabs {
  font-size: 15px;
  font-weight: 500;
  height: 50px;
  background: #324859;
}
@media (max-width: 959px) {
  .sub-tabs {
    font-size: 12px;
    height: 32px;
  }
}
.sub-tabs a {
  color: #2DC46E;
  display: inline-block;
  width: 50%;
  border-bottom: 1px solid #495D6A;
  line-height: 49px;
  cursor: pointer;
  padding: 0 1px 0 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
@media (max-width: 959px) {
  .sub-tabs a {
    line-height: 32px;
    padding: 0 3px 0 19px;
  }
}
.sub-tabs a:hover, .sub-tabs a.active {
  color: #fff;
}
.sub-tabs a.active {
  border-bottom-color: #fff;
  font-weight: 700;
}
.sub-tabs a .team-image {
  position: absolute;
  top: 50%;
  left: 14px;
  margin-top: -9px;
  width: 17px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (max-width: 959px) {
  .sub-tabs a .team-image {
    left: 4px;
    margin-top: -6px;
    width: 12px;
    height: 13px;
  }
}
/* Colors */
/* Fonts */
/* Dimensions */
.container.admin {
  height: 100%;
  padding-bottom: 0;
}

.customers {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.customers .js-link {
  color: #2DC46E;
  border-bottom: 1px dotted;
  cursor: pointer;
}
.customers .js-link.red {
  color: #d33a61;
}
.customers .js-link.blue {
  color: #3f809c;
}
.customers .js-link.orange {
  color: #F2CB6C;
}
.customers__filters {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}
.customers__filters-container {
  width: 100px;
  flex-grow: 0;
}
.customers__sort {
  width: 200px;
}
.customers__rebuild {
  flex-grow: 0;
}
.customers__rebuild .js-link {
  color: #2DC46E;
  border-bottom: 1px dotted;
  cursor: pointer;
}
.customers__rebuild .js-link:hover {
  color: #188538;
}
.customers__filter {
  margin-right: 25px;
}
.customers__list {
  flex-grow: 1;
  margin-top: 25px;
  position: relative;
}
.customers__virtual-list {
  height: 100%;
  width: 100%;
}
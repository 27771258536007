.match-card__match {
  height: 40px;
  position: relative;
  width: 100%;
}
.match-card__match .match-card__team-image {
  left: 50%;
  width: 25px;
  height: 33px;
}
.match-card__match .match-card__team-image:nth-child(1) {
  margin-left: -10px;
  z-index: 2;
}
.match-card__match .match-card__team-image:nth-child(2) {
  margin-left: 10px;
  z-index: 1;
}
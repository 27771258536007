/* Colors */
/* Fonts */
/* Dimensions */
.upgrade-button__row-button {
  display: block;
  background: #2a3c48;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  text-transform: uppercase;
  color: #FFF;
  padding: 12px 15px 8px;
  letter-spacing: 0.14em;
}
.upgrade-button__row-button svg {
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 24px;
}
.upgrade-button__row-button:hover {
  color: #35B374;
}
.upgrade-button.shadow-team {
  max-width: 1064px;
}
.upgrade-button.shadow-team .upgrade-button__row-button {
  padding: 12px 8px 8px;
}
.upgrade-button.shadow-team .upgrade-button__row-button svg {
  margin-right: 12px;
}
.upgrade-button.watchlists .upgrade-button__row-button {
  background: #2D3B46;
  padding: 10px;
  margin-top: 4px;
}
.upgrade-button.watchlists .upgrade-button__row-button svg {
  margin-right: 4px;
}
.upgrade-button.players {
  margin: 48px 0;
}

.upgrade-message-user {
  font-size: 16px;
  line-height: 22px;
  margin: 24px 0;
  opacity: 0.8;
}

.upgrade-button-big {
  margin: 35px 35px 0;
  display: flex;
  align-items: center;
}
.upgrade-button-big .demo-message {
  margin-left: 65px;
}
.upgrade-button-big__row-button {
  width: 163px;
  height: 172px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin: 8px 8px 0 0;
  padding: 16px;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: bold;
  color: #FFF;
  letter-spacing: 0.14em;
  text-align: center;
  background-color: #2D3B46;
  border: 1px solid #2D3B46;
  border-radius: 6px;
  cursor: pointer;
  background-image: none;
}
.upgrade-button-big__row-button svg {
  display: block;
  margin: 0 auto;
  width: 81px;
  height: 80px;
}
.upgrade-button-big__row-button:hover {
  border: 1px solid #3A4C5A;
}
.player-search {
  position: relative;
}
.player-search input {
  width: 100%;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  border: none;
  padding: 6px 15px 6px 46px;
  height: 44px;
  line-height: 32px;
}
.player-search svg {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -8px;
}
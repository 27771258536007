/* Colors */
/* Fonts */
/* Dimensions */
@-webkit-keyframes preview-end {
  from {
    -webkit-transform: scale(1.1);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes preview-end {
  from {
    -moz-transform: scale(1.1);
  }
  to {
    -moz-transform: scale(1);
  }
}
@keyframes preview-end {
  from {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  to {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.substitution-player {
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  position: relative;
  margin-top: 6px;
  width: 14.2857142857%;
}
@media (max-width: 959px) {
  .substitution-player {
    height: 18px;
    margin-top: 3px;
  }
}
.substitution-player.active {
  z-index: 2;
}
.substitution-player:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-animation: preview-end 0.1s linear;
  -moz-animation: preview-end 0.1s linear;
  animation: preview-end 0.1s linear;
  margin-left: -18px;
  width: 36px;
  height: 36px;
}
@media (max-width: 959px) {
  .substitution-player:before {
    margin-left: -9px;
    width: 18px;
    height: 18px;
  }
}
.substitution-player .player__number {
  position: relative;
  color: #fff;
  text-align: center;
  display: block;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -18px;
}
@media (max-width: 959px) {
  .substitution-player .player__number {
    width: 18px;
    height: 18px;
    margin-left: -9px;
  }
}
.substitution-player .player__number-text {
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  margin-top: 3px;
}
@media (max-width: 959px) {
  .substitution-player .player__number-text {
    margin-top: 2px;
    font-size: 9px;
    line-height: 9px;
  }
}
.substitution-player .player__birthdate {
  margin-top: -4px;
  line-height: 18px;
  font-size: 8px;
  color: #CCC;
}
@media (max-width: 959px) {
  .substitution-player .player__birthdate {
    margin-top: -2px;
    font-size: 7px;
    line-height: 9px;
  }
}
.substitution-player.substituted .player__number:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  height: 0;
  width: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #fff;
}
@media (max-width: 959px) {
  .substitution-player.substituted .player__number:before {
    height: 0;
    width: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #fff;
  }
}
.substitution-player.substituted .player__number:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #d33a61;
}
@media (max-width: 959px) {
  .substitution-player.substituted .player__number:after {
    height: 0;
    width: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #d33a61;
  }
}
.substitution-player.substituted.active .player__number:before,
.substitution-player.substituted.active .player__number:after {
  display: none;
}
.home .substitution-player:before {
  background: #202020;
}
.away .substitution-player:before {
  background: #fff;
}
.away .substitution-player .player__number {
  color: #000;
}
.away .substitution-player .player__birthdate {
  color: #000;
}
.substitution-player .player__name {
  position: absolute;
  top: 100%;
  right: 2px;
  left: 2px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  margin-top: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}
@media (max-width: 959px) {
  .substitution-player .player__name {
    left: 1px;
    right: 1px;
    font-size: 10px;
  }
}
.substitution-player .swap {
  position: absolute;
  top: -14px;
  right: -14px;
  width: 18px;
  height: 18px;
}
@media (max-width: 959px) {
  .substitution-player .swap {
    top: -7px;
    right: -7px;
    width: 9px;
    height: 9px;
  }
}
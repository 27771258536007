/* Colors */
/* Fonts */
/* Dimensions */
.player-category-field .icon_btn {
  width: 25px;
  height: 25px;
  margin-left: 15px;
  opacity: 0.5;
  display: inline-block;
  margin-top: 15px;
  cursor: pointer;
}
.player-category-field .icon_btn:hover {
  opacity: 1;
}
.player-category-field .icon_btn:nth-child(1) {
  margin-left: 0;
}
.player-category-field .icon_btn.cat_0 {
  stroke: #5F7582;
  fill: none;
}
.player-category-field .icon_btn.cat_0.active {
  fill: #5F7582;
}
.player-category-field .icon_btn.cat_1 {
  stroke: #EFDD30;
  fill: none;
}
.player-category-field .icon_btn.cat_1.active {
  fill: #EFDD30;
}
.player-category-field .icon_btn.cat_2 {
  stroke: #F98736;
  fill: none;
}
.player-category-field .icon_btn.cat_2.active {
  fill: #F98736;
}
.player-category-field .icon_btn.cat_3 {
  stroke: #DD4043;
  fill: none;
}
.player-category-field .icon_btn.cat_3.active {
  fill: #DD4043;
}
.player-category-field .icon_btn.active {
  opacity: 1;
}
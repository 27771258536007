/* Colors */
/* Fonts */
/* Dimensions */
.watchlist__suggestions {
  margin-top: 96px;
}
.watchlist__suggestions h2 {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 0 0 20px;
}
.watchlist .show-more {
  width: 100%;
  border: none;
  background: none;
  color: #2DC46E;
  text-align: center;
  padding: 15px 0;
}
.watchlist .show-more:hover {
  color: #FFF;
}
.watchlist .add-selected {
  float: right;
  margin-top: 20px;
}
@import '../../../../styles/exports';

$color_light_border: rgba(255, 255, 255, .15);
$color-light-border: rgba(255, 255, 255, .15);
$time_transition: 250ms;
$color_active_light_blue: $color_GreenAction;
$color_active: $color_GreenAction;
$color_active_bg_blue: rgba(255, 255, 255, .15);
$color_text_light: #777;
$color_meta_text: #999;

$color_link: #CCC;
$blue: blue;
$time-transition: 250ms;
$color-active: green;
$color-meta-text: #CCC;

$squad_link_hover: green;

$anthrazit: #000;
$color-text-light: #CCC;
$color-placeholder: #CCC;
$color-link: $color_GreenAction;

$color_tag_bg: #CCC;
$blue_light: blue;

$color_placeholder: #ccc;

$time_transition_long: 500ms;
$primary-color: red;
$color_content_title: #FFF;
$mq-tablet-landscape: 1023px;

$color_content_text: #FFF;
$color-content-title: #FFF;
$color-active-light-blue: blue;

$dark_actionbar: black;

@mixin clip-path($clip) {
  -webkit-clip-path: $clip;
  -moz-clip-path: $clip;
  -ms-clip-path: $clip;
  clip-path: $clip;
}

/* Colors */
/* Fonts */
/* Dimensions */
.customers__header {
  display: flex;
}
.customers__search {
  flex-grow: 1;
}
.customers__create {
  flex-grow: 0;
  width: 200px;
  margin-left: 25px;
}
.customers__create .btn {
  line-height: 38px;
  height: 38px;
  white-space: nowrap;
}
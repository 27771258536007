/* Colors */
/* Fonts */
/* Dimensions */
.substitution-control-window {
  text-align: left;
  position: fixed;
  width: 582px;
  height: 248px;
  left: 50%;
  top: 50%;
  margin-left: -291px;
  margin-top: -124px;
  background: #2E3C47;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
  border-radius: 6px;
  z-index: 10;
}
.substitution-control-window__timeline {
  display: flex;
  justify-content: center;
  align-items: center;
}
.substitution-control-window__timeline label {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #CCCCCC;
  margin: 0 8px;
}
.substitution-control-window__timeline span {
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  color: #FFFFFF;
  display: block;
  margin: 0 8px;
}
.substitution-control-window__timeline input {
  color: #35B374;
  border: none;
  display: block;
  padding: 0;
  margin: 0;
  font-size: 32px;
  line-height: 40px;
  width: 32px;
  height: 40px;
}
.substitution-control-window__timeline input.mins {
  width: 46px;
  text-align: right;
}
.substitution-control-window__timeline input::placeholder {
  color: #35B374;
}
.substitution-control-window__pad {
  box-sizing: border-box;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.substitution-control-window__close {
  display: block;
  position: absolute;
  z-index: 2;
  padding: 8px;
  right: 16px;
  top: 16px;
  color: #FFF;
  cursor: pointer;
}
.substitution-control-window__close:hover {
  color: #35B374;
}
.substitution-control-window__close svg {
  width: 8px;
  height: 8px;
  fill: currentColor;
}
.substitution-control-window__buttons {
  text-align: right;
}
.substitution-control-window__buttons .btn-submit {
  background: #35B374;
  color: #FFF;
  margin-left: 24px;
}
.substitution-control-window__buttons .btn-submit:hover {
  background: #fff;
  color: #35B374;
}
.substitution-control-window__title {
  text-align: left;
  color: #FFF;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.substitution-control-window__title svg {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 10px;
  margin-right: 6px;
  position: relative;
  top: -2px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.settings {
  will-change: transform, opacity;
  height: 100%;
}
.settings .settings-h2 {
  font-weight: 700;
  font-size: 19px;
  margin: 0 0 1px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.95);
}
@media (max-width: 959px) {
  .settings .settings-h2 {
    font-size: 14px;
  }
}
.settings .settings-h4 {
  font-weight: 300;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 18px;
  margin: 0 0 25px;
  opacity: 0.8;
  color: rgba(255, 255, 255, 0.75);
  margin: 0 0 25px;
}
@media (max-width: 959px) {
  .settings .settings-h4 {
    font-size: 14px;
    margin: 0 0 13px;
  }
}
.settings .green-btn {
  color: #2DC46E;
  font-weight: 400;
}
.settings .red-btn {
  color: #d33a61;
  margin-left: 20px;
  font-weight: 400;
}
@media (max-width: 959px) {
  .settings .red-btn {
    margin-left: 10px;
  }
}
.settings .blue-btn, .settings .orange-btn {
  margin-right: 20px;
}
@media (max-width: 959px) {
  .settings .blue-btn, .settings .orange-btn {
    margin-right: 10px;
  }
}
.settings .orange-btn {
  color: #F2CB6C;
}
.settings .blue-btn {
  color: #3f809c;
}
.settings .inp {
  width: calc(100% + 10px);
  height: 32px;
  margin: 0px;
  padding: 0px 6px 4px 0px;
  font-size: 16px;
  margin-bottom: 40px;
}
@media (max-width: 959px) {
  .settings .inp {
    width: calc(100% + 5px);
    height: 15px;
    margin: -3px;
    padding: 1px 3px 2px;
    font-size: 11px;
  }
}
.settings .inp.invalid {
  color: red;
}
.settings .inp.invalid::-webkit-input-placeholder {
  color: red;
  transition: opacity 0.2s;
  opacity: 1;
}
.settings .inp.invalid::-moz-placeholder {
  color: red;
  transition: opacity 0.2s;
  opacity: 1;
}
.settings .inp.invalid:-ms-input-placeholder {
  color: red;
  transition: opacity 0.2s;
  opacity: 1;
}
.settings .inp.invalid:-moz-placeholder {
  color: red;
  transition: opacity 0.2s;
  opacity: 1;
}
.settings .inp::-webkit-input-placeholder {
  color: #8B8282;
  transition: opacity 0.2s;
  opacity: 1;
}
.settings .inp::-moz-placeholder {
  color: #8B8282;
  transition: opacity 0.2s;
  opacity: 1;
}
.settings .inp:-ms-input-placeholder {
  color: #8B8282;
  transition: opacity 0.2s;
  opacity: 1;
}
.settings .inp:-moz-placeholder {
  color: #8B8282;
  transition: opacity 0.2s;
  opacity: 1;
}
.settings .inp:focus::-webkit-input-placeholder {
  opacity: 0;
}
.settings .inp:focus::-moz-placeholder {
  opacity: 0;
}
.settings .inp:focus:-ms-input-placeholder {
  opacity: 0;
}
.settings .inp:focus:-moz-placeholder {
  opacity: 0;
}
.settings .inp-helper {
  border: 1px solid;
  width: 16px;
  height: 16px;
  line-height: 14px;
  text-align: center;
  border-radius: 8px;
  cursor: default;
  font-style: normal;
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
  position: relative;
  top: -2px;
}
.settings .inp-helper:hover {
  color: #35B374;
}
.settings .Select {
  margin: -5px -6px;
  position: relative;
}
@media (max-width: 959px) {
  .settings .Select {
    margin: -3px;
  }
}
.settings .Select .Select-value-label, .settings .Select .Select-placeholder {
  font-size: 15px;
  font-weight: 300;
}
.settings .Select .Select-control {
  font-size: 15px;
  border-radius: 0;
  border-bottom: 1px solid #2DC46E !important;
  height: 30px;
}
@media (max-width: 959px) {
  .settings .Select .Select-control {
    font-size: 11px;
    height: 15px;
  }
}
.settings .Select .Select-value {
  padding: 0 6px;
  line-height: 29px;
}
@media (max-width: 959px) {
  .settings .Select .Select-value {
    padding: 0 3px;
    line-height: 15px;
  }
}
.settings .Select .Select-input {
  height: 29px;
}
@media (max-width: 959px) {
  .settings .Select .Select-input {
    height: 15px;
  }
}
.settings .Select .Select-input input {
  width: calc(100% + 10px);
  height: 29px;
  margin: 0;
  padding: 3px 6px 4px;
  font-size: 15px;
}
@media (max-width: 959px) {
  .settings .Select .Select-input input {
    width: calc(100% + 5px);
    height: 15px;
    margin: -3px;
    padding: 2px 3px 2px;
    font-size: 4px;
  }
}
.settings .Select .Select-placeholder {
  color: #8B8282 !important;
  line-height: 29px;
  padding: 0 5px;
}
@media (max-width: 959px) {
  .settings .Select .Select-placeholder {
    line-height: 15px;
    padding: 0 2px;
  }
}
.settings .settings-more-col .js-link {
  color: #2DC46E;
  border-bottom: 1px dashed;
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
}
.settings .settings-more-col .js-link:nth-child(1) {
  margin-left: 0;
}
.settings .settings-select-all {
  margin: 0 0 25px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.outer .control .section {
  fill: #263742;
  stroke: #151C21;
  stroke-width: 1px;
  cursor: pointer;
}
.outer .control .section:hover {
  fill: #3c4b55;
}
.outer .control text {
  fill: #2DC46E;
  font-size: 31px;
  text-align: center;
  text-anchor: middle;
  pointer-events: none;
}
@media (max-width: 959px) {
  .outer .control text {
    font-size: 20px;
  }
}
.outer .control.active .section {
  fill: #fff;
}
.outer .control.active text {
  fill: #151C21;
}
/* Colors */
/* Fonts */
/* Dimensions */
.favorite header .container:after {
  content: "";
  display: table;
  clear: both;
}

.favorite .container {
  padding: 0 38px 40px;
  max-width: 1280px;
}
.favorite .section-nav + .container {
  padding-bottom: 140px;
}
@media (max-width: 959px) {
  .favorite .section-nav + .container {
    padding-bottom: 0;
  }
}
.favorite .section-nav {
  background: none !important;
  box-shadow: none !important;
}
.favorite .section-nav .container {
  border-bottom: 2px solid #385161;
  margin: 0 38px;
  padding: 0;
  max-width: 1204px;
}
.favorite .section-nav .container a, .favorite .section-nav .container span {
  padding: 10px 10px;
}
.favorite header {
  padding: 25px 0 0;
  position: relative;
  z-index: 5;
}
.favorite header .container {
  padding-bottom: 0;
  display: flex;
  align-content: space-between;
}
@media (max-width: 959px) {
  .favorite header .container {
    flex-direction: column;
  }
}
.favorite header .favorite__header-left {
  width: 65%;
}
.favorite header .favorite__header-right {
  justify-content: right;
  display: flex;
  padding-top: 12px;
}
@media (max-width: 959px) {
  .favorite header .favorite__header-right {
    margin-bottom: 25px;
    display: none;
  }
}
.favorite__title {
  margin-top: 0px;
  vertical-align: middle;
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
}
.favorite__title .placeholder {
  display: inline-block;
  width: 120px;
  height: 30px;
  background: rgba(255, 255, 255, 0.05);
  vertical-align: middle;
  margin-bottom: 23px;
}
.favorite__title .placeholder.fn {
  width: 140px;
  margin-right: 8px;
}
.favorite__title .placeholder.ln {
  width: 180px;
}
.favorite__season-filter, .favorite__scout-filter {
  margin-left: 25px;
}
@media (max-width: 959px) {
  .favorite__season-filter, .favorite__scout-filter {
    margin-left: 0;
    margin-right: 10px;
  }
}
.favorite__season-filter .Select, .favorite__scout-filter .Select {
  max-width: 170px;
}
.favorite__season-filter .Select--single.has-value .Select-control, .favorite__scout-filter .Select--single.has-value .Select-control {
  border-bottom: none !important;
}
.favorite__season-filter .Select--single.has-value .Select-input, .favorite__season-filter .Select--single.has-value .Select-control, .favorite__scout-filter .Select--single.has-value .Select-input, .favorite__scout-filter .Select--single.has-value .Select-control {
  height: 24px;
}
.favorite__season-filter .Select--single.has-value > .Select-control .Select-value, .favorite__scout-filter .Select--single.has-value > .Select-control .Select-value {
  font-size: 15px;
  line-height: 24px;
}
.favorite__season-filter .Select--single.has-value > .Select-control .Select-value .Select-value-label, .favorite__scout-filter .Select--single.has-value > .Select-control .Select-value .Select-value-label {
  font-weight: 600;
}
.favorite__season-filter label, .favorite__scout-filter label {
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 18px;
  opacity: 0.7;
  margin-top: -2px;
  display: block;
  text-transform: uppercase;
}
/* Colors */
/* Fonts */
/* Dimensions */
.api-list-filters {
  display: flex;
}
.api-list-filters__trigger-container {
  white-space: nowrap;
}
.api-list-filters__trigger {
  position: relative;
  top: 9px;
  color: #2DC46E;
  border-bottom: 1px dotted;
  cursor: pointer;
}
.api-list-filters__fade {
  position: fixed;
  z-index: 9998;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.api-list-filters__pane {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  width: 300px;
  background-color: #263742;
}
.api-list-filters__pane .pad {
  padding: 32px;
}
.api-list-filters__filter {
  margin-top: 25px;
  position: relative;
}
.api-list-filters__filter:nth-child(1) {
  margin-top: 0;
}
.api-list-filters__filter .label {
  display: block;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
}
.api-list-filters__filter .label .js-link {
  color: #2DC46E;
  display: inline-block;
  margin-left: 10px;
  border-bottom: 1px dotted;
  cursor: pointer;
  text-transform: none;
}
.api-list-filters__filter .input .text-input {
  padding: 5px 6px;
}
.api-list-filters__filter__checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.api-list-filters__filter__checkbox .label {
  max-width: 128px;
}
.api-list-filters__clear {
  margin-top: 25px;
}
.api-list-filters__filter-date-range {
  width: 100%;
  border-bottom: 1px solid #2DC46E;
}
.api-list-filters__filter-slider {
  margin-top: 25px;
}

.api-list-filters__transition-enter.api-list-filters__fade, .api-list-filters__transition-appear.api-list-filters__fade {
  opacity: 0;
}
.api-list-filters__transition-enter.api-list-filters__pane, .api-list-filters__transition-appear.api-list-filters__pane {
  transform: translateX(300px);
}

.api-list-filters__transition-enter-active.api-list-filters__fade, .api-list-filters__transition-appear-active.api-list-filters__fade {
  opacity: 1;
  transition: opacity 350ms;
}
.api-list-filters__transition-enter-active.api-list-filters__pane, .api-list-filters__transition-appear-active.api-list-filters__pane {
  transform: translateX(0);
  transition: transform 350ms;
}

.api-list-filters__transition-leave.api-list-filters__fade, .api-list-filters__transition-leave.api-list-filters__fade {
  opacity: 1;
}
.api-list-filters__transition-leave.api-list-filters__pane, .api-list-filters__transition-leave.api-list-filters__pane {
  transform: translateX(0px);
}

.api-list-filters__transition-leave-active.api-list-filters__fade, .api-list-filters__transition-leave-active.api-list-filters__fade {
  opacity: 0;
  transition: opacity 350ms;
}
.api-list-filters__transition-leave-active.api-list-filters__pane, .api-list-filters__transition-leave-active.api-list-filters__pane {
  transform: translateX(300px);
  transition: transform 350ms;
}
/* Colors */
/* Fonts */
/* Dimensions */
.substitutions {
  display: block;
  margin: 14px auto 0;
  font-size: 0;
  -webkit-transition: width 0.5s ease-out;
  -moz-transition: width 0.5s ease-out;
  transition: width 0.5s ease-out;
}
@media (max-width: 959px) {
  .substitutions {
    margin: 4px auto 0;
  }
}
.substitutions .home,
.substitutions .away {
  display: inline-block;
  width: 50%;
  font-size: 0;
  vertical-align: top;
}
.substitutions .home {
  padding-right: 8px;
}
@media (max-width: 959px) {
  .substitutions .home {
    padding-right: 2px;
  }
}
.substitutions .home .substitution .substitution-target {
  background: rgba(32, 32, 32, 0.2);
}
.substitutions .away {
  padding-left: 8px;
}
@media (max-width: 959px) {
  .substitutions .away {
    padding-left: 2px;
  }
}
.substitutions .away .substitution .substitution-target {
  background: rgba(255, 255, 255, 0.2);
}
.substitutions .substitutions-list {
  background: #0f6630;
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: space-around;
}
@media (max-width: 959px) {
  .substitutions .substitutions-list {
    height: 38px;
  }
}
.substitutions .substitution {
  width: 14.2857142857%;
  height: 36px;
  margin-top: 6px;
  position: relative;
}
.substitutions .substitution .substitution-target {
  width: 42px;
  height: 42px;
  margin: 0px 0 0 12px;
  position: absolute;
  border-radius: 50%;
}
@media (max-width: 1379px) {
  .substitutions .substitution .substitution-target {
    width: 36px;
    height: 36px;
    margin: 6px 0 0 6px;
  }
}
@media (max-width: 959px) {
  .substitutions .substitution .substitution-target {
    height: 18px;
    width: 18px;
    margin: -3px 0 0 0;
  }
}
.substitutions .substitution .substitution-target:before {
  position: absolute;
  top: 50%;
  right: 50%;
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #36D07F;
  margin: -3px -5px 0 0;
  content: "";
}
.substitutions .substitution .position-players-list {
  bottom: 50%;
}
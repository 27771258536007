/* Colors */
/* Fonts */
/* Dimensions */
.tabs {
  font-size: 16px;
  font-weight: 300;
  line-height: 0;
  background: #3B5569;
  display: flex;
}
@media (max-width: 959px) {
  .tabs {
    font-size: 12px;
  }
}
.tabs a {
  color: #2DC46E;
  border-bottom: 2px solid #495D6A;
  line-height: 45px;
  cursor: pointer;
  padding: 10px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.5px;
  font-weight: 400;
  text-align: center;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  flex-grow: 1;
  -ms-flex-positive: 1;
}
@media (max-width: 959px) {
  .tabs a {
    line-height: 26px;
    padding: 5px 4px 0;
  }
}
.tabs a:hover {
  color: #fff;
}
.tabs a.active {
  color: #fff;
  font-weight: 700;
}
.tabs a.active {
  border-bottom-color: rgba(255, 255, 255, 0.7);
  font-weight: 700;
}
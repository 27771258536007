/* Colors */
/* Fonts */
/* Dimensions */
.admin {
  height: 100%;
}
.admin .container {
  max-width: 100%;
}
.admin .admin-nav {
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 -15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 1px solid #495D6A;
}
@media (max-width: 959px) {
  .admin .admin-nav {
    font-size: 12px;
    margin: 0 0 -8px;
  }
}
.admin .admin-nav .container {
  padding-top: 50px;
  padding-bottom: 0;
  display: flex;
}
@media (max-width: 959px) {
  .admin .admin-nav .container {
    padding-top: 25px;
  }
}
.admin .admin-nav a {
  color: rgba(255, 255, 255, 0.75);
  border-bottom: 1px solid transparent;
}
.admin .admin-nav a:hover {
  color: #fff;
}
.admin .admin-nav span {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  color: #fff;
}
.admin .admin-nav span, .admin .admin-nav a {
  display: inline-block;
  width: 50%;
  padding: 10px 15px;
  text-align: center;
  margin-bottom: -1px;
}
@media (max-width: 959px) {
  .admin .admin-nav span, .admin .admin-nav a {
    padding: 5px 8px;
  }
}
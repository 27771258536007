/* Colors */
/* Fonts */
/* Dimensions */
.change-password {
  margin-top: 20px;
}
@media (max-width: 959px) {
  .change-password {
    margin-top: 25px;
  }
}
.change-password .password-form {
  margin-top: 13px;
}
@media (max-width: 959px) {
  .change-password .password-form {
    margin-top: 7px;
  }
}
.change-password .password-form input {
  border: 1px solid transparent;
  border-bottom-color: #2DC46E;
  color: #fff;
  line-height: 18px;
  margin: 10px 1px;
  font-size: 17px;
  width: 100%;
  font-weight: 500;
  margin-bottom: 40px;
}
@media (max-width: 959px) {
  .change-password .password-form input {
    line-height: 30px;
    padding: 3px 1px;
    font-size: 14px;
  }
}
.change-password .password-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  transition: opacity 0.2s;
  opacity: 1;
}
.change-password .password-form input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  transition: opacity 0.2s;
  opacity: 1;
}
.change-password .password-form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  transition: opacity 0.2s;
  opacity: 1;
}
.change-password .password-form input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  transition: opacity 0.2s;
  opacity: 1;
}
.change-password .password-form input:focus {
  /*border-bottom-color: $primary-color;*/
}
.change-password .password-form input:focus::-webkit-input-placeholder {
  opacity: 0;
}
.change-password .password-form input:focus::-moz-placeholder {
  opacity: 0;
}
.change-password .password-form input:focus:-ms-input-placeholder {
  opacity: 0;
}
.change-password .password-form input:focus:-moz-placeholder {
  opacity: 0;
}
.change-password .password-form .error-message,
.change-password .password-form .success-message {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: -30px;
  display: none;
}
@media (max-width: 959px) {
  .change-password .password-form .error-message,
  .change-password .password-form .success-message {
    font-size: 7px;
    line-height: 10px;
    margin-top: 5px;
    margin-bottom: -15px;
  }
}
.change-password .password-form .error-message.visible,
.change-password .password-form .success-message.visible {
  display: block;
}
.change-password .password-form .error-message {
  color: red;
}
.change-password .password-form .success-message {
  color: #188538;
}
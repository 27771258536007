/* Colors */
/* Fonts */
/* Dimensions */
.reports__controls {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: stretch;
  padding: 0 25px 25px 0;
  max-width: 1023px;
}
.reports__controls__filter, .reports__controls__new {
  flex-shrink: 0;
  margin-left: 24px;
}
.reports__controls__search {
  flex-grow: 1;
}
.reports__controls .btn {
  position: relative;
  height: 44px;
  line-height: 42px;
}
.reports__controls .btn__filter {
  width: 44px;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
}
.reports__controls .btn__filter svg {
  width: 14px;
  height: 42px;
  stroke: currentColor;
  fill: none;
}
.reports__controls .btn__filter__num {
  text-align: center;
  position: absolute;
  display: block;
  right: -11px;
  bottom: -11px;
  border-radius: 11px;
  background: #d33a61;
  color: #FFF;
  font-size: 12px;
  line-height: 22px;
  width: 22px;
  height: 22px;
}
.reports__controls .btn__filter.active {
  color: #FFF;
  background: #35B374;
}
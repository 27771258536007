/* Colors */
/* Fonts */
/* Dimensions */
.default-lineup-controls {
  position: relative;
  height: 0;
  margin: 0 auto;
  display: block;
  font-size: 14px;
  line-height: 20px;
}
@media (max-width: 959px) {
  .default-lineup-controls {
    font-size: 12px;
    line-height: 16px;
  }
}
.default-lineup-controls .save-default-lineup {
  position: absolute;
  left: 0;
  top: 10px;
}
@media (max-width: 959px) {
  .default-lineup-controls .save-default-lineup {
    top: 0px;
  }
}
.default-lineup-controls .save-default-lineup_away {
  left: auto;
  right: 0;
}
/* Colors */
/* Fonts */
/* Dimensions */
.billing .settings-h2 {
  text-transform: uppercase;
  margin-top: 60px;
  margin-bottom: 20px;
}
.billing .settings-h2:nth-child(1) {
  margin-top: 0;
}
.billing .radio-group {
  margin: 30px 20px 0px 20px;
  display: block;
}
.billing .radio-group::after {
  clear: both;
  content: "";
  display: table;
}
.billing .pseudo-link {
  display: block;
  font-weight: bold;
  cursor: pointer;
  padding: 15px 20px;
  text-transform: uppercase;
  border: 1px solid #188538;
  float: left;
  border-radius: 2px;
}
.billing .pseudo-link.active {
  color: #FFF;
  background: #188538;
  border: 1px solid #188538;
}
.billing .pseudo-link + .pseudo-link {
  border-left: none;
}
@media (max-width: 959px) {
  .billing .pseudo-link {
    padding: 7px 15px;
    font-size: 14px;
  }
}
.billing .billing-col {
  margin-top: 40px;
  padding-top: 50px;
  border-top: 1px solid #334a59;
}
.billing .billing-col h4 {
  font-weight: bold;
  margin-bottom: 2px;
  margin-top: 30px;
}
.billing .billing-col svg {
  width: 45px;
  height: 33px;
  margin-right: 12px;
  margin-top: 10px;
}
.billing .billing-col svg.sepa {
  background: #F7F7F7;
  padding: 0px 11px;
  width: 60px;
  border-radius: 6px;
}
.billing .billing-columns {
  display: flex;
  flex-wrap: wrap;
}
.billing .billing-columns .billing-col {
  width: calc(33% - 18px);
  margin-left: 35px;
  margin-bottom: 35px;
  min-width: 320px;
}
@media (max-width: 959px) {
  .billing .billing-columns .billing-col {
    width: 100%;
  }
}
.billing .billing-columns .billing-col p {
  margin-top: 5px;
}
.billing .billing-columns .billing-col p:nth-child(1) {
  margin-top: 0;
}
.billing .billing-columns .billing-col:nth-child(odd) {
  margin-left: 0;
}
.billing .payment .payment-type {
  font-size: 22px;
  line-height: 160%;
  vertical-align: top;
  display: block;
}
.billing .payment .payment-type svg {
  margin-top: 0px;
  vertical-align: top;
}
.billing .payment p.payment-next {
  opacity: 0.7;
}
.billing .payment p.payment-users {
  font-size: 20px;
  margin-top: 40px;
}
.billing .payment .payment-expires {
  font-size: 16px;
  opacity: 0.7;
  display: block;
  margin: -5px 0px 10px 56px;
  line-height: 110%;
}
.billing .invoice-item {
  display: block;
}
.billing .invoice-item .invoice-new {
  display: inline-block;
  padding: 1px 5px;
  font-size: 11px;
  border-radius: 2px;
  color: #188538;
  border: 1px solid #188538;
  margin-right: 5px;
  vertical-align: middle;
  text-transform: uppercase;
  position: relative;
  top: -2px;
}
.billing .link {
  color: #2DC46E;
  cursor: pointer;
}
.billing .link.link-red {
  margin-top: 10px;
  font-size: 12px;
  line-height: 38px;
  white-space: nowrap;
  padding: 0px 20px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #d33a61;
  color: white;
  background: inherit;
}
.billing .link.link-red:hover {
  background: #d33a61;
}
.billing .link:hover {
  color: #FFF;
}
.billing .invoices-list .invoice-item {
  display: inline-block;
  white-space: nowrap;
  margin: 10px 25px 0 0;
}
.billing__trial-end {
  margin-top: 35px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.billing-confirm-settings-h2 {
  margin: 0 0 15px;
  font-weight: 700;
  font-size: 22px;
  color: rgba(255, 255, 255, 0.95);
}
@media (max-width: 959px) {
  .billing-confirm-settings-h2 {
    font-size: 14px;
  }
}
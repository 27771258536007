/* Colors */
/* Fonts */
/* Dimensions */
.area-players {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.area-players .get-started {
  position: absolute;
  top: 0;
  right: -50px;
  bottom: -144px;
  left: -20px;
  background: rgba(38, 55, 66, 0.9);
  z-index: 1;
  font-size: 18px;
}
@media (max-width: 959px) {
  .area-players .get-started {
    font-size: 14px;
    bottom: -86px;
    right: -32px;
  }
}
.area-players .get-started .align {
  display: inline-block;
  text-align: left;
  margin-top: 10%;
}
@media (max-width: 959px) {
  .area-players .get-started .align {
    margin-top: 5%;
  }
}
.area-players .get-started h2 {
  font-size: 28px;
  font-weight: bold;
}
@media (max-width: 959px) {
  .area-players .get-started h2 {
    font-size: 18px;
  }
}
.area-players .get-started ol {
  list-style-type: decimal;
  padding-left: 20px;
  margin-top: 30px;
}
@media (max-width: 959px) {
  .area-players .get-started ol {
    margin-top: 10px;
    padding-left: 15px;
  }
}
.area-players .get-started ol li {
  margin-top: 10px;
}
.area-players .get-started .btn {
  margin-top: 40px;
  padding: 0 50px;
}
@media (max-width: 959px) {
  .area-players .get-started .btn {
    margin-top: 15px;
  }
}
/* Colors */
/* Fonts */
/* Dimensions */
.pdf {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background: #263742;
  will-change: transform, opacity;
  z-index: 1;
}
.pdf .pdf-top-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 20px;
  background: #1C2830;
  z-index: 6;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 959px) {
  .pdf .pdf-top-bar {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    padding: 13px 34px 13px 13px;
  }
}
.pdf .pdf-top-bar .btn {
  padding: 0px 60px;
  flex-shrink: 0;
}
.pdf .pdf-top-bar .btn svg {
  vertical-align: middle;
  position: relative;
  top: -2px;
  width: 21px;
  height: 21px;
}
@media screen and (max-width: 959px) {
  .pdf .pdf-top-bar .btn svg {
    width: 14px;
    height: 14px;
    top: -1px;
  }
}
.pdf .pdf-top-bar .btn.notify, .pdf .pdf-top-bar .btn.mark-incomplete, .pdf .pdf-top-bar .btn.go-to-match {
  margin-left: 16px;
}
.pdf .pdf-top-bar .btn.mark-incomplete {
  background: #d33a61;
  border-color: #d33a61;
  color: #FFF;
}
@media screen and (max-width: 959px) {
  .pdf .pdf-top-bar .btn {
    padding: 0 20px;
  }
}
.pdf .pdf-top-bar .text {
  flex-shrink: 1;
  flex-grow: 0;
  display: block;
  margin-left: 30px;
  text-transform: uppercase;
}
.pdf .pdf-top-bar .text svg {
  vertical-align: middle;
  position: relative;
  top: -2px;
  width: 21px;
  height: 21px;
  margin-right: 4px;
}
@media screen and (max-width: 959px) {
  .pdf .pdf-top-bar .text svg {
    width: 14px;
    height: 14px;
    top: -1px;
  }
}
.pdf .pdf__close {
  position: absolute;
  top: 28px;
  right: 28px;
  cursor: pointer;
  z-index: 1;
}
@media screen and (max-width: 959px) {
  .pdf .pdf__close {
    top: 16px;
    right: 16px;
  }
}
.pdf .pdf__close svg {
  width: 24px;
  height: 24px;
}
@media screen and (max-width: 959px) {
  .pdf .pdf__close svg {
    width: 18px;
    height: 18px;
  }
}
.pdf .pdf-page {
  background: #fff;
  width: 1024px;
  min-height: 1449px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  margin: 20px auto;
  color: #000;
  padding: 100px;
}
@media screen and (max-width: 959px) {
  .pdf .pdf-page {
    width: 682px;
    min-height: 966px;
    margin: 10px auto;
    padding: 66px;
  }
  .pdf .pdf-page.single {
    width: 772px;
  }
}
.pdf .pdf-page:first-child {
  margin-top: 100px;
}
@media screen and (max-width: 959px) {
  .pdf .pdf-page:first-child {
    margin-top: 66px;
  }
}
.pdf .pdf-list-player {
  position: relative;
  font-size: 16px;
  padding-left: 30px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 24px;
  margin-bottom: 7px;
  padding-bottom: 5px;
}
@media screen and (max-width: 959px) {
  .pdf .pdf-list-player {
    font-size: 11px;
    padding-left: 22px;
    line-height: 18px;
    margin-bottom: 6px;
  }
}
.pdf .pdf-list-player .player__number {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #000;
  font-weight: 500;
  font-size: 14px;
}
@media screen and (max-width: 959px) {
  .pdf .pdf-list-player .player__number {
    width: 18px;
    height: 18px;
    line-height: 16px;
    font-size: 10px;
  }
}
.pdf .pdf-list-player .player__name {
  font-weight: 600;
}
.pdf .pdf-list-player .player__position {
  font-weight: 500;
}
.pdf .pdf-list-player.home .player__number {
  color: #fff;
  background: #252525;
}
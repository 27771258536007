/* Colors */
/* Fonts */
/* Dimensions */
.add-player-fade {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1000;
}

.add-player-pane {
  z-index: 1001;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 25%;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  background: #273A45;
  box-shadow: 0 2px 16px 1px rgba(0, 0, 0, 0.1);
  height: 100%;
}
.add-player-pane .header {
  flex-grow: 0;
  flex-shrink: 0;
  background: #2b3f4c;
}
.add-player-pane .header h4 {
  font-size: 18px;
  line-height: 24px;
  /*text-transform: uppercase;*/
  font-weight: bold;
  padding: 25px 20px 0;
  white-space: nowrap;
}
.add-player-pane .header .limit {
  font-size: 14px;
  line-height: 18px;
  padding: 2px 20px;
  opacity: 0.5;
}
.add-player-pane .header .limit.error {
  color: #d33a61;
  opacity: 1;
}
.add-player-pane .header input {
  padding: 18px 0px 10px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #101a20;
}
.add-player-pane .add-new-player {
  padding: 0 20px;
  margin-top: 35px;
}
.add-player-pane .players-list {
  flex-grow: 1;
  height: calc(100% - 122px);
}
.add-player-pane .players-list ul li {
  border-top: 1px solid #294254;
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  user-select: none;
}
.add-player-pane .players-list ul li:hover {
  background: #1e2c35;
}
.add-player-pane .players-list ul li > span {
  width: 100%;
  height: 40px;
  padding: 7px 20px;
  display: inline-block;
}
.add-player-pane .players-list ul li .team-image {
  width: 17px;
  height: 22px;
  position: relative;
  top: -2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  background-size: contain;
}
.add-player-pane .players-list .no-results {
  opacity: 0.4;
  padding: 10px 20px;
}
.add-player-pane .custom-player-indicator {
  display: inline-block;
  padding: 2px 5px;
  font-size: 9px;
  line-height: 11px;
  vertical-align: super;
  text-transform: uppercase;
  background: #188538;
  color: #FFF;
  border-radius: 2px;
  margin-left: 4px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.favorites-list__wrapper {
  margin-top: 50px;
  position: relative;
}
.favorites-list__wrapper h2 {
  font-size: 19px;
  font-weight: bold;
  margin: 0 0 0px;
}
.favorites-list__wrapper .favorites-list {
  margin: 0;
  min-width: 740px;
}
.favorites-list__wrapper .favorites-list th, .favorites-list__wrapper .favorites-list td {
  text-overflow: ellipsis;
  vertical-align: middle;
}
@media screen and (max-width: 1024px) {
  .favorites-list__wrapper .favorites-list th, .favorites-list__wrapper .favorites-list td {
    font-size: 13px;
  }
  .favorites-list__wrapper .favorites-list th .favorite-player-name, .favorites-list__wrapper .favorites-list td .favorite-player-name {
    font-size: 14px;
  }
}
.favorites-list__wrapper .favorites-list .col__last-name {
  position: relative;
  padding-right: 10px !important;
}
.favorites-list__wrapper .favorites-list .col__position-name {
  width: 90px;
}
.favorites-list__wrapper .favorites-list .col__fav {
  width: 40px;
}
.favorites-list__wrapper .favorites-list .col__rating {
  width: 145px;
}
.favorites-list__wrapper .favorites-list .col__birth-date {
  width: 40px;
}
.favorites-list__wrapper .favorites-list .col__join-date {
  width: 95px;
}
@media (max-width: 1024px) {
  .favorites-list__wrapper .favorites-list .col__join-date {
    display: none;
  }
}
.favorites-list__wrapper .favorites-list .col__contract {
  width: 90px;
}
.favorites-list__wrapper .favorites-list .col__final-rating {
  width: 55px;
}
.favorites-list__wrapper .favorites-list .col__country {
  width: 90px;
}
.favorites-list__wrapper .favorites-list .col__menu {
  width: 40px;
  position: relative;
}
.favorites-list__wrapper .favorites-list .add-to-favorites {
  position: relative;
  top: -1px;
}
.favorites-list__wrapper .favorites-list .favorite-selector {
  cursor: pointer;
  line-height: 25px;
}
.favorites-list__wrapper .favorites-list .favorite-selector svg {
  width: 21px;
  height: 21px;
  display: inline-block;
  position: relative;
  top: -2px;
  vertical-align: middle;
}
.favorites-list__wrapper .favorites-list .favorite-selector .chevron-down {
  vertical-align: middle;
  width: 15px;
  height: 15px;
  position: relative;
  fill: #FFF !important;
  stroke: none !important;
  top: -1px;
}
.favorites-list__wrapper .fav-td {
  width: 44px;
}
.favorites-list__wrapper .fav-td .favorite-change {
  opacity: 0.7;
}
/* Colors */
/* Fonts */
/* Dimensions */
.add-from-db .control-player-info {
  border-bottom: 0;
  margin: 0 -15px;
  font-size: 17px;
  min-height: 115px;
}
@media (max-width: 959px) {
  .add-from-db .control-player-info {
    margin: 0 -8px;
    font-size: 13px;
    min-height: 57px;
  }
}
.add-from-db .control-player-info > * {
  font-size: 15px;
}
@media (max-width: 959px) {
  .add-from-db .control-player-info > * {
    font-size: 12px;
  }
}
.add-from-db .add-from-db__title {
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}
@media (max-width: 959px) {
  .add-from-db .add-from-db__title {
    font-size: 11px;
    margin-bottom: 5px;
  }
}
.add-from-db .Select-placeholder, .add-from-db .Select-value-label {
  font-size: 14px;
}
html, body {
	height: 100vh;
    background: darken($color_Bg,7%);
    overflow: hidden;
    position: fixed;
    top: 0;
    left:0;
    bottom: 0;
    right: 0;

    .to-landscape,  {
        display: none;
        @include position(fixed, 0);
        background: $color_Bg;
        color: white;
        z-index: 255;

        @media (max-width: $screen-sm-max) and (orientation: portrait){
            display: block;
        }

        svg {
            width: 100px;
            height: 100px;
            @include position(fixed, 45% none none 50%);
            margin: -50px 0 0 -50px;
        }

        .rotate-text {
            width: 250px;
            height: 100px;
            @include position(fixed, 45% none none 50%);
            margin: 75px 0 0 -100px;
            font-size: 20px;
            font-weight: 300;
        }
    }
}

/* Colors */
/* Fonts */
/* Dimensions */
.reports-overview .today-date {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
}
.reports-overview .overview-heading {
  white-space: nowrap;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #FFFFFF;
  margin-bottom: 16px;
}
.reports-overview .overview-heading a {
  color: #FFF;
}
.reports-overview .overview-heading a:hover {
  color: #35B374;
}
.reports-overview .overview-heading .chevron-right {
  vertical-align: middle;
  stroke: currentColor;
  width: 24px;
  height: 24px;
  margin-left: 8px;
}
.reports-overview__col {
  width: 320px;
  overflow: hidden;
  margin-left: 18px;
}
.reports-overview__col:nth-child(1) {
  margin-left: 0;
}
.reports-overview__block {
  display: flex;
  margin-top: 48px;
  flex-wrap: wrap;
}
.reports-overview__block__upcoming {
  margin-top: 24px;
  display: block;
}
.reports-overview__matches {
  margin-top: 24px;
}
.reports-overview__matches__row {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  height: 130px;
}
.reports-overview__matches__column {
  min-height: 406px;
}
.reports-overview__matches__column p {
  color: rgba(255, 255, 255, 0.7);
}
.reports-overview__matches__column .demo-message {
  margin-top: 40px;
  margin-left: 46px;
}
.reports-overview__matches__column .demo-message.player-demo-message {
  margin-top: 56px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.player-controls {
  width: 350px;
  height: 350px;
  margin: -175px 0 0 -175px;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: default;
}
@media (max-width: 959px) {
  .player-controls {
    width: 240px;
    height: 240px;
    margin: -120px 0 0 -120px;
  }
}
.player-controls.saved + .player__number {
  background-color: #2DC46E;
}
/* Colors */
/* Fonts */
/* Dimensions */
.confirm-modal {
  width: 437px;
  background: #1B262E;
  position: relative;
}
.confirm-modal .close-icon {
  position: absolute;
  top: 28px;
  right: 28px;
  width: 8px;
  height: 8px;
  cursor: pointer;
}
.confirm-modal .body {
  margin: 0 0 20px;
}
.confirm-modal .buttons {
  text-align: right;
}
.confirm-modal .buttons .btn {
  min-width: 124px;
  margin-left: 20px;
  margin-top: 20px;
  padding: 0 30px;
}
.confirm-modal .buttons .btn:disabled {
  background: grey;
}
.confirm-modal .confirm-body p {
  margin-top: 20px;
  line-height: 145%;
}
.confirm-modal .confirm-body .h2 {
  font-weight: 700;
  font-size: 19px;
  margin: 0 0 1px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.95);
}
@media (max-width: 959px) {
  .confirm-modal .confirm-body .h2 {
    font-size: 14px;
  }
}
.confirm-modal .confirm-body .with-checkbox {
  position: relative;
  padding-left: 35px;
}
.confirm-modal .confirm-body .checkbox {
  position: absolute;
  left: 0;
  top: 6px;
  width: 24px;
  height: 24px;
  border: 1px solid #FFF;
  cursor: pointer;
}
.confirm-modal .confirm-body .checkbox svg {
  width: 20px;
  height: 20px;
  display: none;
}
.confirm-modal .confirm-body .checkbox.checked svg {
  display: block;
}

@media (max-width: 959px) {
  .modal-overlay {
    overflow-y: scroll;
    padding-bottom: 10%;
  }
}

.ReactModalPortal {
  position: fixed;
  z-index: 2147483648 !important;
}
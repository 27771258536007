/* Colors */
/* Fonts */
/* Dimensions */
.players__save-default-lineup-btn {
  border-top: 1px solid #1C2830;
}
.players__save-default-lineup {
  background: #253540;
  color: #2DC46E;
  position: relative;
  padding: 15px 10px 20px 40px;
  display: block;
  cursor: pointer;
  vertical-align: middle;
}
.players__save-default-lineup svg {
  box-sizing: content-box;
  fill: none;
  stroke-width: 4px;
  stroke: #2DC46E;
  display: inline-block;
  width: 22px;
  height: 16px;
  vertical-align: middle;
  position: absolute;
  top: 26px;
  left: 10px;
  margin-top: -7px;
}
.players__save-default-lineup:hover {
  color: #FFF;
}
.players__save-default-lineup:hover svg {
  stroke: #FFF;
}
.players .players-group .group-action {
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: #253540;
  padding: 12px 10px 12px 39px;
  position: relative;
  display: block;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(21, 28, 33, 0.3), 0px -6px 8px rgba(21, 28, 33, 0.1);
  z-index: 1;
}
.players .players-group .group-action.down {
  box-shadow: none;
}
@media (max-width: 959px) {
  .players .players-group .group-action {
    font-size: 11px;
    padding: 7px 5px 7px 22px;
  }
}
.players .players-group .group-action svg {
  width: 11px;
  height: 11px;
  position: absolute;
  top: 50%;
  left: 17px;
  margin-top: -6px;
}
@media (max-width: 959px) {
  .players .players-group .group-action svg {
    width: 8px;
    height: 8px;
    margin-top: -5px;
    left: 7px;
  }
}
.players .players-group .group-list {
  overflow: hidden;
  -webkit-transition: max-height 0.2s ease-out;
  -moz-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out;
}
/* Colors */
/* Fonts */
/* Dimensions */
.watchlist-icon {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.watchlist-icon svg {
  fill: currentColor;
  width: 40px;
  height: 40px;
  margin: 20px;
}
.watchlist-icon.small {
  width: 32px;
  height: 32px;
}
.watchlist-icon.small svg {
  width: 24px;
  height: 24px;
  margin: 4px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.player-table-menu {
  position: relative;
  width: 24px;
  z-index: 2;
}
.player-table-menu.active {
  z-index: 3;
}
.player-table-menu__button {
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 4px;
  position: relative;
}
.player-table-menu__button:before, .player-table-menu__button:after {
  content: "";
}
.player-table-menu__button i, .player-table-menu__button:before, .player-table-menu__button:after {
  position: absolute;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #2DC46E;
  top: 10px;
}
.player-table-menu__button i {
  left: 10px;
}
.player-table-menu__button:before {
  left: 4px;
}
.player-table-menu__button:after {
  left: 16px;
}
.player-table-menu__button:hover, .player-table-menu__button.active {
  background: #2DC46E;
}
.player-table-menu__button:hover i, .player-table-menu__button:hover:before, .player-table-menu__button:hover:after, .player-table-menu__button.active i, .player-table-menu__button.active:before, .player-table-menu__button.active:after {
  background: #FFF;
}
.player-table-menu__list-wrapper {
  position: absolute;
  right: 0;
  top: 24px;
  min-width: 180px;
  padding-top: 8px;
}
.player-table-menu__list {
  background: #FFF;
  border-radius: 4px;
  border: 1px solid #A4A6A4;
  color: #2D3B46;
  white-space: nowrap;
}
.player-table-menu__list li {
  white-space: nowrap;
  padding: 6px 8px;
}
.player-table-menu__list li .icon-container {
  width: 18px;
  height: 18px;
  line-height: 18px;
  vertical-align: middle;
  margin-right: 4px;
  position: relative;
  display: inline-block;
  text-align: center;
  top: -2px;
}
.player-table-menu__list li svg {
  width: 18px;
  height: 18px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.player-table-menu__list li svg.close-icon {
  width: 10px;
  height: 10px;
}
.player-table-menu__list li:nth-child(1) {
  border-radius: 4px 4px 0 0;
}
.player-table-menu__list li:nth-last-child(1) {
  border-radius: 0 0 4px 4px;
}
.player-table-menu__list li:hover {
  background: #e4fff1;
  cursor: pointer;
}
/* Colors */
/* Fonts */
/* Dimensions */
.favorites .empty-message {
  font-size: 18px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.75);
  margin: 55px 0 25px;
  width: 65%;
  text-align: left;
  opacity: 0.8;
  font-weight: 400;
  letter-spacing: 0.2px;
}
.favorites .demo-message {
  margin: 60px 0 25px 120px;
}
.favorites .demo-player {
  display: block;
  position: relative;
  padding-left: 42px;
}
.favorites .demo-player .player-image {
  width: 36px;
  height: 44px;
  margin-top: -23px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 0;
}
@media (max-width: 959px) {
  .favorites .demo-player .player-image {
    width: 24px;
    height: 28px;
    margin-top: -15px;
  }
}
.favorites .tri {
  color: #FFF;
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  position: absolute;
  left: 0;
  margin-left: 5px;
  top: 50%;
  margin-top: -3px;
  font-weight: 600;
}
.favorites .tri.asc {
  border-bottom: none;
  border-top: 4px solid #FFF;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.favorites .tri.desc {
  border-top: none;
  border-bottom: 4px solid #FFF;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.favorites .favorite-player-name {
  position: relative;
  padding-left: 48px;
  font-size: 16px;
  line-height: 120%;
  display: table-caption;
  margin-bottom: -5px;
  min-height: 38px;
}
.favorites .favorite-player-name .favorite-player-name_last {
  font-weight: bold;
}
@media (max-width: 959px) {
  .favorites .favorite-player-name {
    padding-left: 30px;
    font-size: 14px;
    line-height: 120%;
  }
}
.favorites .favorite-player-name .player-image {
  width: 36px;
  height: 44px;
  margin-top: -23px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 0;
}
@media (max-width: 959px) {
  .favorites .favorite-player-name .player-image {
    width: 24px;
    height: 28px;
    margin-top: -15px;
  }
}
.favorites .favorite-player-team {
  display: inline-block;
  position: relative;
  padding-left: 23px;
}
@media (max-width: 959px) {
  .favorites .favorite-player-team {
    line-height: 120%;
  }
}
.favorites .favorite-player-team .team-image {
  width: 17px;
  height: 22px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
}
.favorites .favorite-rate {
  display: inline-block;
  position: relative;
  padding-left: 20px;
}
.favorites .favorite-rate .legend {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 0;
}
.favorites .create-icon {
  width: 11px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: 6px;
}
.favorites .favorites-list {
  line-height: 18px;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  margin: 20px 0 0;
  border-collapse: separate;
  border-spacing: 0 4px;
  table-layout: fixed;
}
.favorites .favorites-list__filters {
  position: relative;
  z-index: 51;
  display: flex;
  margin-left: 24px;
}
@media (max-width: 959px) {
  .favorites .favorites-list__filters.empty {
    display: none;
  }
}
.favorites .favorites-list__filters .filter-container {
  margin-left: 30px;
  width: 310px;
  padding-left: 30px;
  border-left: 1px solid #495158;
  display: flex;
}
.favorites .favorites-list__filters .filter-container.empty {
  border-left: none;
  margin-left: 0;
  padding-left: 0;
}
.favorites .favorites-list__filters .filter-container.empty .btn {
  width: 200px;
}
.favorites .favorites-list__filters .filter-container + .filter-container {
  border-left: none;
}
@media (max-width: 1200px) {
  .favorites .favorites-list__filters .add-new-player-container {
    display: none;
  }
}
.favorites .favorites-list__filters .filter {
  margin-left: 40px;
  min-width: 150px;
  user-select: none;
}
.favorites .favorites-list__filters .filter__input {
  margin-top: 4px;
}
.favorites .favorites-list__filters .filter__position {
  min-width: 140px;
}
.favorites .favorites-list__filters .filter label {
  text-transform: uppercase;
  color: white;
  opacity: 0.6;
  font-size: 13px;
  /*line-height: 20px;*/
  letter-spacing: 0.5px;
}
.favorites .favorites-list__filters .filter .btn.search-player {
  min-width: 240px;
}
.favorites .favorites-list__filters .filter .input-range {
  margin-top: 25px;
}
.favorites .favorites-list__filters .filter .input-range .input-range__label--min, .favorites .favorites-list__filters .filter .input-range .input-range__label--max {
  display: none;
}
.favorites .favorites-list__filters .filter .input-range .input-range__label--value {
  top: -2.2rem;
  color: #2DC46E;
}
.favorites .favorites-list__filters .filter .input-range .input-range__label--value .input-range__label-container {
  border-radius: 3px;
  padding: 0 2px;
  position: relative;
  background: rgb(38, 55, 66);
}
.favorites .favorites-list__filters .filter .input-range .input-range__label--value .input-range__label-container:before {
  position: absolute;
  height: 14px;
  width: 15px;
  right: 100%;
  top: 0;
  bottom: 0;
  content: "";
  background: linear-gradient(to right, rgba(38, 55, 66, 0) 0%, rgb(38, 55, 66) 100%);
}
.favorites .favorites-list__filters .filter .input-range .input-range__label--value .input-range__label-container i {
  font-style: normal;
  /*color: rgba( 255, 255,255, .75);*/
  font-weight: 400;
  opacity: 0.8;
}
.favorites .favorites-list__filters .filter .input-range .input-range__track--active {
  background: #2DC46E;
}
.favorites .favorites-list__filters .filter .input-range .input-range__slider {
  background: #263742;
  border-color: #2DC46E;
  width: 26px;
  height: 26px;
  margin-left: -11px;
  margin-top: -13px;
}
.favorites .favorites-list__filters .filter .input-range .input-range__track--background {
  background: rgba(255, 255, 255, 0.3);
}
.favorites .favorites-list__filters .filter:nth-child(1) {
  margin-left: 0;
}
.favorites .favorites-list__filters .filter__checkbox {
  min-width: 160px;
  margin-left: 25px;
  position: relative;
  padding-left: 25px;
  /*text-transform: uppercase;*/
  /*letter-spacing: 1px;*/
  font-size: 15px;
  margin-top: 25px;
  opacity: 0.7;
  color: #2DC46E;
}
.favorites .favorites-list__filters .filter__checkbox.checked {
  opacity: 1;
}
.favorites .favorites-list__filters .filter .checkbox_label {
  margin-left: 6px;
  display: block;
  line-height: 130%;
  width: 80px;
}
.favorites .favorites-list__filters .filter .checkbox {
  border: 1px solid #2DC46E;
  width: 24px;
  height: 24px;
  top: -2px;
}
.favorites .favorites-list__filters .filter .checkbox svg {
  position: absolute;
  left: 3px;
  top: 3px;
  width: 16px;
  height: 16px;
}
.favorites .favorites-list__filters .filter.select-scout {
  margin-left: 0px;
  position: relative;
  z-index: 1000;
}
@media (max-width: 959px) {
  .favorites .favorites-list {
    line-height: 13px;
    font-size: 11px;
  }
}
.favorites .favorites-list th, .favorites .favorites-list td {
  text-align: left;
  padding: 12px 4px 15px;
  border: none;
  /*border-bottom: 1px solid rgba(#fff, .1);*/
  vertical-align: top;
}
@media (max-width: 959px) {
  .favorites .favorites-list th, .favorites .favorites-list td {
    padding: 8px 2px;
  }
}
.favorites .favorites-list th:first-child, .favorites .favorites-list td:first-child {
  padding: 8px 0px 8px 8px;
}
@media (max-width: 959px) {
  .favorites .favorites-list th:first-child, .favorites .favorites-list td:first-child {
    padding: 8px 0px 8px 8px;
  }
}
.favorites .favorites-list th:last-child, .favorites .favorites-list td:last-child {
  padding-right: 10px;
}
@media (max-width: 959px) {
  .favorites .favorites-list th:last-child, .favorites .favorites-list td:last-child {
    padding-right: 10px;
  }
}
.favorites .favorites-list th.controls-col, .favorites .favorites-list td.controls-col {
  text-align: right;
  width: 150px;
  white-space: nowrap;
}
@media (max-width: 959px) {
  .favorites .favorites-list th.controls-col, .favorites .favorites-list td.controls-col {
    width: 75px;
  }
}
.favorites .favorites-list tbody tr:last-child th, .favorites .favorites-list tbody tr:last-child td {
  border-bottom: none;
}
.favorites .favorites-list tbody tr {
  background: #2a3c48;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.favorites .favorites-list tbody td:first-child {
  padding: 0px;
}
@media (max-width: 959px) {
  .favorites .favorites-list tbody td:first-child {
    padding: 0px;
  }
}
.favorites .favorites-list th {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
  opacity: 0.6;
  position: relative;
  line-height: 130%;
  padding: 8px 4px;
  vertical-align: bottom;
}
.favorites .favorites-list th.sorted {
  opacity: 1;
  font-weight: 400;
  padding-left: 14px;
}
.favorites .favorites-list th:hover {
  cursor: pointer;
  opacity: 1;
}
@media (max-width: 959px) {
  .favorites .favorites-list th {
    font-size: 11px;
  }
}
.favorites .favorites-list td {
  font-size: 15px;
  margin-bottom: 4px;
}
@media (max-width: 959px) {
  .favorites .favorites-list td {
    font-size: 14px;
  }
}
.favorites .favorites-list .align {
  display: block;
}
.favorites .favorite-change {
  cursor: pointer;
}
.favorites .favorite-change svg {
  stroke: currentColor;
  width: 22px;
  height: 22px;
}
@media (max-width: 959px) {
  .favorites .favorite-change svg {
    width: 12px;
    height: 12px;
  }
}
.favorites .favorite-change:hover svg {
  fill: transparent;
}
.favorites .favorites-header {
  display: flex;
  justify-content: space-between;
  position: relative;
}
@media (max-width: 959px) {
  .favorites .favorites-header.empty {
    margin-bottom: 80px;
  }
}
.favorites .favorites-header h3 {
  vertical-align: middle;
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
}
.favorites .favorites-header .btn.not-empty {
  right: 0;
  position: relative;
  top: -7px;
}
.favorites .favorites-header .btn.empty {
  position: absolute;
  bottom: -54px;
  width: 200px;
}
.favorites .favorites-header .player-search {
  flex: auto;
}
.favorites .add-new-player,
.favorites .search-player {
  margin-left: 24px;
  white-space: nowrap;
}
.favorites .players-infinite-loader {
  position: absolute;
  top: 72px;
  right: 0;
  bottom: 0;
  left: 26px;
  margin-top: 50px;
}
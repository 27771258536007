/* Colors */
/* Fonts */
/* Dimensions */
@media (max-width: 959px) {
  .add-player-action {
    height: 32px;
  }
}
.add-player-action:nth-child(even) {
  background-color: #2C3E4A;
}
.add-player-action .add-btn {
  font-weight: 500;
  font-size: 16px;
  position: relative;
  padding: 15px 10px 20px 40px;
  display: block;
  cursor: pointer;
  background-color: #253540;
  color: #2DC46E;
}
.add-player-action .add-btn:hover {
  color: white;
}
@media (max-width: 959px) {
  .add-player-action .add-btn {
    font-size: 12px;
    padding: 8px 5px 8px 30px;
  }
}
.add-player-action .add-btn svg {
  position: absolute;
  top: 26px;
  left: 14px;
  margin-top: -7px;
  width: 15px;
  height: 15px;
}
@media (max-width: 959px) {
  .add-player-action .add-btn svg {
    left: 8px;
    margin-top: -16px;
    width: 12px;
    height: 12px;
  }
}
/* Colors */
/* Fonts */
/* Dimensions */
.add-watchlist-fade {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1000;
}

.add-watchlist-pane {
  z-index: 1001;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 25%;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  background: #273A45;
  box-shadow: 0 2px 16px 1px rgba(0, 0, 0, 0.1);
  height: 100%;
}
.add-watchlist-pane .header {
  flex-grow: 0;
  flex-shrink: 0;
  background: #2b3f4c;
}
.add-watchlist-pane .header h4 {
  font-size: 20px;
  line-height: 24px;
  /*text-transform: uppercase;*/
  font-weight: bold;
  padding: 25px 20px 0;
}
.add-watchlist-pane .header input {
  padding: 18px 0px 10px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #101a20;
}
.add-watchlist-pane .add-new-watchlist {
  padding: 0 20px;
  margin-top: 35px;
}
.add-watchlist-pane .watchlists-list {
  flex-grow: 1;
  height: calc(100% - 122px);
}
.add-watchlist-pane .watchlists-list ul li {
  border-top: 1px solid #294254;
  height: 40px;
  padding: 8px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  user-select: none;
}
.add-watchlist-pane .watchlists-list ul li:hover {
  background: #1e2c35;
}
.add-watchlist-pane .watchlists-list ul li > span {
  width: 100%;
  height: 40px;
  padding: 7px 20px;
  display: inline-block;
}
.add-watchlist-pane .watchlists-list .no-results {
  opacity: 0.4;
  padding: 10px 20px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.admin-users-table {
  border: none;
}
.admin-users-table th, .admin-users-table td {
  border: none;
}
.admin-users-table td {
  will-change: backround-color;
  transition: background-color 0.15s ease-out;
}
.admin-users-table th {
  padding: 0 10px 5px;
  font-size: 15px;
  line-height: 21px;
  font-weight: bold;
}
.admin-users-table td {
  padding: 10px 15px;
  background: #2a3c48;
}
.admin-users-table td .reset-user-password-link {
  margin-top: 10px;
}
.admin-users-table td p {
  margin: 0 0 5px;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
}
.admin-users-table td p .label {
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.65);
}
.admin-users-table td:nth-child(1) {
  border-radius: 4px 0 0 4px;
}
.admin-users-table td:nth-last-child(1) {
  border-radius: 0 4px 4px 0;
}
.admin-users-table tr {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.admin-users-table tr.empty, .admin-users-table tr.head {
  box-shadow: none;
}
.admin-users-table tr:hover td, .admin-users-table tr.selected td {
  background: #395263;
}
.admin-users-table tr:hover.empty, .admin-users-table tr:hover.head, .admin-users-table tr.selected.empty, .admin-users-table tr.selected.head {
  background: none;
}
.admin-users-table tr.disabled td {
  background: rgba(125, 0, 0, 0.1);
}
.admin-users-table tr.disabled:hover td, .admin-users-table tr.disabled.selected td {
  background: rgba(125, 0, 0, 0.3);
}
.admin-users-table tr.empty td {
  padding: 0;
  background: none;
  height: 5px;
}
.admin-users-table tr.selected td {
  background: #395263;
}
.admin-users-table .add-user {
  white-space: nowrap;
}
.admin-users-table .add-user .btn {
  position: relative;
  line-height: 24px;
  top: -6px;
}
.admin-users-table__buttons, .admin-users-table .add-user {
  width: 120px;
}
.admin-users-table__buttons .button, .admin-users-table .add-user .button {
  margin-top: 10px;
}
.admin-users-table__buttons .button:nth-child(1), .admin-users-table .add-user .button:nth-child(1) {
  margin-top: 0;
}
.admin-users-table__buttons .js-link, .admin-users-table .add-user .js-link {
  color: #2DC46E;
  border-bottom: 1px dotted;
  cursor: pointer;
}
.admin-users-table__buttons .js-link.red, .admin-users-table .add-user .js-link.red {
  color: #d33a61;
}
.admin-users-table__buttons .js-link.blue, .admin-users-table .add-user .js-link.blue {
  color: #3f809c;
}
.admin-users-table__buttons .js-link.orange, .admin-users-table .add-user .js-link.orange {
  color: #F2CB6C;
}
.admin-users-table__form td {
  vertical-align: middle;
  background: #223440;
  height: 106px;
}
.admin-users-table__form label {
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.65);
}
.admin-users-table__form .js-link {
  margin-left: 15px;
}
.admin-users-table__form input.invalid {
  border-bottom-color: #d33a61;
}
.admin-users-table__form-row {
  display: flex;
}
.admin-users-table__form-col {
  align-items: center;
  flex: 4;
  padding: 10px;
}
.admin-users-table__form-col.admin-users-table__buttons {
  flex: 1 65px;
  align-self: flex-end;
  white-space: nowrap;
}
/* Colors */
/* Fonts */
/* Dimensions */
.create-team-form {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.45);
  z-index: 9999;
  color: #000;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  pointer-events: all;
}
.create-team-form * {
  pointer-events: all;
}
.create-team-form .form {
  position: fixed;
  width: 640px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1B262E;
  padding: 25px;
}
.create-team-form .form .no-valid > div {
  border-bottom: 1px solid #d33a61;
}
.create-team-form .form input {
  padding: 7px 0;
}
.create-team-form .form h2 {
  color: #FFF;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
}
.create-team-form .form label {
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 4px;
  text-transform: uppercase;
  display: block;
  color: white;
  opacity: 0.7;
}
@media (max-width: 959px) {
  .create-team-form .form label {
    font-size: 9px;
    margin-bottom: 0;
  }
}
.create-team-form .form .form-row {
  margin-top: 25px;
}
.create-team-form .form .submit-row {
  text-align: right;
}
.create-team-form .form .submit-row button {
  margin-left: 25px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.single-report__shortcuts-controls {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  overflow: hidden;
}
.single-report__shortcuts-controls .title-with-help {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
}
.single-report__shortcuts-controls .title-with-help h4 {
  margin-bottom: 0;
}
.single-report__shortcuts-controls .title-with-help .controls-list-help {
  padding-left: 12px;
  margin: 0;
}
.single-report__shortcuts-controls .title-with-help .controls-list-help svg {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  width: 14px;
  height: 14px;
}
.single-report__shortcuts-controls .controls-list-help {
  padding-left: 12px;
  margin: 12px 0 4px;
}
.single-report__shortcuts-controls .controls-list-help svg {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  width: 14px;
  height: 14px;
}
@media (max-width: 959px) {
  .single-report__shortcuts-controls {
    display: block;
  }
}
.single-report__shortcuts-controls__primary {
  flex-grow: 0;
}
.single-report__shortcuts-controls h4 {
  margin: 0 0 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1px;
  color: #CCC;
}
.single-report__shortcuts-controls__row {
  margin-bottom: 24px;
  flex-grow: 2;
}
.single-report__shortcuts-controls__groups {
  display: flex;
  justify-content: stretch;
}
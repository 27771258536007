/* Colors */
/* Fonts */
/* Dimensions */
.data-item {
  display: flex;
  align-items: center;
}
.data-item__icon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.data-item__icon svg {
  width: 20px;
  height: 20px;
}
.data-item__content {
  width: 100%;
}
.data-item__value {
  color: #FFF;
  font-size: 16px;
  line-height: 20px;
}
.data-item__value .data-item__image {
  position: relative;
  top: -2px;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-position: center center;
  background-size: contain;
  vertical-align: middle;
  margin-right: 4px;
  background-repeat: no-repeat;
}
.data-item__label {
  font-size: 13px;
  text-transform: uppercase;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.5px;
}
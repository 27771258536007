/* Colors */
/* Fonts */
/* Dimensions */
.match {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #263742;
  z-index: 1;
  will-change: transform, opacity;
  -webkit-transition: left 500ms, right 500ms;
  -moz-transition: left 500ms, right 500ms;
  transition: left 500ms, right 500ms;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}
.match .match__close {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  cursor: pointer;
}
@media (max-width: 959px) {
  .match .match__close {
    top: 3px;
    right: 3px;
  }
}
.match .match__close svg {
  width: 24px;
  height: 24px;
}
@media (max-width: 959px) {
  .match .match__close svg {
    width: 18px;
    height: 18px;
  }
}
.match.side-controls--visible {
  left: -285px;
  right: 285px;
}
@media (max-width: 959px) {
  .match.side-controls--visible {
    left: -170px;
    right: 170px;
  }
}
.match.side-add-player--visible {
  left: 285px;
  right: -285px;
}
@media (max-width: 959px) {
  .match.side-add-player--visible {
    left: 186px;
    right: -186px;
  }
}
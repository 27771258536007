.reports-container {
  height: 100%;
  max-width: 100%;
  padding-right: 0;
  padding-bottom: 0;
}

.reports__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sub-nav__navigation.reports {
  width: 225px;
}
.sub-nav__navigation.reports .overview-link {
  margin-bottom: 25px;
}
.sub-nav__navigation.reports .overview-divider-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #CCCCCC;
  margin-top: 32px;
}
.sub-nav__navigation.reports .offline-report {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sub-nav__navigation.reports .offline-report__match {
  display: inline-block;
  margin-right: 8px;
}
.sub-nav__navigation.reports .offline-report__text {
  vertical-align: middle;
}
.sub-nav__navigation.reports .offline-report__team-img {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #22323c;
  background-blend-mode: screen;
  display: inline-block;
  width: 16px;
  height: 20px;
  vertical-align: middle;
}
.sub-nav__navigation.reports .offline-report__team-img + .offline-report__team-img {
  z-index: 2;
}
.sub-nav__navigation.reports .offline-report__player-img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  margin-right: 8px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.billing .plan-limits {
  margin-bottom: 35px;
  width: 280px;
}
.billing .plan-limits h2 {
  margin: 0;
  font-weight: normal;
  text-transform: none;
  margin-top: 18px !important;
  letter-spacing: 0.5px;
}
.billing .plan-limits__limit {
  margin-top: 20px;
}
.billing .plan-limits__body {
  display: flex;
}
.billing .plan-limits__progress {
  width: 200px;
  margin-right: 15px;
  border: 1px solid #395262;
  height: 8px;
  border-radius: 4px;
  margin-top: 8px;
  overflow: hidden;
}
.billing .plan-limits__progress span {
  display: block;
  width: 0;
  border-radius: 4px;
  height: 6px;
  background: #2DC46E;
}
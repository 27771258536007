/* Colors */
/* Fonts */
/* Dimensions */
.suggestions-table {
  border: none;
}
@media screen and (max-width: 959px) {
  .suggestions-table {
    font-size: 13px;
    line-height: 19px;
  }
}
.suggestions-table .tr {
  display: flex;
}
.suggestions-table .heading .tr {
  padding: 0 10px;
}
.suggestions-table .th, .suggestions-table .td {
  padding: 0 8px;
}
.suggestions-table .suggestion__player {
  align-items: center;
  background: #2D3B46;
  padding: 10px;
  margin-top: 5px;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  position: relative;
  color: #CCC;
}
.suggestions-table .col-name__player {
  position: relative;
  display: block;
  padding-left: 48px;
}
.suggestions-table .col-name__player .player-image {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  position: absolute;
  left: 8px;
  top: 50%;
  margin-top: -16px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #e0e8ed;
}
.suggestions-table .col-name__player .last-name {
  font-weight: bold;
  display: block;
}
.suggestions-table .th {
  font-size: 12px;
  line-height: 16px;
  color: #CCC;
  text-transform: uppercase;
}
.suggestions-table .team {
  position: relative;
  display: block;
  padding-left: 24px;
}
.suggestions-table .team .team-image {
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 4px;
  top: 50%;
  margin-top: -8px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.suggestions-table .add-to-favorites {
  position: relative;
  top: -1px;
}
.suggestions-table .favorite-selector {
  cursor: pointer;
  line-height: 25px;
}
.suggestions-table .favorite-selector svg {
  width: 21px;
  height: 21px;
  display: inline-block;
  position: relative;
  top: -2px;
  vertical-align: middle;
}
.suggestions-table .favorite-selector .chevron-down {
  vertical-align: middle;
  width: 15px;
  height: 15px;
  position: relative;
  fill: #FFF !important;
  stroke: none !important;
  top: -1px;
}
.suggestions-table .col-name {
  width: calc(50% - 255px);
}
@media screen and (max-width: 1025px) {
  .suggestions-table .col-name {
    width: calc(50% - 135px);
  }
}
.suggestions-table .col-team {
  width: calc(50% - 255px);
}
@media screen and (max-width: 1025px) {
  .suggestions-table .col-team {
    width: calc(50% - 135px);
  }
}
.suggestions-table .col-checkbox {
  width: 40px;
}
.suggestions-table .col-position {
  width: 34px;
}
.suggestions-table .col-age {
  width: 40px;
}
.suggestions-table .col-date {
  width: 120px;
}
@media screen and (max-width: 1025px) {
  .suggestions-table .col-date {
    display: none;
  }
}
.suggestions-table .col-category {
  width: 155px;
}
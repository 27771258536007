/* Colors */
/* Fonts */
/* Dimensions */
.indicators-list {
  position: relative;
  padding-bottom: 24px;
  max-width: 304px;
}
.indicators-list > div > div:nth-child(1) {
  scroll-behavior: smooth;
}
.indicators-list__empty {
  max-width: 180px;
  margin: 24px auto;
  text-align: center;
}
.indicators-list__empty p {
  font-size: 13px;
  font-style: normal;
  line-height: 16px;
  text-align: left;
  color: #A4A6A4;
  margin: 0 0 16px;
}
.indicators-list__body {
  margin-bottom: 24px;
}
.indicators-list:after {
  content: "";
  width: 100%;
  height: 24px;
  bottom: -3px;
  left: 0;
  position: absolute;
  z-index: 2;
  background: linear-gradient(180deg, rgba(38, 55, 66, 0) 0%, rgb(38, 55, 66) 59.37%);
}
.indicators-list .scroll-arrow {
  width: 24px;
  height: 6px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  bottom: 0;
  z-index: 3;
  cursor: pointer;
  color: #FFF;
  stroke: currentColor;
  fill: none;
}
.indicators-list .scroll-arrow:hover {
  color: #35B374;
}
@media (max-width: 959px) {
  .indicators-list {
    display: block;
  }
  .indicators-list:after {
    display: none;
  }
}
.gender-switcher {
  margin-right: 24px;
  background: #2D3B46;
  height: 44px;
  border-radius: 6px;
  max-width: 226px;
  flex-grow: 1;
  padding: 4px;
}
.gender-switcher button {
  width: 50%;
  height: 36px;
  background: transparent;
  border-radius: 6px;
  border: none;
  outline: none;
  font-size: 13px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.gender-switcher button svg {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 4px;
  position: relative;
  top: -2px;
}
.gender-switcher button.active {
  background: #637581;
}
/* Colors */
/* Fonts */
/* Dimensions */
.pdf-block {
  display: flex;
  margin-top: 43px;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  padding: 24px 0;
}
.pdf-block .col {
  border-left: 1px solid #CCCCCC;
  padding: 0 20px;
  flex-grow: 1;
}
.pdf-block .col:nth-child(1) {
  border-left: none;
}
@media screen and (max-width: 959px) {
  .pdf-block .col {
    padding: 0 12px;
  }
}
.pdf-block:nth-child(1) {
  margin-top: 0;
}
.pdf-block .data-item-changeable__value {
  color: #181C20;
}
.pdf-block .data-item-changeable__input input {
  color: #181C20;
  border-bottom: #181C20;
}
.pdf-block .data-item-changeable__label {
  color: #637581;
}
.pdf-block .data-item__value {
  color: #181C20;
}
.pdf-block .data-item__label {
  color: #637581;
  text-overflow: ellipsis;
  overflow: hidden;
}
.pdf-block h4 {
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: bold;
}
@media screen and (max-width: 959px) {
  .pdf-block h4 {
    font-size: 10px;
    line-height: 14px;
  }
}
.pdf-block .add-to-favorites {
  position: relative;
}
.pdf-block .favorite-selector {
  cursor: pointer;
  font-size: 24px;
  line-height: 24px;
  color: #181C20;
  white-space: nowrap;
}
@media screen and (max-width: 959px) {
  .pdf-block .favorite-selector {
    font-size: 16px;
    line-height: 16px;
  }
}
.pdf-block .favorite-selector svg {
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
  top: -2px;
  vertical-align: middle;
  margin-right: 5px;
}
@media screen and (max-width: 959px) {
  .pdf-block .favorite-selector svg {
    width: 16px;
    height: 16px;
  }
}
.pdf-block .favorite-selector .chevron-down {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  position: relative;
  fill: #181C20 !important;
  stroke: none !important;
  top: -1px;
  margin-right: 0;
}
@media screen and (max-width: 959px) {
  .pdf-block .data-item__icon, .pdf-block .data-item-changeable__icon {
    width: 14px;
    height: 14px;
  }
  .pdf-block .data-item__icon svg, .pdf-block .data-item-changeable__icon svg {
    width: 14px;
    height: 14px;
  }
  .pdf-block .data-item__input input, .pdf-block .data-item-changeable__input input {
    font-size: 11px;
    line-height: 14px;
  }
  .pdf-block .data-item__value, .pdf-block .data-item-changeable__value {
    font-size: 11px;
  }
  .pdf-block .data-item__label, .pdf-block .data-item-changeable__label {
    font-size: 9px;
  }
}

.pdf-stats-row {
  display: flex;
  margin-top: 7px;
}
.pdf-stats-row:nth-child(1) {
  margin-top: 0;
}

.pdf-small-title {
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
}
@media screen and (max-width: 959px) {
  .pdf-small-title {
    font-size: 10px;
    line-height: 14px;
  }
}

.pdf-match-stats .data-item, .pdf-match-stats .data-item-changeable {
  width: 50%;
  margin-left: 20px;
}
.pdf-match-stats .data-item:nth-child(1), .pdf-match-stats .data-item-changeable:nth-child(1) {
  margin-left: 0;
}
@media screen and (max-width: 959px) {
  .pdf-match-stats .data-item, .pdf-match-stats .data-item-changeable {
    margin-left: 12px;
  }
}

.pdf-match-info {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.pdf-match-info .pdf-stats-row.info {
  align-items: end;
}
.pdf-match-info .data-item {
  width: calc(25% - 6px);
  margin-left: 24px;
}
@media screen and (max-width: 959px) {
  .pdf-match-info .data-item {
    margin-left: 12px;
  }
}
.pdf-match-info .data-item:nth-child(1) {
  margin-left: 0;
}

.single-pdf__match {
  flex-grow: 1;
}
.single-pdf__position {
  width: 148px;
  flex-shrink: 0;
  flex-grow: 0 !important;
}
.single-pdf__position label {
  color: #637581;
}
@media screen and (max-width: 959px) {
  .single-pdf__position {
    width: 130px;
  }
}
.single-pdf__match-stats {
  width: 220px;
  flex-shrink: 0;
  flex-grow: 0;
}
@media screen and (max-width: 959px) {
  .single-pdf__match-stats {
    width: 185px;
  }
}
@media screen and (max-width: 959px) {
  .single-pdf__team {
    min-width: 230px;
  }
}
.single-pdf__category {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 230px;
  flex-grow: 0 !important;
  flex-shrink: 0;
}
@media screen and (max-width: 959px) {
  .single-pdf__category {
    width: 170px;
  }
}
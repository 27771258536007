/* Colors */
/* Fonts */
/* Dimensions */
.customer-page .js-link {
  color: #2DC46E;
  border-bottom: 1px dotted;
  cursor: pointer;
}
.customer-page .js-link.red {
  color: #d33a61;
}
.customer-page .js-link.blue {
  color: #3f809c;
}
.customer-page .js-link.orange {
  color: #F2CB6C;
}
.customer-page .email-verify-link {
  font-size: 14px;
  margin: 0 10px;
}
.customer-page .email-verify-link.no-margin {
  margin-left: 0;
}
.customer-page__heading {
  margin: 35px 0 0;
}
.customer-page__heading-row {
  display: flex;
}
.customer-page__heading-column {
  flex-grow: 1;
}
.customer-page__heading-column-controls {
  flex-grow: 0;
  width: 320px;
  align-self: flex-end;
  text-align: right;
  white-space: nowrap;
}
.customer-page__heading-column-controls .js-link {
  margin-left: 15px;
}
.customer-page__customer-status {
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 0 10px;
  color: #22323c;
  padding: 3px 10px;
  border-radius: 12px;
  display: inline-block;
  text-transform: uppercase;
  vertical-align: middle;
}
.customer-page__customer-status.regular {
  background: #3f809c;
}
.customer-page__customer-status.paying {
  background: #2DC46E;
}
.customer-page__customer-status.trialing {
  background: #3f809c;
}
.customer-page__customer-status.disabled {
  background: #d33a61;
}
.customer-page__name {
  margin: 0;
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
}
.customer-page__email, .customer-page__user-name {
  margin: 5px 0 0;
  font-size: 16px;
  line-height: 22px;
}
.customer-page__row {
  display: flex;
}
.customer-page__column {
  width: 25%;
  position: relative;
  padding: 0 10px;
}
.customer-page__column:nth-child(1) {
  padding-left: 0;
}
.customer-page__column:nth-last-child(1) {
  padding-right: 0;
}
.customer-page__info {
  background: #2a3c48;
  padding: 15px 15px 15px 25px;
  margin: 35px 0 0;
  border-radius: 4px;
}
.customer-page__info input.invalid {
  border-bottom-color: #d33a61;
}
.customer-page__info.loading {
  text-align: center;
  height: 160px;
}
.customer-page__info.loading .inline-loader-container {
  top: 20px;
}
.customer-page__info p {
  margin: 0 0 5px;
  font-size: 16px;
  line-height: 20px;
  position: relative;
}
.customer-page__info p .label {
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.65);
}
.customer-page__plan {
  font-weight: bold;
}
.customer-page__id, .customer-page__customer-type {
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  line-height: 20px;
}
.customer-page__indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  line-height: 0;
  font-size: 0;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: -14px;
}
.customer-page__indicator.regular {
  background: #3f809c;
}
.customer-page__indicator.paying {
  background: #2DC46E;
}
.customer-page__indicator.disabled {
  background: #d33a61;
}
.customer-page__tabs {
  margin-top: 0;
}
.customer-page__tabs-content {
  padding: 25px 0 35px;
}
.customer-page__tab-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  margin: 25px 0;
}
.customer-page__form-buttons {
  margin-top: 25px;
  text-align: right;
}
.customer-page__form-buttons .btn {
  margin-left: 15px;
}
@import '../exports';

.container {
	padding: 25px 20px 80px 25px;
	max-width: 1064px;
}

/*
 * Grid
 */

//----- Extends
%clearfix:after {
	display:block;
	clear:both;
	content:'';
}

//----- Grid System
$grid-spacing: 1%;

.row {
  @extend %clearfix;
  margin-left: -10px;
  margin-right: -10px;

  @media (max-width: $screen-sm-max) {
      margin-left: -5px;
      margin-right: -5px;
  }

  &.indented{
    margin-left: -24px;
    margin-right: -24px;

    @media (max-width: $screen-sm-max) {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  float: left;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: $screen-sm-max) {
    padding-left: 5px;
    padding-right: 5px;
  }

  &.indented{
    padding-left: 24px;
    padding-right: 24px;

    @media (max-width: $screen-sm-max) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.col-12 {
  width: 100%;
}
.col-11 {
  width: 91.66666667%;
}
.col-10 {
  width: 83.33333333%;
}
.col-9 {
  width: 75%;
}
.col-8 {
  width: 66.66666667%;
}
.col-7 {
  width: 58.33333333%;
}
.col-6 {
  width: 50%;
}
.col-5 {
  width: 41.66666667%;
}
.col-4 {
  width: 33.33333333%;
}
.col-3 {
  width: 25%;
}
.col-2 {
  width: 16.66666667%;
}
.col-1 {
  width: 8.33333333%;
}
.col-pull-12 {
  right: 100%;
}
.col-pull-11 {
  right: 91.66666667%;
}
.col-pull-10 {
  right: 83.33333333%;
}
.col-pull-9 {
  right: 75%;
}
.col-pull-8 {
  right: 66.66666667%;
}
.col-pull-7 {
  right: 58.33333333%;
}
.col-pull-6 {
  right: 50%;
}
.col-pull-5 {
  right: 41.66666667%;
}
.col-pull-4 {
  right: 33.33333333%;
}
.col-pull-3 {
  right: 25%;
}
.col-pull-2 {
  right: 16.66666667%;
}
.col-pull-1 {
  right: 8.33333333%;
}
.col-pull-0 {
  right: auto;
}
.col-push-12 {
  left: 100%;
}
.col-push-11 {
  left: 91.66666667%;
}
.col-push-10 {
  left: 83.33333333%;
}
.col-push-9 {
  left: 75%;
}
.col-push-8 {
  left: 66.66666667%;
}
.col-push-7 {
  left: 58.33333333%;
}
.col-push-6 {
  left: 50%;
}
.col-push-5 {
  left: 41.66666667%;
}
.col-push-4 {
  left: 33.33333333%;
}
.col-push-3 {
  left: 25%;
}
.col-push-2 {
  left: 16.66666667%;
}
.col-push-1 {
  left: 8.33333333%;
}
.col-push-0 {
  left: auto;
}
.col-offset-12 {
  margin-left: 100%;
}
.col-offset-11 {
  margin-left: 91.66666667%;
}
.col-offset-10 {
  margin-left: 83.33333333%;
}
.col-offset-9 {
  margin-left: 75%;
}
.col-offset-8 {
  margin-left: 66.66666667%;
}
.col-offset-7 {
  margin-left: 58.33333333%;
}
.col-offset-6 {
  margin-left: 50%;
}
.col-offset-5 {
  margin-left: 41.66666667%;
}
.col-offset-4 {
  margin-left: 33.33333333%;
}
.col-offset-3 {
  margin-left: 25%;
}
.col-offset-2 {
  margin-left: 16.66666667%;
}
.col-offset-1 {
  margin-left: 8.33333333%;
}
.col-offset-0 {
  margin-left: 0%;
}

.coll-pull-right{
  float: right
}

.content-row{
  @extend %clearfix;
  display:flex;
  margin-top: 20px;

  &.white-space-wrap{
    flex-wrap: wrap;
  }

  &.align-end{
    align-items: end;

    &>div{
      margin-left: 0 !important;
      margin-right: 20px;
    }
  }

  .content-col-50{
    width: calc(50% - 18px);
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 35px;

    /*@media (max-width: $screen-sm-max) {
      margin-left: 18px;
      width: calc(50% - 9px);
    }*/
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .content-col-66{
    width: calc(66.7% - 18px);
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 35px;
    /*@media (max-width: $screen-sm-max) {
      margin-left: 18px;
      width: calc(66.7% - 5px);
    }*/
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .content-col-33{
    width: calc(33.3% - 18px);
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 35px;
    /*@media (max-width: $screen-sm-max) {
      margin-left: 18px;
      width: calc(33.3% - 9px);
    }*/
    &:nth-child(1) {
      margin-left: 0;
    }
  }


  .content-col-60{
    width: calc(60% - 18px);
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 35px;
    &:nth-child(1) {
      margin-left: 0;
    }
  }
  .content-col-40{
    width: calc(40% - 18px);
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 35px;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  &.small-margin{
    margin-top: 5px;
  }

  &:nth-child(1){
    margin-top: 0;
  }

  .data-item, .data-item-icon, .data-item-changeable{
    margin-left: 24px;

    /*@media (max-width: $screen-sm-max) {
      margin-left: 18px;
    }*/

    &:nth-child(1){
      margin-left: 0;
    }
  }
}

$breakpoint: 1279px;
.flex-row{
  @media screen and (min-width: $breakpoint) {
    display: flex;
  }
}

/* Colors */
$color_Black: #111;
$color_White: #fff;
$color_Green: #188538; //298544
$color_Red: #d33a61;
$color_orange: #F2CB6C;
$color_Dark: #202020;
$color_Blue: #3f809c;
$color_neoGreen: #35B374;

$color_textDark: #1C2734;
$color_textSupport: #A4A6A4;

$color_GreenDark: #0f6630; //#357D31 1d6638
$color_GreenAction: #2DC46E;
$color_Disabled: #637581;

$color_DarkBlue: #1E2A31;
$color_BlackBlue: #151C21;

$color_Cat0: #5F7582;
$color_Cat1: #EFDD30;
$color_Cat2: #F98736;
$color_Cat3: #DD4043;

$link_color: $color_orange;

$color_Inactive: #A4A6A4;


/* Fonts */
$font_Primary: "Source Sans Pro", Helvetica, sans-serif;
$font_Auth: 'IBM Plex Sans', sans-serif;
$font_Size: 16px;
$font_Size_Sm: 7px;
$line_Height: 1.4;


$color_ContentBg: #2E3C47;
$color_Bg: #263742;
$color_Auth_Bg: #021c29;


$color_homeCardBg: #24323D;
$color_homeCardBgHover: #344450;
$color_homecardHoverBorder: #3A4C5A;

$color_ReportCardBg: #3A4C5A;
$color_ReportCardBgHover: #24323D;
$color_ReportCardBorder: #2D3B46;
$color_Disabled: #637581;

/* Dimensions */
$minWidth: 320px;
$maxWidth: 1920px;

// Sizes
$min-width:320px;
$max-width:1080px;

// Media queries helpers
$screen-xxs: 320px;
$screen-xxs-max: $screen-xxs - 1px;

$screen-xs: 768px;
$screen-xs-max: $screen-xs - 1px;

$screen-sm: 960px;
$screen-sm-max: $screen-sm - 1px;

$screen-md: 1200px;
$screen-md-max: $screen-md - 1px;

$screen-iPad: 1025px;
$screen-iPad-max: $screen-iPad - 1px;

$screen-lg: 1380px;
$screen-lg-max: $screen-lg - 1px;

$breakpoint: 1279px;


/* Colors */
/* Fonts */
/* Dimensions */
.single-report__substitutions__up, .single-report__substitutions__down {
  position: absolute;
  left: 50%;
  bottom: 10px;
  margin-left: -350px;
  background: none;
  border: 1px solid #FFF;
  border-radius: 6px;
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.single-report__substitutions__up svg, .single-report__substitutions__down svg {
  width: 16px;
  height: 16px;
}
.single-report__substitutions__up:hover, .single-report__substitutions__down:hover {
  border-color: #35B374;
}
@media (max-width: 959px) {
  .single-report__substitutions__up, .single-report__substitutions__down {
    margin-left: -245px;
    width: 24px;
    height: 24px;
  }
}
.single-report__substitutions__up {
  margin-left: -300px;
}
@media (max-width: 959px) {
  .single-report__substitutions__up {
    margin-left: -210px;
  }
}
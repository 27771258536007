/* Colors */
/* Fonts */
/* Dimensions */
.controls-list {
  padding-bottom: 70px;
}
.controls-list .controls-group .controls-group-title {
  font-weight: 700;
  font-size: 16px;
  padding: 10px 15px;
  color: #fff;
  background: #293b47;
  box-shadow: 0px 4px 8px rgba(21, 28, 33, 0.3), 0px -6px 8px rgba(21, 28, 33, 0.1);
  z-index: 1;
  text-transform: uppercase;
}
@media (max-width: 959px) {
  .controls-list .controls-group .controls-group-title {
    font-size: 12px;
    padding: 5px 8px;
  }
}
.controls-list .controls-list-help {
  padding-left: 12px;
  margin: 12px 0 4px;
}
.controls-list .controls-list-help svg {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  width: 14px;
  height: 14px;
}
.controls-list .control:nth-child(even) {
  background: #212f38;
}
.controls-list .control .title {
  padding: 15px 15px 15px 45px;
  font-size: 16px;
  font-weight: 400;
  display: block;
  cursor: pointer;
  position: relative;
}
@media (max-width: 959px) {
  .controls-list .control .title {
    padding: 10px 10px 10px 26px;
    font-size: 12px;
  }
}
.controls-list .control .title svg {
  position: absolute;
  left: 15px;
  max-width: 25px;
  font-weight: 400;
  width: 24px;
  height: 24px;
}
@media (max-width: 959px) {
  .controls-list .control .title svg {
    left: 8px;
    max-width: 13px;
    width: 16px;
    height: 16px;
  }
}
.controls-list .control .values {
  padding: 0 15px 15px;
  font-size: 30px;
  line-height: 50px;
  max-height: 67px;
  overflow: hidden;
  -webkit-transition: max-height 0.5s, padding 0.5s;
  -moz-transition: max-height 0.5s, padding 0.5s;
  transition: max-height 0.5s, padding 0.5s;
}
@media (max-width: 959px) {
  .controls-list .control .values {
    padding: 0 10px 10px;
    font-size: 18px;
    line-height: 26px;
    max-height: 38px;
  }
}
.controls-list .control .values .value {
  display: inline-block;
  width: 20%;
  text-align: center;
  border: 1px solid #495D6A;
  color: #2DC46E;
  cursor: pointer;
}
.controls-list .control .values .value + .value {
  border-left: none;
}
.controls-list .control .values .value:hover {
  background: #495D6A;
}
.controls-list .control .values .value.active {
  background: #fff;
  color: #1E2A31;
}
.controls-list .control .values.hidden {
  max-height: 0;
  padding-bottom: 0;
}
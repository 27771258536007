/* Colors */
/* Fonts */
/* Dimensions */
.earn .milestones {
  margin-top: 48px;
}
.earn .milestones__row {
  display: flex;
  padding: 15px 0;
  margin-top: 2px;
  background: #2D3B46;
  align-items: center;
}
.earn .milestones__col {
  padding: 0 16px;
  font-size: 16px;
  line-height: 22px;
  text-overflow: ellipsis;
  flex-shrink: 0;
}
.earn .milestones__icon {
  width: 56px;
}
.earn .milestones__icon .icon-container {
  display: block;
  position: relative;
}
.earn .milestones__icon .icon-container .badge {
  font-size: 13px;
  line-height: 13px;
  color: #FFF;
  padding: 1px 4px;
  border-radius: 7px;
  background: #637581;
  position: absolute;
  top: 100%;
  left: 100%;
  margin-left: -15px;
  margin-top: -11px;
}
.earn .milestones__icon svg {
  width: 24px;
  height: 24px;
  display: block;
}
.earn .milestones__status {
  width: 46px;
}
.earn .milestones__status svg {
  width: 14px;
  height: 12px;
  display: block;
}
.earn .milestones__credits {
  width: 70px;
  white-space: nowrap;
}
.earn .milestones__progress-count {
  width: 80px;
  white-space: nowrap;
}
.earn .milestones__progress-bar {
  width: 128px;
}
.earn .milestones__progress-bar .progress-container {
  display: block;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: #24323D;
}
.earn .milestones__progress-bar .progress-container .progress-line {
  display: block;
  height: 8px;
  border-radius: 4px;
  background: #35B374;
}
.earn .milestones__name {
  width: calc(100% - 379px);
}
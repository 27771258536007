/* Colors */
/* Fonts */
/* Dimensions */
.demo-message {
  width: 196px;
  font-size: 16px;
  line-height: 20px;
  color: #35B374;
  font-family: "Sedgwick Ave", cursive;
  position: relative;
  pointer-events: none;
}
.demo-message svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 32px;
}
.demo-message .demo-arrow {
  position: absolute;
}
.demo-message .demo-arrow.left {
  left: -49px;
  top: -8px;
}
.demo-message .demo-arrow.top-left {
  top: -30px;
  left: -30px;
  transform: rotate(90deg) scaleY(-1);
}
.demo-message .demo-arrow.top-right {
  top: -50px;
  left: 100%;
  margin-left: -61px;
  transform: rotate(90deg);
}
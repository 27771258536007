.match-card__team {
  position: relative;
  padding-left: 32px;
  font-size: 19px;
  line-height: 20px;
  font-weight: 700;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.match-card__team-image {
  position: absolute;
  width: 24px;
  height: 30px;
  top: 50%;
  left: 0;
  margin-top: -15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
/* Colors */
/* Fonts */
/* Dimensions */
.team-view {
  display: flex;
}
.team-view__img {
  width: 25px;
  height: 25px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
}
/* Colors */
/* Fonts */
/* Dimensions */
.shadow-team__player-information {
  position: relative;
  padding: 10px 2px 10px 53px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  width: 230px;
}
.shadow-team__player-information.add {
  position: absolute;
  top: 0;
  background: #2A333A;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.35);
}
.shadow-team__player-information.add.left {
  right: 100%;
  margin-right: 3px;
}
.shadow-team__player-information.add.right {
  left: 100%;
  margin-left: 3px;
}
.shadow-team__player-information .player__image {
  position: absolute;
  top: 10px;
  left: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 33px;
  height: 42px;
  border-radius: 3px;
}
.shadow-team__player-information b {
  /*text-transform: uppercase;*/
  font-weight: 300;
  letter-spacing: 0.2px;
}
.shadow-team__player-information .player__name {
  line-height: 17px;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
  margin-right: 5px;
  word-wrap: break-word; /* IE 5.5-7 */
  white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
  white-space: pre-wrap; /* current browsers */
}
.shadow-team__player-information .small {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}
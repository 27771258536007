input {
	background: transparent;
	border: none;
	border-bottom: 1px solid $color_GreenAction;
	border-radius: 0;
	color: #fff;
	line-height: 22px;
	padding: 5px 0;
	font-size: 16px;
	width: 100%;
    font-weight: 400;
    letter-spacing: .2px;
    font-family: $font_Primary;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus
    &:-webkit-autofill {
        -webkit-text-fill-color: white;
        -webkit-box-shadow: 0 0 0px 1000px #253742 inset;
        transition: background-color 5000s ease-in-out 0s;
        background: transparent;
    }

    @media (max-width: $screen-sm-max) {
        font-size: 14px;
        line-height: 18px;
    }

	&::-webkit-input-placeholder { color: rgba(#fff, .75); transition: opacity .2s; opacity: 1; }
	&::-moz-placeholder { color: rgba(#fff, .75); transition: opacity .2s; opacity: 1; }
	&:-ms-input-placeholder { color: rgba(#fff, .75); transition: opacity .2s; opacity: 1; }
	&:-moz-placeholder { color: rgba(#fff, .75); transition: opacity .2s; opacity: 1; }

	&:focus {
		border-bottom-color: #fff;
	}

    &.no-valid {
        border-bottom-color: red;
    }
}

input:required {
    box-shadow: none;
}
input:invalid {
    box-shadow: none;
}

button {
	display: inline-block;
	background: #009B7A;

}

.Select-control {
	@include border-top-radius(2px);
	@include border-right-radius(2px);
	@include border-bottom-radius(2px);
	@include border-left-radius(2px);
	border: 0 !important;
	border-bottom: 1px solid $color_GreenAction !important;
	background: none !important;
	font-size: 16px;
	color: white !important;
	font-family: $font_Primary;

}
// .has-value.Select--single > .Select-control .Select-value .Select-value-label, .has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
// 	color: white !important;
// 	font-weight: 500;
// }

// .Select-placeholder, .Select--single > .Select-control .Select-value .Select-value-label, .react-datepicker__input-container input, .react-timepicker__input-container, .Select-input input	{
// 	color: white !important;
// 	font-weight: 600 !important;
// 	font-size: 17px;
// }

/* Colors */
/* Fonts */
/* Dimensions */
.data-item-changeable-simple {
  position: relative;
  z-index: 2;
}
.data-item-changeable-simple__input input {
  color: white;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  padding: 1px 0 2px;
}
.data-item-changeable-simple__input .date_input {
  width: 80px;
}
.data-item-changeable-simple__input .select_input {
  min-width: 80px;
}
.data-item-changeable-simple__input .select_input > div {
  min-height: 23px;
}
.data-item-changeable-simple__input .number_input {
  width: 45px;
}
.data-item-changeable-simple__label {
  font-size: 13px;
  text-transform: uppercase;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.5px;
  display: block;
}